import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #9F9E9E;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #9F9E9E;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default GlobalStyles;
