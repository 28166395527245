import React, { useCallback } from 'react';
import {
  AutocompleteRenderInputParams,
  Autocomplete as MUIAutoComplete,
  SxProps,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { TextField } from '../textField';

export type AutoCompleteProps = {
  id: string | undefined;
  value: string | null;
  onChange: (value: string | null) => void;
  options: string[];
  placeholder: string;
  isTouched?: boolean;
  errorMessage?: string;
  sx?: SxProps;
  inputSx?: SxProps;
};

const AutoComplete: React.FC<AutoCompleteProps> = ({
  id,
  value,
  onChange,
  options,
  placeholder,
  isTouched,
  errorMessage,
  sx,
  inputSx,
}) => {
  const renderTextField = useCallback(
    (params: AutocompleteRenderInputParams): React.ReactNode => (
      <TextField
        {...params}
        sx={{ height: 40, ...inputSx }}
        placeholder={placeholder}
        errorMessage={errorMessage}
        isTouched={isTouched}
      />
    ),
    [placeholder, inputSx, errorMessage, isTouched],
  );

  const handleChange = useCallback(
    (_event: React.SyntheticEvent<Element, Event>, value: string | null) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <MUIAutoComplete
      popupIcon={<ExpandMore />}
      disablePortal
      id={id}
      options={options}
      value={value}
      onChange={handleChange}
      title="select"
      renderInput={renderTextField}
      sx={{
        width: 385,
        height: 40,
        '& .MuiOutlinedInput-root': {
          padding: '0px 8px',
        },
        ...sx,
      }}
    />
  );
};

export default AutoComplete;
