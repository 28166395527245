import React, { useCallback } from 'react';
import {
  Checkbox as MUICheckbox,
  CheckboxProps as MUICheckboxProps,
} from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';

type CheckboxProps = {
  value: string;
  isChecked: boolean;
  onChange: (value: string, checked: boolean) => void;
} & MUICheckboxProps;

const Checkbox: React.FC<CheckboxProps> = ({ value, isChecked, onChange }) => {
  const handleChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChange(value, checked);
    },
    [value, onChange],
  );

  return (
    <MUICheckbox
      icon={<CheckBoxOutlineBlankOutlinedIcon />}
      checkedIcon={<CheckBoxOutlinedIcon />}
      checked={isChecked}
      onChange={handleChange}
    />
  );
};

export default Checkbox;
