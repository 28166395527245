import React, { useCallback, useEffect, useState } from 'react';
import { ActivityLogLayout } from '../layout/activityLog';
import { ActivityLogRepository } from '../repositories';
import {
  ActivityLogFilterParam,
  ActivityLogResponse,
} from '@/types/ActivityLog';
import debounce from 'lodash/debounce';
import { PAGE_COUNT } from '../constants';
import { useSnackbar } from '../state';
import { useLoading } from '../components/loading/LoadingProvider';
import { activityLogDownloadByUrl } from '../repositories/ActivityLogRepository';

export type ActivityFilterData = {
  fromDate: Date | null;
  toDate: Date | null;
};
const ActivityLogPage = () => {
  const snackbar = useSnackbar();
  const { setLoading } = useLoading();
  const [searchText, setSearchText] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [filterData, setFilterData] = useState<ActivityFilterData>({
    fromDate: null,
    toDate: null,
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [page, setPage] = useState<number>(0);
  const [activityLogData, setActivityLogData] = useState<ActivityLogResponse>();
  const [filterValue, setFilterValue] = useState<ActivityLogFilterParam | null>(
    null,
  );
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [downloadDate, setDownloadDate] = useState<ActivityFilterData>({
    fromDate: null,
    toDate: null,
  });
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [viewFilterData, setViewFilterData] = useState(false);
  const [appliedFilterData, setAppliedFilterData] =
    useState<ActivityFilterData>({
      fromDate: null,
      toDate: null,
    });

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      if (event?.currentTarget) setPage(newPage);
    },
    [],
  );

  const handleClickFilter = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setOpen((prevOpen) => !prevOpen);
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleDownloadEndDateChange = useCallback((date: Date | null) => {
    setDownloadDate((prev) => ({
      ...prev,
      toDate: date,
    }));
  }, []);

  const handleDownloadStartDateChange = useCallback((date: Date | null) => {
    setDownloadDate((prev) => ({
      ...prev,
      fromDate: date,
    }));
  }, []);

  const handleDownloadOpen = useCallback(() => {
    setDownloadOpen(true);
  }, []);

  const handleDownloadClose = useCallback(() => {
    setDownloadOpen(false);
    setDownloadDate((prev) => ({
      ...prev,
      fromDate: null,
      toDate: null,
    }));
  }, []);

  const handleDownloadApply = useCallback(async () => {
    try {
      setLoading(true);
      const fromDate = downloadDate?.fromDate
        ? downloadDate?.fromDate?.getTime()?.toString()
        : null;
      const toDate = downloadDate?.toDate
        ? downloadDate?.toDate?.setHours(23, 59, 59)?.toString()
        : null;
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const region = navigator.language;
      const downloadValue = {
        fromDate: fromDate,
        toDate: toDate,
      };
      const response = await ActivityLogRepository.activityLogDownload(
        region,
        timeZone,
        downloadValue,
      );
      const csvResponse = await activityLogDownloadByUrl(response.url);
      const blob = new Blob([csvResponse], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute(
        'download',
        `activity-log-cyphlens-${new Date().toString()}.csv`,
      );
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading(false);
    } catch (error: unknown) {
      setLoading(false);
      if (error instanceof Error && error?.message) {
        snackbar.show(error.message);
      }
    }
  }, [downloadDate, snackbar, setLoading]);

  const handleChangeSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
    },
    [],
  );

  const handleApplySearch = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        setSearchText(searchValue);
        setPage(0);
      }
    },
    [searchValue],
  );

  const handleStartDateChange = useCallback((date: Date | null) => {
    setFilterData((prev) => ({
      ...prev,
      fromDate: date,
    }));
  }, []);

  const handleEndDateChange = useCallback((date: Date | null) => {
    setFilterData((prev) => ({
      ...prev,
      toDate: date,
    }));
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleApply = useCallback(() => {
    setAppliedFilterData(filterData);
    const fromDate = filterData?.fromDate
      ? filterData?.fromDate?.getTime()?.toString()
      : null;
    const toDate = filterData?.toDate
      ? filterData?.toDate?.setHours(23, 59, 59)?.toString()
      : null;
    setFilterValue((prev) => ({
      ...prev,
      ...(fromDate && { fromDate }),
      ...(toDate && { toDate }),
    }));
    setViewFilterData(!!filterData?.fromDate);
  }, [filterData]);

  const handleReset = useCallback(() => {
    setFilterData((prev) => ({
      ...prev,
      fromDate: null,
      toDate: null,
    }));
    setAppliedFilterData((prev) => ({
      ...prev,
      fromDate: null,
      toDate: null,
    }));
    setFilterValue(null);
    setViewFilterData(false);
  }, []);

  const handleClickSearch = useCallback(
    (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      if (event.type === 'click') {
        setSearchText(searchValue);
        setPage(0);
      }
    },
    [searchValue],
  );

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await ActivityLogRepository.activityLog(
        searchText,
        filterValue,
        page + 1,
        PAGE_COUNT,
      );
      const data = response?.data?.map((item, index) => {
        const id = `${item.time}${index}`;
        const dateFormat = new Date(parseInt(item?.date));
        const date = dateFormat?.toLocaleDateString([], {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
        const timeFormat = dateFormat?.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
        const time = timeFormat?.toUpperCase();
        return {
          ...item,
          id,
          date,
          time,
        };
      });
      setActivityLogData({ ...response, data });
      setLoading(false);
    } catch (error: unknown) {
      setLoading(false);
      if (error instanceof Error && error?.message) {
        snackbar.show(error.message);
      }
    }
  }, [searchText, snackbar, page, filterValue, setLoading]);

  useEffect(() => {
    debounce(fetchData, 1000);
    fetchData();
  }, [fetchData]);

  return (
    <ActivityLogLayout
      activityLogData={activityLogData?.data ?? []}
      isOpenFilter={open}
      isOpenDownload={downloadOpen}
      page={page}
      totalRecords={activityLogData?.page?.totalRecords ?? 0}
      onChangePage={handleChangePage}
      anchorEl={anchorEl}
      filterData={filterData}
      downloadDate={downloadDate}
      onChangeDownloadStartDate={handleDownloadStartDateChange}
      onChangeDownloadEndDate={handleDownloadEndDateChange}
      onDownloadClose={handleDownloadClose}
      onDownloadApply={handleDownloadApply}
      onClickDownload={handleDownloadOpen}
      onChangeSearch={handleChangeSearch}
      onApplySearch={handleApplySearch}
      onFilterApply={handleApply}
      onFilterReset={handleReset}
      onClickFilter={handleClickFilter}
      onChangeStartDate={handleStartDateChange}
      onChangeEndDate={handleEndDateChange}
      onFilterClose={handleClose}
      onClickSearch={handleClickSearch}
      isViewFilter={viewFilterData}
      appliedFilterData={appliedFilterData}
    />
  );
};

export default ActivityLogPage;
