import React, { useMemo, useState, useCallback } from 'react';
import {
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export type TextFieldProps = {
  id?: string;
  value?: string;
  name?: string;
  isTouched?: boolean;
  errorMessage?: string | null;
} & MUITextFieldProps;

const TextField: React.FC<TextFieldProps> = ({
  id,
  placeholder,
  value,
  name,
  isTouched,
  errorMessage,
  type,
  inputProps,
  onChange,
  onBlur,
  sx,
  ...props
}) => {
  const { t } = useTranslation();
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleTogglePasswordVisibility = useCallback(() => {
    setIsShowPassword((prevShowPassword: boolean) => !prevShowPassword);
  }, []);

  const hasError = useMemo(() => {
    return isTouched && errorMessage && errorMessage?.length > 0;
  }, [isTouched, errorMessage]);

  const passwordInputProps = useMemo(() => {
    if (type === 'password') {
      return {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleTogglePasswordVisibility}
              edge="end"
            >
              {isShowPassword ? (
                <VisibilityOffOutlined />
              ) : (
                <VisibilityOutlined />
              )}
            </IconButton>
          </InputAdornment>
        ),
      };
    }
  }, [type, isShowPassword, handleTogglePasswordVisibility]);

  return (
    <MUITextField
      sx={{
        '& .MuiInputBase-input': {
          fontSize: '12px',
        },
        '& .MuiOutlinedInput-input': {
          padding: '12px',
        },
        ...sx,
      }}
      variant="outlined"
      margin="normal"
      fullWidth
      id={id}
      name={name}
      placeholder={placeholder}
      value={value}
      helperText={hasError && errorMessage && t(errorMessage)}
      error={hasError || false}
      type={isShowPassword ? 'text' : type}
      onChange={onChange}
      onBlur={onBlur}
      InputProps={passwordInputProps}
      inputProps={inputProps}
      {...props}
    />
  );
};

export default TextField;
