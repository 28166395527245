import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoginLayout from '../layout/login/LoginLayout';
import {
  AuthenticationType,
  CyphImageVerificationStatus,
  RouterName,
} from '../constants';
import { useAuth, useSnackbar } from '../state';
import { LoginFormType, ScreenType } from '../layout/login/LoginForm';
import { AuthRepository } from '../repositories';
import { LoginEmailResponse } from '../types';
import useTimer from '../hooks/useTimer';
import { MFAVerificationStatusService } from '../services';

const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const {
    setUser,
    setIsLoggedIn,
    updateAuthenticationType,
    mfaVerificationStatus,
    setMfaVerificationStatus,
  } = useAuth();
  const { start, value } = useTimer();

  const [screenType, setScreenType] = useState(ScreenType.email);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [userSession, setUserSession] = useState<LoginEmailResponse | null>(
    null,
  );
  const handleRecover = useCallback(() => {
    navigate(RouterName.recover);
  }, [navigate]);

  const handleMfaStatus = useCallback(
    (status: string) => {
      setMfaVerificationStatus(status);
    },
    [setMfaVerificationStatus],
  );

  const handleNext = useCallback(
    async (values: LoginFormType) => {
      try {
        setLoading(true);
        setEmail(values.email);
        const response = await AuthRepository.loginWithEmail(values.email);
        const content = response;
        setUserSession(content);
        setScreenType(ScreenType.cyphlensImageVerification);
        start();
        MFAVerificationStatusService.start(content?.sessionId, handleMfaStatus);
        setLoading(false);
      } catch (error: unknown) {
        setLoading(false);
        if (error instanceof Error && error?.message) {
          snackbar.show(error.message);
        }
      }
    },
    [snackbar, setUserSession, start, handleMfaStatus],
  );

  const handleRefresh = useCallback(() => {
    MFAVerificationStatusService.stop();
    handleNext({
      email,
      mfaCode: '',
      password: '',
    });
  }, [handleNext, email]);

  const handleMfaVerify = useCallback(
    async (values: LoginFormType) => {
      try {
        setLoading(true);
        await AuthRepository.loginWith2FA(
          values.email,
          values.mfaCode,
          userSession?.sessionId ?? '',
        );
        setScreenType(ScreenType.password);
        updateAuthenticationType(AuthenticationType.MFA);
        setLoading(false);
      } catch (error: unknown) {
        setLoading(false);
        if (error instanceof Error && error?.message) {
          snackbar.show(error.message);
        }
      }
    },
    [userSession?.sessionId, updateAuthenticationType, snackbar],
  );

  const handleLogin = useCallback(
    async (values: LoginFormType) => {
      try {
        setLoading(true);
        const response = await AuthRepository.loginWithPassword(
          values.email,
          values.password,
          userSession?.sessionId ?? '',
        );
        setUser(response);
        navigate(RouterName.account);
        setIsLoggedIn(true);
        setLoading(false);
        setScreenType(ScreenType.email);
        setMfaVerificationStatus('');
      } catch (error: unknown) {
        setLoading(false);
        if (error instanceof Error && error?.message) {
          snackbar.show(error.message);
        }
      }
    },
    [
      navigate,
      setIsLoggedIn,
      userSession?.sessionId,
      snackbar,
      setUser,
      setMfaVerificationStatus,
    ],
  );

  useEffect(() => {
    if (mfaVerificationStatus === CyphImageVerificationStatus.success) {
      MFAVerificationStatusService.stop();
      setScreenType(ScreenType.password);
      updateAuthenticationType(AuthenticationType.MFA);
    }
    if (mfaVerificationStatus === CyphImageVerificationStatus.expired) {
      snackbar.show(t('loginForm.imageExpirationError'));
    }
  }, [
    t,
    snackbar,
    mfaVerificationStatus,
    setMfaVerificationStatus,
    updateAuthenticationType,
  ]);

  return (
    <LoginLayout
      isLoading={loading}
      mfaInterval={value}
      imageUrl={userSession?.image}
      screenType={screenType}
      onClickRecover={handleRecover}
      onClickRefresh={handleRefresh}
      onClickNext={handleNext}
      onClickVerify={handleMfaVerify}
      onClickLogin={handleLogin}
    />
  );
};

export default LoginPage;
