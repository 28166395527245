import React from 'react';
import { Box, CardMedia, IconButton, Typography } from '@mui/material';
import PowerSettingsNewRounded from '@mui/icons-material/PowerSettingsNewRounded';
import { useTheme } from '@mui/material/styles';

interface DrawerBottomContainerProps {
  profileImage: string;
  name: string;
  onClickLogout: () => void;
}

const DrawerBottomContainer: React.FC<DrawerBottomContainerProps> = ({
  profileImage,
  name,
  onClickLogout,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        boxShadow: `0 2px 4px  ${theme?.palette?.black?.[500]}`,
        width: '100%',
        position: 'absolute',
        bottom: 0,
        marginBottom: '15px',
        borderTop: `1px solid ${theme.palette.grey?.[200]}`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          padding: '8px',
          width: '100%',
          marginLeft: '12px',
          marginRight: '12px',
        }}
      >
        <Box display="flex" alignItems="center" style={{ height: '100%' }}>
          <CardMedia
            component="img"
            image={profileImage}
            alt="profile"
            sx={{ width: '26px', height: '26px', borderRadius: '26px' }}
          />
          <Typography
            sx={{
              color: theme.palette.background.paper,
              fontSize: '14px',
              fontWeight: 700,
              paddingLeft: '10px',
              paddingRight: '10px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {name}
          </Typography>
        </Box>
        <IconButton data-testid="logout" onClick={onClickLogout}>
          <PowerSettingsNewRounded
            sx={{
              color: theme.palette.background.paper,
              fontSize: '20px',
              cursor: 'pointer',
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DrawerBottomContainer;
