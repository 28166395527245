import { Box, DialogActions, Divider } from '@mui/material';
import { PickersActionBarProps } from '@mui/x-date-pickers';
import React from 'react';
import { Button } from '../button';

const DatePickerAction: React.FC<PickersActionBarProps> = ({
  onAccept,
  onCancel,
}) => {
  return (
    <Box>
      <Divider />
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          variant="outlined"
          title={'Cancel'}
          onClick={onCancel}
          sx={{ width: '65px', height: '24px', fontSize: '11px' }}
        />
        <Button
          title={'Apply'}
          onClick={onAccept}
          variant="contained"
          sx={{ width: '65px', height: '24px', fontSize: '11px' }}
        />
      </DialogActions>
    </Box>
  );
};

export default DatePickerAction;
