import React from 'react';
import { Grid, Box } from '@mui/material';
import { LogoImages } from '../../assets/images';
import { RecoverForm } from '.';
import { theme } from '../../theme';
import { RecoverFormType } from './RecoverForm';

export type RecoverLayoutProps = {
  isLoading?: boolean;
  onClickLogin: (values: RecoverFormType) => void;
};

const RecoverLayout: React.FC<RecoverLayoutProps> = ({
  isLoading,
  onClickLogin,
}) => {
  return (
    <Grid container item xs={12} sx={{ display: 'flex', minHeight: '100vh' }}>
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        sx={{
          flex: '1 1',
          minWidth: '500px',
          height: '100vh',
          width: '100%',
          background: `linear-gradient(270deg, ${theme?.palette?.black?.[200]} 1.81%, ${theme?.palette?.black?.[100]}  48.71%, ${theme?.palette?.black?.[200]} 100%)`,
          backgroundColor: theme?.palette?.background?.default,
          boxShadow: `0px 6px 60px 0px ${theme?.palette?.black?.[300]}`,
          opacity: 0.9,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src={LogoImages.cyphlensLogo}
          width="145px"
          alt="recover-background"
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={8}
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src={LogoImages.transparent.default}
          alt="transparent-logo"
          sx={{
            position: 'absolute',
            width: '300px',
            height: '375px',
            flexShrink: 0,
            top: '-30px',
            right: 0,
          }}
        />
        <RecoverForm isLoading={isLoading} onClickLogin={onClickLogin} />
      </Grid>
    </Grid>
  );
};

export default RecoverLayout;
