import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Button from '../../components/button/Button';
import { ShareLayoutType } from '../../pages/CreateCyphlensImagePage';

type ShareTypeConfirmationProps = {
  onSharePopupClose?: () => void;
  onEmailOptionSelected?: () => void;
  onMessageOptionSelected?: () => void;
  shareLayoutType: ShareLayoutType;
};
const ShareTypeConfirmation: React.FC<ShareTypeConfirmationProps> = ({
  onSharePopupClose,
  onEmailOptionSelected,
  onMessageOptionSelected,
}: ShareTypeConfirmationProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <IconButton
          data-testid="close-share-popup"
          style={{ color: theme.palette.primary.main }}
          onClick={onSharePopupClose}
        >
          <HighlightOffRoundedIcon />
        </IconButton>
      </Box>
      <Typography
        sx={{ marginBottom: '10px' }}
        variant="h6"
        color={theme.palette.background.default}
      >
        {t('createCyphlensImage.share.title')}
      </Typography>
      <Typography
        id="transition-modal-description"
        className="modal-description"
        color={theme?.palette?.black?.[800]}
        variant="h4"
      >
        {t('createCyphlensImage.share.description')}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          marginTop="30px"
          sx={{
            width: '150px',
            marginRight: '30px',
          }}
        >
          <Button
            type="reset"
            variant="contained"
            data-testid="share-with-email-button"
            title={t('createCyphlensImage.email')}
            style={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: '25px',
              border: `1px solid ${theme?.palette?.grey?.[800]}`,
              color: theme?.palette?.black?.[600],
              marginBottom: '20px',
            }}
            onClick={onEmailOptionSelected}
          />
        </Box>
        <Box
          marginTop="30px"
          sx={{
            width: '150px',
          }}
        >
          <Button
            sx={{ width: '150px', marginBottom: '20px' }}
            data-testid="share-with-message-button"
            type="submit"
            variant="contained"
            title={t('createCyphlensImage.messageButton')}
            onClick={onMessageOptionSelected}
          />
        </Box>
      </Box>
    </>
  );
};

export default ShareTypeConfirmation;
