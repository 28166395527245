import React from 'react';
import { RadioGroup, FormControlLabel, useTheme } from '@mui/material';
import StyledRadio from './StyledRadio';

type EmergencyContactList = {
  id: string;
  email: string;
};

type EmergencyRecoverContactListLayoutProps = {
  selectedContact?: string | null;
  contactListData: EmergencyContactList;
  onChangeRecoverContactList: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
};

const EmergencyRecoverContactListLayout: React.FC<
  EmergencyRecoverContactListLayoutProps
> = ({ selectedContact, contactListData, onChangeRecoverContactList }) => {
  const theme = useTheme();

  return (
    <RadioGroup
      key={contactListData?.id}
      value={selectedContact}
      onChange={onChangeRecoverContactList}
    >
      <FormControlLabel
        sx={{
          fontSize: '14px',
          marginBottom: '30px',
          fontFamily: theme?.font?.primary,
          color: theme?.palette?.text?.primary,
        }}
        value={contactListData?.id}
        control={<StyledRadio />}
        label={contactListData?.email}
      />
    </RadioGroup>
  );
};

export default EmergencyRecoverContactListLayout;
