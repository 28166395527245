import React from 'react';
import { TextField, TextFieldProps } from '../textField';
import { Box } from '@mui/material';
import InputProgressBar from '../inputProgressBar/inputProgressBar';

type TextareaWithCounterProps = {
  id?: string;
  placeholder?: string;
  value: string;
  errorMessage: string | undefined;
  isTouched?: boolean;
} & TextFieldProps;

const TextareaWithCounter: React.FC<TextareaWithCounterProps> = ({
  id,
  placeholder,
  value,
  errorMessage,
  isTouched,
  ...props
}) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <TextField
        multiline
        fullWidth
        minRows={3}
        id={id}
        value={value}
        placeholder={placeholder}
        errorMessage={errorMessage}
        isTouched={isTouched}
        {...props}
      />
      <InputProgressBar
        maxTextLength={props.inputProps?.maxLength || 0}
        count={value.length}
        sx={{
          position: 'absolute',
          bottom: errorMessage != undefined && isTouched ? '33px' : '10px',
          right: '10px',
          marginTop: -12,
        }}
      />
    </Box>
  );
};

export default TextareaWithCounter;
