import React from 'react';
import { Box, Typography } from '@mui/material';

type ReadOnlyEmailProps = {
  menuList: string[];
};

const ReadOnlyEmail: React.FC<ReadOnlyEmailProps> = ({ menuList }) => {
  return (
    <Box>
      {menuList &&
        menuList.map((email) => (
          <Typography
            key={email}
            sx={{
              width: '100%',
              overflow: 'hidden',
              textWrap: 'nowrap',
              textOverflow: 'ellipsis',
              fontSize: '10px',
            }}
          >
            {email}
          </Typography>
        ))}
    </Box>
  );
};

export default ReadOnlyEmail;
