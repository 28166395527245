import { EventSourcePolyfill } from 'event-source-polyfill';
import { Configuration, CyphImageVerificationStatus } from '../constants';

class MFAVerificationStatusService {
  private eventSource: EventSource | null = null;
  private onChangeStatus: ((status: string) => void) | null = null;

  public start(
    sessionId: string,
    onChangeStatus: (status: string) => void,
  ): void {
    this.onChangeStatus = onChangeStatus;
    this.setupEventSource(sessionId);
  }

  private setupEventSource(sessionId: string): void {
    this.eventSource = new EventSourcePolyfill(
      `${Configuration.API_URL}cyphlens/status-events?sessionId=${sessionId}`,
      {
        headers: {
          Accept: 'application/stream+json',
        },
      },
    );

    this.eventSource.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      const status = data?.status;
      if (status && this.onChangeStatus) {
        this.onChangeStatus(status);
        if (status !== CyphImageVerificationStatus?.pending) {
          this.stop();
        }
      }
    };
  }

  public stop(): void {
    if (this.eventSource) {
      this.eventSource.close();
    }
  }
}

export default new MFAVerificationStatusService();
