import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
  useTheme,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useTranslation } from 'react-i18next';

type FaqAccordionProps = {
  title: string;
  description: string;
};

const FaqAccordion: React.FC<FaqAccordionProps> = ({ title, description }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Accordion sx={{ boxShadow: 'none' }}>
      <AccordionSummary
        expandIcon={
          <Box
            sx={{
              '.Mui-expanded & > .collapseIconWrapper': {
                display: 'none',
              },
              '.expandIconWrapper': {
                display: 'none',
              },
              '.Mui-expanded & > .expandIconWrapper': {
                display: 'block',
              },
            }}
          >
            <div className="expandIconWrapper">
              <RemoveCircleOutlineIcon />
            </div>
            <div className="collapseIconWrapper">
              <AddCircleOutlineIcon />
            </div>
          </Box>
        }
        aria-controls="accordion-content"
        id="accordion-header"
      >
        <Typography
          sx={{
            fontFamily: theme?.font?.primaryBold,
            fontSize: '14px',
            color: theme?.palette?.text?.secondary,
          }}
        >
          {t(title)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          sx={{
            color: theme?.palette?.text?.primary,
            fontSize: '14px',
          }}
        >
          {t(description)}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default FaqAccordion;
