export const faqContent = [
  {
    title: 'faqPage.canIUseMultipleAccountTitle',
    description: 'faqPage.canIUseMultipleAccountDescription',
  },
  {
    title: 'faqPage.canILoginWithDifferentDeviceTitle',
    description: 'faqPage.canILoginWithDifferentDeviceDescription',
  },
  {
    title: 'faqPage.howDoITransferCyphlensAccountTitle',
    description: 'faqPage.howDoITransferCyphlensAccountDescription',
  },
  {
    title: 'faqPage.whyToAddEmergencyContactTitle',
    description: 'faqPage.whyToAddEmergencyContactDescription',
  },
  {
    title: 'faqPage.whatToDoIfDeviceStolenTitle',
    description: 'faqPage.whatToDoIfDeviceStolenDescription',
  },
  {
    title: 'faqPage.whatDoesLockIconDoTitle',
    description: 'faqPage.whatDoesLockIconDoDescription',
  },
  {
    title: 'faqPage.whatDoesSunIconDoTitle',
    description: 'faqPage.whatDoesSunIconDoDescription',
  },
  {
    title: 'faqPage.howToUpdatePasswordTitle',
    description: 'faqPage.howToUpdatePasswordDescription',
  },
  {
    title: 'faqPage.howToReceiveAssistanceTitle',
    description: 'faqPage.howToReceiveAssistanceDescription',
  },
];
