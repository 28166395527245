import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import { ExpandMore } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import EditEmailList from './EditEmailList';
import ReadOnlyEmail from './ReadOnlyEmail';

interface EditSharedEmailProps {
  menuList: string[];
  width?: string;
  isViewOnly?: boolean;
  setMenuList: (menuList: string[]) => void;
  onClickEditApply: () => void;
  onClickEditCancel?: () => void;
}

const EditSharedEmail: React.FC<EditSharedEmailProps> = ({
  menuList,
  width,
  isViewOnly,
  setMenuList,
  onClickEditApply,
  onClickEditCancel,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [value, setValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!isViewOnly || (isViewOnly && menuList?.length > 0))
        setAnchorEl(event.currentTarget);
    },
    [isViewOnly, menuList?.length],
  );

  const handleClose = useCallback(() => {
    onClickEditCancel?.();
    setAnchorEl(null);
    setErrorMessage('');
  }, [onClickEditCancel]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      setErrorMessage(null);
    },
    [],
  );

  const validateEmail = useCallback((email: string) => {
    const schema = Yup.string().email();
    return schema.isValidSync(email);
  }, []);

  const isAlreadyExists = useCallback(
    (email: string) => {
      return menuList.includes(email);
    },
    [menuList],
  );

  const handleAddEmail = useCallback(() => {
    setErrorMessage(null);
    if (value != '' && validateEmail(value) && !isAlreadyExists(value)) {
      setMenuList([...menuList, value]);
      setValue('');
    } else if (isAlreadyExists(value)) {
      setErrorMessage(t('validation.emailAlreadyExists'));
    } else if (value != '') {
      setErrorMessage(t('validation.invalidEmail'));
    } else {
      setValue('');
    }
  }, [t, menuList, value, setMenuList, validateEmail, isAlreadyExists]);

  const handleBlurEvent = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      event.preventDefault();
      handleAddEmail();
      setAnchorEl(null);
    },
    [handleAddEmail],
  );

  const handleEnterKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleAddEmail();
      }
    },
    [handleAddEmail],
  );

  const onClickRemove = useCallback(
    (index: number) => {
      setMenuList([...menuList.slice(0, index), ...menuList.slice(index + 1)]);
    },
    [menuList, setMenuList],
  );

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingLeft="11px"
        paddingRight="11px"
        gap="5px"
        maxHeight="30px"
        height="30px"
        data-testid="email-picker"
        sx={{
          maxWidth: { width },
          overflowY: 'hidden',
          overflowX: 'hidden',
          minWidth: 0,
          borderRadius: '5px',
          border: isViewOnly
            ? 'none'
            : `1px solid ${theme.palette.echoBlue?.[100]}`,
        }}
        onClick={handleClick}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          gap="5px"
          width="152px"
          sx={{
            flex: 1,
            overflow: 'hidden',
            textWrap: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {menuList?.[0] ?? '-'}
        </Box>
        {(menuList?.length > 0 || !isViewOnly) && (
          <ExpandMore sx={{ fontSize: '20px', marginLeft: '15px' }} />
        )}
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-testid="email-picker-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              width: isViewOnly ? '150px' : anchorEl?.offsetWidth,
              border: `1px solid ${theme.palette?.echoBlue?.[100]}`,
              borderRadius: '5px',
              padding: '10px',
              boxShadow: 'none',
              marginTop: '12px',
            },
          },
        }}
      >
        {isViewOnly ? (
          <ReadOnlyEmail menuList={menuList} />
        ) : (
          <EditEmailList
            value={value}
            menuList={menuList}
            onBlurEvent={handleBlurEvent}
            onClickClose={handleClose}
            onClickEditApply={onClickEditApply}
            onClickRemove={onClickRemove}
            onEnterKeyPress={handleEnterKeyPress}
            onInputChange={handleInputChange}
            isTouched={errorMessage != undefined}
            errorMessage={errorMessage}
          />
        )}
      </Popover>
    </Box>
  );
};

export default EditSharedEmail;
