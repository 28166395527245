import * as React from 'react';
import { Modal as BaseModal, Box } from '@mui/material';
import Fade from '@mui/material/Fade';
import ShareTypeConfirmation from './ShareTypeConfirmation';
import ShareTypeEmail from './ShareTypeEmail';
import ShareTypeMessage from './ShareTypeMessage';
import { ShareLayoutType } from '../../pages/CreateCyphlensImagePage';
import { useTheme } from '@mui/material/styles';

export type ShareProps = {
  isLoading?: boolean;
  isSharePopupOpen?: boolean;
  screenType: ShareLayoutType;
  onSharePopupClose?: () => void;
  onEmailOptionSelected?: () => void;
  onMessageOptionSelected?: () => void;
  shareLayoutType: ShareLayoutType;
  onSendEmailEvent: (emailList: string[]) => void;
  onClickMessageSendButton: (phoneCode: string, phoneNumber: string) => void;
};

const ShareLayout: React.FC<ShareProps> = ({
  isLoading,
  isSharePopupOpen = false,
  screenType,
  onSharePopupClose,
  onEmailOptionSelected,
  onMessageOptionSelected,
  onSendEmailEvent,
  onClickMessageSendButton,
  shareLayoutType,
}: ShareProps) => {
  const theme = useTheme();
  return (
    <Box>
      <BaseModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isSharePopupOpen}
        closeAfterTransition
        sx={{
          position: 'fixed',
          zIndex: 1300,
          inset: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(15, 15, 15, 0.7)',
        }}
      >
        <Fade in={isSharePopupOpen}>
          <Box
            data-testid="share-layout"
            sx={{
              backgroundColor: theme.palette.background.paper,
              width: '500px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            {screenType === ShareLayoutType.confirmation && (
              <ShareTypeConfirmation
                onSharePopupClose={onSharePopupClose}
                onEmailOptionSelected={onEmailOptionSelected}
                onMessageOptionSelected={onMessageOptionSelected}
                shareLayoutType={shareLayoutType}
              />
            )}
            {screenType === ShareLayoutType.email && (
              <ShareTypeEmail
                isLoading={isLoading}
                onSharePopupClose={onSharePopupClose}
                onSendEmailEvent={onSendEmailEvent}
              />
            )}
            {screenType === ShareLayoutType.sms && (
              <ShareTypeMessage
                isLoading={isLoading}
                onSharePopupClose={onSharePopupClose}
                onClickMessageSendButton={onClickMessageSendButton}
              />
            )}
          </Box>
        </Fade>
      </BaseModal>
    </Box>
  );
};

export default ShareLayout;
