import { jsPDF } from 'jspdf';
import { CreateImageResponse } from '../types/CreateImage';
import { Buffer } from 'buffer';
const PDF_IMAGE_TOP_MARGIN = 10;

export class DocumentService {
  defaultFileName = 'CyphlensImage.pdf';
  defaultImageFileName = 'CyphlensImage.png';
  allImageSlug = 'cyphlens-image-viewer';

  createPdf(createCyphlensResponse: CreateImageResponse[]) {
    const doc = new jsPDF({
      unit: 'pt',
      format: 'letter',
    });
    let pdfX = 0;
    let pdfY = 0;
    let highestY = 0;
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    createCyphlensResponse.forEach((response) => {
      const { width, height } = this.getDimensions(response.image);
      const scaleDownWidth = width * 0.5;
      const scaleDownHeight = (height * scaleDownWidth) / width;
      if (pdfX + scaleDownWidth > pageWidth) {
        pdfY = pdfY + highestY + PDF_IMAGE_TOP_MARGIN;
        pdfX = 0;
      }
      if (
        pdfX + scaleDownWidth > pageWidth ||
        pdfY + scaleDownHeight > pageHeight
      ) {
        doc.addPage();
        pdfX = 0;
        pdfY = 0;
        highestY = 0;
      }
      doc.addImage(
        response.image,
        'PNG',
        pdfX,
        pdfY,
        scaleDownWidth,
        scaleDownHeight,
        response.id,
        'NONE',
        0,
      );
      pdfX = pdfX + scaleDownWidth;
      highestY = Math.max(highestY, scaleDownHeight);
    });
    return doc;
  }

  downloadPdf(createCyphlensResponse: CreateImageResponse[]) {
    const doc = this.createPdf(createCyphlensResponse);
    doc.save(this.defaultFileName);
  }

  getDimensions(base64: string) {
    const buffer = Buffer.from(base64, 'base64');
    const format = buffer.toString('ascii', 0, 4);
    if (format.trim() == 'PNG') {
      return {
        width: buffer.readUInt32BE(16),
        height: buffer.readUInt32BE(20),
      };
    } else throw new Error('Unsupported image format');
  }

  async shareImage(image: string) {
    const res = await fetch(`data:image/png;base64,${image}`);
    const blob = await res.blob();
    return new File([blob], this.defaultImageFileName, {
      type: 'image/png',
    });
  }

  sharePdf(createCyphlensResponse: CreateImageResponse[]) {
    if (createCyphlensResponse.length === 0) return null;
    const doc = this.createPdf(createCyphlensResponse);
    return new File([doc.output('blob')], this.defaultFileName, {
      type: 'application/pdf',
    });
  }

  downloadAsImage(image: string, id: string) {
    const base64 = `data:image/png;base64,${image}`;
    const link = document.createElement('a');
    link.href = base64;
    link.download = id + '.png';
    link.click();
  }
}
