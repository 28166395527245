import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Typography, Paper, Box, Link } from '@mui/material';
import { theme } from '../../theme';
import { Button, TextField } from '../../components';
import { RedirectionUrl } from '../../constants';

export type RecoverFormType = {
  email: string;
  password: string;
};

export type RecoverFormProps = {
  isLoading?: boolean;
  onClickLogin: (values: RecoverFormType) => void;
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email('validation.invalidEmailError')
    .required('validation.emailError'),
  password: Yup.string().required('validation.passwordError'),
});

const RecoverForm: React.FC<RecoverFormProps> = ({
  isLoading,
  onClickLogin,
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: onClickLogin,
  });

  return (
    <Paper
      data-testid="recover-form"
      sx={{
        background: theme?.palette?.background?.paper,
        padding: '40px',
        borderRadius: '5px',
        boxShadow: `0 0 10px ${theme?.palette?.black?.[400]}`,
        width: '440px',
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '36px',
          gap: '10px',
          marginBottom: '20px',
          fontWeight: 600,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: '20px',
            fontFamily: theme?.font?.primaryBold,
            color: theme?.palette?.text?.secondary,
          }}
        >
          {t('recoverAccountForm.recoverAccountButtonLabel')}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Box sx={{ marginBottom: '10px' }}>
            <TextField
              id="email"
              placeholder={t('recoverAccountForm.email')}
              name="email"
              type="text"
              value={formik.values.email}
              errorMessage={formik?.errors?.email}
              isTouched={formik?.touched?.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Box>
          <Box sx={{ marginBottom: '10px' }}>
            <TextField
              name="password"
              placeholder={t('recoverAccountForm.password')}
              type={'password'}
              id="password"
              value={formik.values.password}
              errorMessage={formik.errors?.password}
              isTouched={formik?.touched?.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Box>
          <Typography
            style={{ fontSize: '12px', color: theme?.palette?.grey?.[200] }}
          >
            {t('recoverAccountForm.termsAndConditionsLabel')}{' '}
            <Link
              href={RedirectionUrl.termsAndConditionUrl}
              target="_blank"
              sx={{
                color: theme?.palette?.secondary?.main,
                textDecoration: 'underline',
              }}
            >
              {t('recoverAccountForm.termsAndConditions')}
            </Link>
            {t('recoverAccountForm.middleLabel')}
            <Link
              href={RedirectionUrl.privacyPolicyUrl}
              target="_blank"
              sx={{
                color: theme?.palette?.secondary?.main,
                textDecoration: 'underline',
              }}
            >
              {t('recoverAccountForm.privacyPolicy')}
            </Link>
          </Typography>
          <Box marginTop="30px">
            <Button
              disabled={!formik.isValid}
              title={t('recoverAccountForm.login')}
              type="submit"
              variant="contained"
              isLoading={isLoading}
            />
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default RecoverForm;
