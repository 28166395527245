import React, { useCallback, useState, useMemo } from 'react';
import { SnackbarContext } from '../../state/SnackbarContext';
import Snackbar, { SeverityType } from './Snackbar';

type SnackbarProviderProps = {
  children: JSX.Element;
};

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [severity, setSeverity] = useState<SeverityType>(SeverityType.error);

  const handleOpen = useCallback((value: string, severity?: SeverityType) => {
    setIsVisible(true);
    setMessage(value);
    setSeverity(severity || SeverityType.error);
  }, []);

  const handleClose = useCallback(() => {
    setIsVisible(false);
    setMessage(null);
  }, []);

  const _values = useMemo(() => ({ show: handleOpen }), [handleOpen]);

  return (
    <SnackbarContext.Provider value={_values}>
      {children}
      <Snackbar
        open={isVisible}
        severity={severity}
        message={message}
        onClose={handleClose}
      />
    </SnackbarContext.Provider>
  );
};
