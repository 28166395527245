import React from 'react';
import { Typography, Box, useTheme, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LogoImages } from '../../assets/images';
import EmergencyContactSuccess from './EmergencyContactSuccess';
import EmergencyContactError from './EmergencyContactError';

export type EmergencyContactLayoutProps = {
  isSuccess: boolean;
};

const EmergencyContactLayout: React.FC<EmergencyContactLayoutProps> = ({
  isSuccess,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid
      container
      style={{
        display: 'grid',
        height: '100vh',
        gridTemplateRows: 'auto .9fr',
        gridTemplateColumns: '1fr',
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '.5em',
          backgroundColor: theme?.palette?.black?.[900],
          gridColumn: '1',
          gridRow: '1',
        }}
      >
        <Box
          component="img"
          alt="Cyphlens Logo"
          src={LogoImages.cyphlensLogo}
          width="16%"
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '2rem',
          textAlign: 'center',
          gridColumn: 1,
          gridRow: 2,
        }}
      >
        {isSuccess ? <EmergencyContactSuccess /> : <EmergencyContactError />}
        <Typography color={theme?.palette?.grey?.[400]}>
          {t('emergencyContactPage.closeMessage')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EmergencyContactLayout;
