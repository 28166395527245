import Loading from './Loading';
import React, { useContext, useMemo, useState } from 'react';

interface LoadingContextProps {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoadingContext = React.createContext<LoadingContextProps>(
  {} as LoadingContextProps,
);

export const useLoading = () => useContext(LoadingContext);

const LoadingProvider = ({ children }: { children?: React.ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({ loading, setLoading }),
    [loading, setLoading],
  );
  return (
    <LoadingContext.Provider value={contextValue}>
      <Loading isLoading={loading} />
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
