import { AxiosProgressEvent } from 'axios';
import React, { useCallback, useState } from 'react';
import CreateCyphlensImageLayout from '../layout/createCyphlensImage';
import { CreateCyphlensImageFormType } from '../layout/createCyphlensImage/CreateCyphlensImageForm';
import { useSnackbar } from '../state';
import { CreateImageRepository } from '../repositories';
import {
  CreateImageResponse,
  ShareCyphlensImageResponse,
  UploadFileResponse,
} from '../types/CreateImage';
import { useTranslation } from 'react-i18next';
import { DecryptionType, SINGLE_USE_TEXT_COUNT } from '../constants';
import { DocumentService } from '../services/DocumentService';
import { SeverityType } from '../components/snackbar/Snackbar';
import { TimezoneUtil } from '../utils/TimezoneUtil';

export enum ShareLayoutType {
  email = 'email',
  sms = 'sms',
  confirmation = 'confirmation',
}

const CreateCyphlensImagePage = () => {
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const snackbar = useSnackbar();
  const [cyphlensImages, setCyphlensImages] = useState<CreateImageResponse[]>(
    [],
  );
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedShareImage, setSelectedShareImage] =
    useState<CreateImageResponse | null>(null);
  const [isSharePopupOpen, setSharePopupOpen] = useState(false);
  const [shareLayoutType, setShareLayoutType] = useState(
    ShareLayoutType.confirmation,
  );
  const defaultErrorMessage = 'createCyphlensImage.defaultErrorMessage';

  const onUploadProgressChange = useCallback(
    (progressEvent: AxiosProgressEvent) => {
      setUploadProgress(
        Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? 0)),
      );
    },
    [],
  );

  const uploadSelectedFile = useCallback(() => {
    if (selectedFile != null) {
      return CreateImageRepository.uploadFile(
        selectedFile,
        onUploadProgressChange,
      );
    }
  }, [selectedFile, onUploadProgressChange]);

  const timeZoneToUtc = useCallback(
    (expirationDate: Date, expirationTimeZone: string) => {
      return TimezoneUtil.convertDateToUtcEpoch(
        expirationDate,
        expirationTimeZone,
      );
    },
    [],
  );

  const handleCreateImage = useCallback(
    async (values: CreateCyphlensImageFormType) => {
      try {
        setLoading(true);
        const uploadedFile: UploadFileResponse | undefined =
          await uploadSelectedFile();
        const decryptionType = values.isOverlay
          ? DecryptionType?.overlay
          : values.decryptionType;
        const imageResponse: CreateImageResponse =
          await CreateImageRepository.createImage(
            values.title,
            uploadedFile && uploadedFile.shortUrl != null
              ? uploadedFile.shortUrl + '\n' + values.message
              : values.message,
            values.accessType,
            decryptionType,
            values.canShowTitle,
            values.isOverlay,
            values.emailList.map((email) => ({ emails: [email] })),
            values.expirationDate
              ? timeZoneToUtc(values.expirationDate, values.expirationTimeZone)
              : null,
            values.singleUseOnly,
          );
        setCyphlensImages([...cyphlensImages, imageResponse]);
        setLoading(false);
        setSelectedFile(null);
        setUploadProgress(0);
      } catch (error: unknown) {
        setLoading(false);
        if (error instanceof Error && error?.message) {
          snackbar.show(error.message);
        } else {
          snackbar.show(t('createCyphlensImage.defaultErrorMessage'));
        }
      }
    },
    [snackbar, cyphlensImages, t, uploadSelectedFile, timeZoneToUtc],
  );

  const handleFileChange = useCallback((file: File) => {
    setSelectedFile(file);
  }, []);

  const onShareButtonClick = useCallback(() => {
    setSharePopupOpen(true);
  }, [setSharePopupOpen]);

  const handleSharePopupCloseEvent = useCallback(() => {
    setShareLayoutType(ShareLayoutType.confirmation);
    setSharePopupOpen(false);
    setSelectedShareImage(null);
  }, [setSharePopupOpen]);

  const getFileToBeShared = useCallback(async () => {
    let documentToBeShared;
    if (selectedShareImage == null) {
      documentToBeShared = await new DocumentService().sharePdf(cyphlensImages);
    } else {
      documentToBeShared = new DocumentService().shareImage(
        selectedShareImage.image,
      );
    }
    return documentToBeShared;
  }, [selectedShareImage, cyphlensImages]);

  const handleDownloadButtonClick = useCallback(() => {
    new DocumentService().downloadPdf(cyphlensImages);
  }, [cyphlensImages]);

  const onFileRemove = useCallback(() => {
    setSelectedFile(null);
    setUploadProgress(0);
  }, [setSelectedFile]);

  const handleEmailOptionSelectedEvent = useCallback(() => {
    setShareLayoutType(ShareLayoutType.email);
  }, []);

  const handleMessageOptionSelectedEvent = useCallback(() => {
    setShareLayoutType(ShareLayoutType.sms);
  }, []);

  const handleSendEmailEvent = useCallback(
    async (emailId: string[]) => {
      try {
        setLoading(true);
        const documentToBeShared = await getFileToBeShared();
        if (documentToBeShared != null) {
          const shareResponse: ShareCyphlensImageResponse =
            await CreateImageRepository.shareCyphlensImage(
              shareLayoutType.toString(),
              documentToBeShared,
              emailId.join(','),
            );
          snackbar.show(shareResponse.message, SeverityType.success);
        } else {
          snackbar.show(t(defaultErrorMessage));
        }
        setLoading(false);
      } catch (error: unknown) {
        setLoading(false);
        if (error instanceof Error && error?.message) {
          snackbar.show(error.message);
        } else {
          snackbar.show(t(defaultErrorMessage));
        }
      }
      handleSharePopupCloseEvent();
    },
    [
      snackbar,
      shareLayoutType,
      t,
      getFileToBeShared,
      handleSharePopupCloseEvent,
    ],
  );

  const onClickMessageSendButton = useCallback(
    async (phoneCode: string, phoneNumber: string) => {
      try {
        setLoading(true);
        const documentToBeShared = await getFileToBeShared();
        if (documentToBeShared != null) {
          const shareResponse: ShareCyphlensImageResponse =
            await CreateImageRepository.shareCyphlensImage(
              shareLayoutType.toString(),
              documentToBeShared,
              undefined,
              phoneCode + phoneNumber,
            );
          snackbar.show(shareResponse.message, SeverityType.success);
        } else {
          snackbar.show(t(defaultErrorMessage));
        }
        setLoading(false);
      } catch (error: unknown) {
        setLoading(false);
        if (error instanceof Error && error?.message) {
          snackbar.show(error.message);
        } else {
          snackbar.show(t(defaultErrorMessage));
        }
      }
      handleSharePopupCloseEvent();
    },
    [
      snackbar,
      shareLayoutType,
      t,
      getFileToBeShared,
      handleSharePopupCloseEvent,
    ],
  );

  const handleDeleteImageItemEvent = useCallback(
    (itemId: string) => {
      const newCyphlensImages = cyphlensImages.filter(
        (image) => image.id !== itemId,
      );
      setCyphlensImages(newCyphlensImages);
    },
    [cyphlensImages],
  );

  const handleShareImageItemEvent = useCallback(
    (itemId: string) => {
      const imageToShare = cyphlensImages.find((image) => image.id === itemId);
      if (imageToShare != null) {
        setSelectedShareImage(imageToShare);
        onShareButtonClick();
      }
    },
    [cyphlensImages, onShareButtonClick],
  );

  const handleDownloadImageItemEvent = useCallback(
    (itemId: string) => {
      const imageToDownload = cyphlensImages.find(
        (image) => image.id === itemId,
      );
      if (imageToDownload != null) {
        new DocumentService().downloadAsImage(
          imageToDownload.image,
          imageToDownload.id,
        );
      }
    },
    [cyphlensImages],
  );

  return (
    <CreateCyphlensImageLayout
      onCreateImage={handleCreateImage}
      onFileChange={handleFileChange}
      isLoading={loading}
      isSharePopupOpen={isSharePopupOpen}
      cyphlensImages={cyphlensImages}
      selectedFile={selectedFile}
      shareLayoutType={shareLayoutType}
      uploadProgress={uploadProgress}
      onFileRemove={onFileRemove}
      onShareButtonClick={onShareButtonClick}
      onDownloadButtonClick={handleDownloadButtonClick}
      singleUseTextCount={SINGLE_USE_TEXT_COUNT}
      onSharePopupClose={handleSharePopupCloseEvent}
      onMessageOptionSelected={handleMessageOptionSelectedEvent}
      onEmailOptionSelected={handleEmailOptionSelectedEvent}
      onSendEmailEvent={handleSendEmailEvent}
      onClickMessageSendButton={onClickMessageSendButton}
      onDeleteImageItemEvent={handleDeleteImageItemEvent}
      onDownloadImageItemEvent={handleDownloadImageItemEvent}
      onShareImageItemEvent={handleShareImageItemEvent}
    />
  );
};

export default CreateCyphlensImagePage;
