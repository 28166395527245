export const Configuration = {
  API_URL: process.env.REACT_APP_API_URL,
};

export const MFA_REFRESH_IN_SECONDS = 60;

export const PAGE_COUNT = 20;

export const SINGLE_USE_TEXT_COUNT = 3;

export const DESCRIPTION_MAX_LENGTH = 150;

export const ActivityLogFilter = {
  fromDate: 'fromDate=',
  toDate: 'toDate=',
  dateFormat: 'dd/MM/yyyy',
};

export const singleUseFileExpiration = 3;

export const AccessType = {
  private: 'PRIVATE',
  public: 'PUBLIC',
  shared: 'SHARED',
};

export const AccountStatus = {
  active: 'Active',
  inActive: 'InActive',
  activeCaps: 'ACTIVE',
};

export const DecryptionType = {
  overlay: 'OVERLAY',
  popUp: 'POPUP',
};

export const AccessTypeOptions = [
  { label: 'Shared', value: 'SHARED' },
  { label: 'Public', value: 'PUBLIC' },
  { label: 'Private', value: 'PRIVATE' },
];

export const sessionExpired = 'Session expired';
