import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Link } from '@mui/material';
import { theme } from '../../theme';
import { TextField, Button } from '../../components';
import { RedirectionUrl } from '../../constants';
import { LoginFormType } from './LoginForm';

type EmailFormProps = {
  isLoading?: boolean;
  values: LoginFormType;
  errors?: Partial<Record<keyof LoginFormType, string>>;
  touched?: Partial<Record<keyof LoginFormType, boolean>>;
  isDisabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onClickRecover: () => void;
};

const EmailForm: React.FC<EmailFormProps> = ({
  isLoading,
  values,
  errors,
  touched,
  isDisabled,
  onChange,
  onBlur,
  onClickRecover,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={{ marginBottom: '10px' }}>
        <TextField
          id="email"
          placeholder={t('loginForm.email')}
          name="email"
          type="text"
          value={values.email}
          errorMessage={errors?.email}
          isTouched={touched?.email}
          onBlur={onBlur}
          onChange={onChange}
        />
      </Box>
      <Typography
        style={{ fontSize: '12px', color: theme?.palette?.grey?.[200] }}
      >
        {t('loginForm.termsAndConditionsLabel')}{' '}
        <Link
          href={RedirectionUrl.termsAndConditionUrl}
          target="_blank"
          sx={{
            color: theme?.palette?.secondary?.main,
            textDecoration: 'underline',
          }}
        >
          {t('loginForm.termsAndConditions')}
        </Link>
        {t('loginForm.middleLabel')}
        <Link
          href={RedirectionUrl.privacyPolicyUrl}
          target="_blank"
          sx={{
            color: theme?.palette?.secondary?.main,
            textDecoration: 'underline',
          }}
        >
          {t('loginForm.privacyPolicy')}
        </Link>
      </Typography>
      <Box marginTop="30px">
        <Button
          disabled={isDisabled}
          type="submit"
          variant="contained"
          isLoading={isLoading}
          title={t('loginForm.nextButtonLabel')}
        />
      </Box>
      <Typography
        variant="subtitle1"
        style={{
          fontSize: '14px',
          fontWeight: 500,
          cursor: 'pointer',
          marginTop: '30px',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          textDecoration: 'underline',
          color: theme?.palette?.text?.secondary,
        }}
        onClick={onClickRecover}
      >
        {t('loginForm.recoverAccountButtonLabel')}
      </Typography>
    </Box>
  );
};

export default EmailForm;
