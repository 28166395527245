import { EmergencyContactListResponse } from '../types';
import { getHttpClient } from './AxiosClient';

export const emergencyContactList =
  (): Promise<EmergencyContactListResponse> => {
    return getHttpClient('recover/list', 'POST');
  };

export const recover = (id: string) => {
  return getHttpClient('recover', 'POST', { id });
};
