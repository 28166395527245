import React from 'react';
import { Divider, Menu as MUIMenu, MenuItem, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

type MenuProps = {
  anchorEl: HTMLElement | null | undefined;
  onClose: () => void;
  onClickDelete: () => void;
  onClickEdit: () => void;
};

const Menu: React.FC<MenuProps> = ({
  anchorEl,
  onClose,
  onClickDelete,
  onClickEdit,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <MUIMenu
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiList-root': {
          padding: '5px 0',
        },
        '& .MuiPaper-root': {
          border: `1px solid ${theme?.palette?.echoBlue?.[100]}`,
          boxShadow: 'none',
        },
      }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem
        sx={{
          fontSize: '14px',
          color: theme?.palette?.black?.[800],
          padding: '0 5px',
        }}
        onClick={onClickEdit}
      >
        {t('menuComponent.editLabel')}
      </MenuItem>
      <Divider sx={{ margin: '0 5px' }} />
      <MenuItem
        sx={{
          fontSize: '14px',
          color: theme?.palette?.black?.[800],
          padding: '0 5px',
        }}
        onClick={onClickDelete}
      >
        {t('menuComponent.deleteLabel')}
      </MenuItem>
    </MUIMenu>
  );
};

export default Menu;
