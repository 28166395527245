import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface Option {
  value: string | number;
  label: string;
}

interface DropdownProps {
  id: string;
  onSelectValue: (event: SelectChangeEvent) => void;
  value: string;
  options: Option[];
  sx?: SxProps<Theme>;
  isDisabled?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  id,
  onSelectValue,
  value,
  options,
  sx,
  isDisabled,
}) => {
  const theme = useTheme();
  return (
    <Select
      data-testid={id}
      id={id}
      value={value}
      onChange={onSelectValue}
      disabled={isDisabled}
      inputProps={{ 'aria-label': 'Without label' }}
      sx={{
        fontFamily: theme.font?.primary,
        height: 40,
        color: theme?.palette?.black?.[800],
        borderRadius: '5px 0 0 5px',
        minWidth: 190,
        fontSize: 12,
        ...sx,
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{
            fontFamily: theme.font?.primary,
            fontSize: 12,
          }}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default Dropdown;
