import React, { useMemo } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import { DocumentData } from '../../types/FileManager';
import { DatePicker, Menu, NoDataContainer } from '../../components';
import EditSharedEmail from './EditSharedEmail';
import { AccessType } from '../../constants';

type FileManagerListTableProps = {
  data: DocumentData[];
  anchorEl?: HTMLElement | null;
  isEdit: boolean;
  selectedDocument: DocumentData | null;
  menuList: string[];
  onChangeExpiresOn: (date: Date | null) => void;
  setMenuList: (menuList: string[]) => void;
  onClickDeleteDocument: () => void;
  onClickMenu: (
    data: DocumentData,
  ) => (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickMenuClose: () => void;
  onClickEdit: () => void;
  onClickEmailEdit: () => void;
  onClickCancelEdit: () => void;
  onApplyExpireOn: (date: Date | null) => void;
};

const FileManagerListTable: React.FC<FileManagerListTableProps> = ({
  data,
  anchorEl,
  isEdit,
  selectedDocument,
  menuList,
  setMenuList,
  onChangeExpiresOn,
  onClickDeleteDocument,
  onClickMenu,
  onClickMenuClose,
  onClickEdit,
  onClickEmailEdit,
  onClickCancelEdit,
  onApplyExpireOn,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const tableHeadStyle = useMemo(() => {
    return {
      fontFamily: theme?.font?.primaryBold,
      padding: '10px',
      color: theme?.palette?.background?.paper,
      width: '15%',
    };
  }, [theme?.palette?.background?.paper, theme?.font?.primaryBold]);

  const tableBodyStyle = useMemo(() => {
    return {
      padding: '4px 10px',
      borderBottom: `1px solid ${theme?.palette?.echoBlue?.[100]}`,
      maxWidth: '200px',
    };
  }, [theme?.palette?.echoBlue]);

  return (
    <Box sx={{ marginTop: '25px' }}>
      <TableContainer component={Paper}>
        <Table
          sx={{
            borderRadius: '5px 5px 0px 0px',
            border: `1px solid ${theme?.palette?.echoBlue?.[100]}`,
          }}
          aria-label="file manager table"
        >
          <TableHead sx={{ backgroundColor: theme?.palette?.black?.[600] }}>
            <TableRow>
              <TableCell sx={tableHeadStyle}>
                {t('fileManagerTable.title')}
              </TableCell>
              <TableCell sx={tableHeadStyle}>
                {t('fileManagerTable.accessType')}
              </TableCell>
              <TableCell sx={{ ...tableHeadStyle, width: '150px' }}>
                {t('fileManagerTable.createdOn')}
              </TableCell>
              <TableCell sx={{ ...tableHeadStyle, width: '150px' }}>
                {t('fileManagerTable.expiresOn')}
              </TableCell>
              <TableCell sx={tableHeadStyle}>
                {t('fileManagerTable.expiresAfter')}
              </TableCell>
              <TableCell sx={{ ...tableHeadStyle, width: '300px' }}>
                {t('fileManagerTable.sharedWith')}
              </TableCell>
              <TableCell
                sx={{
                  color: theme?.palette?.background?.paper,
                }}
              />
            </TableRow>
          </TableHead>
          <TableBody sx={{ height: '40px' }}>
            {data?.length > 0 ? (
              data?.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={tableBodyStyle}>{row.title}</TableCell>
                  <TableCell sx={tableBodyStyle}>{row.accessType}</TableCell>
                  <TableCell sx={tableBodyStyle}>{row.createOn}</TableCell>
                  <TableCell sx={tableBodyStyle}>
                    {isEdit && row?.id === selectedDocument?.id ? (
                      <DatePicker
                        label={''}
                        value={row?.expiresOnDate}
                        onClose={onClickCancelEdit}
                        onAccept={onApplyExpireOn}
                        disablePast
                        onChange={onChangeExpiresOn}
                      />
                    ) : (
                      row.expireOn
                    )}
                  </TableCell>
                  <TableCell sx={tableBodyStyle}>{row?.expiresAfter}</TableCell>
                  <TableCell sx={tableBodyStyle}>
                    <EditSharedEmail
                      menuList={
                        isEdit && row?.id === selectedDocument?.id
                          ? menuList
                          : row?.sharedWith
                      }
                      setMenuList={setMenuList}
                      width={'200px'}
                      isViewOnly={
                        !isEdit ||
                        row.accessType !== AccessType?.shared ||
                        row?.id !== selectedDocument?.id
                      }
                      onClickEditApply={onClickEmailEdit}
                      onClickEditCancel={onClickCancelEdit}
                    />
                  </TableCell>
                  <TableCell sx={tableBodyStyle}>
                    <IconButton onClick={onClickMenu(row)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      onClose={onClickMenuClose}
                      onClickDelete={onClickDeleteDocument}
                      onClickEdit={onClickEdit}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7}>
                  <NoDataContainer />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FileManagerListTable;
