import React from 'react';
import { Typography, Box, TablePagination, useTheme } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { FileManagerListTable } from './';
import { FileManagerFilterData } from '../../pages/FileManagerPage';
import { TextField } from '../../components';
import { PAGE_COUNT } from '../../constants';
import { DocumentData } from '../../types/FileManager';
import FileManagerFilter from './FileManagerFilter';
import FileManagerViewFilter from './FileManagerViewFilter';

export type FileManagerLayoutProps = {
  filterData: FileManagerFilterData;
  isOpenFilter: boolean;
  page: number;
  totalRecords: number;
  anchorEl: HTMLButtonElement | null;
  anchorElMenu?: HTMLElement | null;
  fileManagerData: DocumentData[];
  isEdit: boolean;
  selectedDocument: DocumentData | null;
  menuList: string[];
  appliedFilterData?: FileManagerFilterData;
  isShowAppliedFilter?: boolean;
  onCreateDateDelete?: () => void;
  onExpireDateDelete?: () => void;
  onAccessTypeDelete?: () => void;
  onChangeExpiresOn: (date: Date | null) => void;
  setMenuList: (menuList: string[]) => void;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void;
  onFilterApply: () => void;
  onClickFilter: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onFilterReset: () => void;
  onChangeSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCreatedStartDate: (date: Date | null) => void;
  onChangeCreatedEndDate: (date: Date | null) => void;
  onChangeExpireStartDate: (date: Date | null) => void;
  onChangeExpireEndDate: (date: Date | null) => void;
  onChangeAccessType: (accessType: string[]) => void;
  onFilterClose: () => void;
  onClickDeleteDocument: () => void;
  onClickMenu: (
    data: DocumentData,
  ) => (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickMenuClose: () => void;
  onClickEdit: () => void;
  onClickEmailEdit: () => void;
  onApplySearch: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onClickSearch: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onClickCancelEdit: () => void;
  onApplyExpireOn: (date: Date | null) => void;
};

const FileManagerLayout: React.FC<FileManagerLayoutProps> = ({
  isOpenFilter,
  page,
  totalRecords,
  anchorEl,
  anchorElMenu,
  filterData,
  fileManagerData,
  isEdit,
  selectedDocument,
  menuList,
  appliedFilterData,
  isShowAppliedFilter,
  onAccessTypeDelete,
  onExpireDateDelete,
  onCreateDateDelete,
  onClickSearch,
  onChangeExpiresOn,
  setMenuList,
  onChangePage,
  onChangeCreatedStartDate,
  onChangeCreatedEndDate,
  onChangeExpireStartDate,
  onChangeExpireEndDate,
  onChangeAccessType,
  onFilterApply,
  onFilterReset,
  onFilterClose,
  onClickFilter,
  onChangeSearch,
  onClickDeleteDocument,
  onClickMenu,
  onClickMenuClose,
  onClickEdit,
  onClickEmailEdit,
  onApplySearch,
  onClickCancelEdit,
  onApplyExpireOn,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box sx={{ width: '100%', height: '100%', minHeight: '786px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">{t('fileManagerTable.tableTitle')}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <TextField
            placeholder={t('fileManagerTable.search')}
            InputProps={{
              endAdornment: (
                <Search onClick={onClickSearch} sx={{ cursor: 'pointer' }} />
              ),
            }}
            sx={{
              width: '166px',
              height: '30px',
              marginTop: 0,
              marginBottom: 0,
              '& .MuiInputBase-input': {
                padding: '4px',
              },
              '& .MuiOutlinedInput-input': {
                padding: '6px',
              },
            }}
            onChange={onChangeSearch}
            onKeyDown={onApplySearch}
          />
          <FileManagerFilter
            isOpenFilter={isOpenFilter}
            anchorEl={anchorEl}
            filterData={filterData}
            onChangeCreatedStartDate={onChangeCreatedStartDate}
            onChangeCreatedEndDate={onChangeCreatedEndDate}
            onChangeExpireStartDate={onChangeExpireStartDate}
            onChangeExpireEndDate={onChangeExpireEndDate}
            onChangeAccessType={onChangeAccessType}
            onFilterApply={onFilterApply}
            onFilterReset={onFilterReset}
            onFilterClose={onFilterClose}
            onClickFilter={onClickFilter}
          />
          <TablePagination
            sx={{
              '& .MuiButtonBase-root.MuiIconButton-root': {
                borderRadius: '5px',
                margin: '0 5px',
                width: '18px',
                height: '18px',
                backgroundColor: theme.palette.black?.[600],
                color: theme.palette.background?.paper,
                '&:hover': {
                  backgroundColor: theme.palette.black?.[600],
                },
              },
              '& .MuiButtonBase-root.MuiIconButton-root.Mui-disabled': {
                backgroundColor: theme.palette.grey?.[900],
                cursor: 'none',
              },
              '& .MuiToolbar-root.MuiTablePagination-toolbar': {
                paddingLeft: '16px',
              },
            }}
            component="div"
            count={totalRecords}
            page={page}
            onPageChange={onChangePage}
            labelRowsPerPage=""
            rowsPerPageOptions={[]}
            rowsPerPage={PAGE_COUNT}
          />
        </Box>
      </Box>
      {isShowAppliedFilter && (
        <FileManagerViewFilter
          appliedFilterData={appliedFilterData}
          onAccessTypeDelete={onAccessTypeDelete}
          onExpireDateDelete={onExpireDateDelete}
          onCreateDateDelete={onCreateDateDelete}
          onFilterReset={onFilterReset}
        />
      )}
      <FileManagerListTable
        isEdit={isEdit}
        data={fileManagerData}
        anchorEl={anchorElMenu}
        selectedDocument={selectedDocument}
        menuList={menuList}
        onChangeExpiresOn={onChangeExpiresOn}
        setMenuList={setMenuList}
        onClickMenu={onClickMenu}
        onClickMenuClose={onClickMenuClose}
        onClickDeleteDocument={onClickDeleteDocument}
        onClickEdit={onClickEdit}
        onClickEmailEdit={onClickEmailEdit}
        onClickCancelEdit={onClickCancelEdit}
        onApplyExpireOn={onApplyExpireOn}
      />
    </Box>
  );
};

export default FileManagerLayout;
