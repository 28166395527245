import * as React from 'react';
import {
  useContext,
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import type { AuthUser, TypeCallback } from '../types';
import { SessionService, StorageService } from '../services';
import { setUserSessionToken } from '../repositories/AxiosClient';
import { AuthRepository } from '../repositories';
import { useSnackbar } from './SnackbarContext';

type AuthContextType = {
  isLoggedIn: boolean;
  user: AuthUser | null;
  authenticationType: string;
  mfaVerificationStatus: string;
  setMfaVerificationStatus: TypeCallback<string>;
  updateAuthenticationType: TypeCallback<string>;
  setUser: TypeCallback<AuthUser>;
  setIsLoggedIn: TypeCallback<boolean>;
};
const AuthContext = createContext({
  isLoggedIn: false,
  user: null,
} as AuthContextType);

type AuthProviderProps = {
  children: JSX.Element;
};
export const AuthProvider = ({ children }: AuthProviderProps) => {
  const snackbar = useSnackbar();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState<AuthUser | null>(null);

  const [authenticationType, setAuthenticationType] = useState<string | null>(
    null,
  );
  const [mfaVerificationStatus, setMfaVerificationStatus] = useState<
    string | null
  >(null);

  useEffect(() => {
    SessionService.observe(() => {
      setUserSessionToken(null);
      setIsLoggedIn(false);
      setUser(null);
      setAuthenticationType(null);
      setMfaVerificationStatus(null);
      StorageService.loginStatus.clear();
      StorageService.userDetails.clear();
    });
    return () => {
      SessionService.clear();
    };
  }, []);

  useEffect(() => {
    const values: boolean = StorageService.loginStatus.getValue() === 'true';
    const userData = StorageService.userDetails.getValue() ?? '{}';
    const userDetails = JSON.parse(userData);
    setUserSessionToken(userDetails?.authToken);
    setIsLoggedIn(values);
    setUser(userDetails);
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      await AuthRepository.logout();
    } catch (error) {
      if (error instanceof Error && error?.message) {
        snackbar.show(error.message);
      }
    }
  }, [snackbar]);

  const handleSetLoggedIn = useCallback(
    (value: boolean) => {
      setIsLoggedIn(value);
      if (value) {
        StorageService.loginStatus.setValue('true');
      } else {
        handleLogout();
        StorageService.loginStatus.clear();
        StorageService.authenticationType.clear();
      }
    },
    [handleLogout],
  );

  const handleSeUser = useCallback((value: AuthUser) => {
    if (value.emailVerified && value.phoneVerified && value?.authToken) {
      StorageService.userDetails.setValue(JSON.stringify(value));
      setUserSessionToken(value?.authToken);
      setUser(value);
    }
  }, []);

  const updateAuthenticationType = useCallback((value: string) => {
    StorageService.authenticationType.setValue(value);
    setAuthenticationType(value);
  }, []);

  const values = useMemo(
    () =>
      ({
        isLoggedIn,
        user,
        authenticationType,
        mfaVerificationStatus,
        setUser: handleSeUser,
        setIsLoggedIn: handleSetLoggedIn,
        updateAuthenticationType,
        setMfaVerificationStatus,
      }) as AuthContextType,
    [
      isLoggedIn,
      user,
      authenticationType,
      mfaVerificationStatus,
      handleSeUser,
      handleSetLoggedIn,
      updateAuthenticationType,
    ],
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
export const useAuth = () => {
  return useContext(AuthContext);
};
