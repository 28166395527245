import React from 'react';
import { Divider, Typography, Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { faqContent } from '../../constants';
import FaqAccordion from './FaqAccordion';

const FaqLayout = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: theme?.palette?.background?.paper,
        maxHeight: '525px',
        overflowY: 'scroll',
      }}
    >
      <Typography variant="h4" sx={{ fontFamily: theme?.font?.primaryBold }}>
        {t('faqPage.faqTitle')}
      </Typography>
      <Typography
        sx={{
          margin: '16px 0',
          height: '40px',
          fontSize: '14px',
          color: theme?.palette?.text?.primary,
        }}
      >
        {t('faqPage.faqMessage')}
      </Typography>
      <Divider />
      <Box>
        {faqContent.map((content) => (
          <FaqAccordion
            key={content.title}
            title={content.title}
            description={content.description}
          />
        ))}
      </Box>
    </Box>
  );
};

export default FaqLayout;
