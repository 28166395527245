import { LoginEmailResponse, Login2FAResponse, AuthUser } from '../types';
import { getHttpClient } from './AxiosClient';

export const loginWithEmail = (email: string): Promise<LoginEmailResponse> => {
  return getHttpClient('dashboard/login/getCyph', 'POST', { email });
};

export const loginWith2FA = (
  email: string,
  passcode: string,
  sessionId: string,
): Promise<Login2FAResponse> => {
  return getHttpClient('dashboard/login/verifyPasscode', 'POST', {
    email,
    passcode,
    sessionId,
  });
};

export const loginWithPassword = (
  email: string,
  password: string,
  sessionId: string,
): Promise<AuthUser> => {
  return getHttpClient('dashboard/login/verifyPassword', 'POST', {
    email,
    password,
    sessionId,
  });
};

export const logout = () => {
  return getHttpClient('dashboard/logout', 'POST');
};
