import React, { useCallback } from 'react';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps,
} from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTheme } from '@mui/material';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import DatePickerAction from './DatePickerAction';
import { format } from 'date-fns';

const DatePicker: React.FC<DatePickerProps<Date>> = ({
  label,
  value,
  onChange,
  onAccept,
  onClose,
  sx,
  ...props
}) => {
  const theme = useTheme();

  const dayOfWeek = useCallback((date: Date) => {
    return format(date, 'EEE');
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        onAccept={onAccept}
        onClose={onClose}
        label={label}
        value={value}
        onChange={onChange}
        slots={{
          openPickerIcon: EventOutlinedIcon,
          actionBar: DatePickerAction,
        }}
        closeOnSelect={false}
        dayOfWeekFormatter={dayOfWeek}
        slotProps={{
          layout: {
            sx: {
              '&.MuiPickersLayout-root': {
                color: theme?.palette?.background.paper,
                display: 'flex',
                flexDirection: 'column',
              },
              '& .MuiPickersCalendarHeader-root': {
                backgroundColor: theme?.palette?.background?.default,
                boxShadow: `0px 4px 8px 0px ${theme.palette.white?.[700]}`,
                color: theme?.palette?.background.paper,
              },
              '& .MuiPickersDay-root': {
                '&.Mui-selected': {
                  backgroundColor: theme?.palette?.background?.default,
                },
              },
            },
          },
          calendarHeader: {
            sx: {
              position: 'relative',
              '& .MuiPickersArrowSwitcher-root': {
                width: 0,
              },
              '& .MuiPickersCalendarHeader-labelContainer': {
                margin: 'auto',
              },
              '& .MuiIconButton-edgeEnd': {
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
              },
              '& .MuiIconButton-edgeStart': {
                position: 'absolute',
                right: 0,
                top: 0,
                bottom: 0,
              },
              svg: {
                color: theme?.palette?.background.paper,
                fontSize: '1rem',
              },
              '& .MuiPickersCalendarHeader-label': {
                fontSize: '0.8rem',
              },
            },
          },
          leftArrowIcon: {
            sx: { color: theme?.palette?.background.paper, fontSize: '1rem' },
          },
          rightArrowIcon: {
            sx: { color: theme?.palette?.background.paper, fontSize: '1rem' },
          },
        }}
        sx={{
          '& .MuiInputLabel-root': {
            textAlign: 'center',
            fontSize: '10px',
            color: theme?.palette?.grey?.[600],
          },
          '& .MuiInputBase-input.MuiOutlinedInput-input': {
            width: '60px',
            height: '30px',
            padding: '4px',
            fontSize: '10px',
          },
          ...sx,
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
