import { fromZonedTime } from 'date-fns-tz';
export class TimezoneUtil {
  static getTimezoneList() {
    if (!('supportedValuesOf' in Intl)) {
      return [{ label: 'UTC', value: 'UTC' }];
    } else {
      const continents = [
        'Africa',
        'America',
        'Antarctica',
        'Asia',
        'Atlantic',
        'Australia',
        'Europe',
        'Indian',
        'Pacific',
      ];
      const locale = navigator.language;
      const timeZoneList = [{ label: 'UTC', value: 'UTC' }];
      for (const timezone of Intl.supportedValuesOf('timeZone')) {
        const continent = timezone.split('/');
        if (continent.length <= 1) {
          continue;
        }
        if (continents.indexOf(continent[0]) === -1) {
          continue;
        }
        const offset = TimezoneUtil.getUTCOffset(locale, timezone);
        timeZoneList.push({
          label: timezone + ' ' + offset,
          value: timezone,
        });
      }
      return timeZoneList;
    }
  }

  static readonly convertDateToUtcEpoch = (date: Date, timeZone: string) => {
    date.setHours(23, 59, 59);
    return fromZonedTime(date, timeZone).getTime();
  };

  static readonly getUTCOffset = (locale: string, timeZone: string) => {
    return TimezoneUtil.getFormattedElement(
      locale,
      timeZone,
      'timeZoneName',
      'longOffset',
    );
  };

  static getFormattedElement = (
    locale: string,
    timeZone: string,
    name: string,
    value: string,
  ) => {
    return (
      new Intl.DateTimeFormat(locale, {
        [name]: value,
        timeZone,
      })
        .formatToParts()
        .find((el) => el.type === name) || {}
    ).value;
  };
}

export const timezoneOptions = TimezoneUtil.getTimezoneList();
