import { useTheme } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

interface SwitchStyle {
  width: number;
  height: number;
  radius: number;
  offset: number;
  handleWidth?: number;
  handleHeight?: number;
  handleRadius?: number;
}

const defaultSwitchStyle: SwitchStyle = {
  width: 32,
  height: 16,
  radius: 30,
  offset: 1.6,
  handleWidth: 12.8,
  handleHeight: 12.8,
  handleRadius: 30,
};

export const AccountPageSwitchStyle: SwitchStyle = {
  width: 40,
  height: 20,
  radius: 30,
  offset: 2,
  handleWidth: 16,
  handleHeight: 16,
  handleRadius: 30,
};

const StyledLabel = styled.label<{
  checked: boolean;
  trackActiveColor: string;
  trackInactiveColor: string;
  handleActiveColor: string;
  handleInactiveColor: string;
  switchStyle: SwitchStyle;
}>`
  cursor: pointer;
  text-indent: -9999px;
  width: ${({ switchStyle }) => switchStyle.width + 'px'};
  height: ${({ switchStyle }) => switchStyle.height + 'px'};
  background: ${({ checked, trackActiveColor, trackInactiveColor }) =>
    checked ? trackActiveColor : trackInactiveColor};
  display: block;
  border-radius: ${({ switchStyle }) => switchStyle.radius + 'px'};
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: ${({ checked, switchStyle }) =>
      checked
        ? 'calc(55% - ' + switchStyle.offset + 'px)'
        : switchStyle.offset + 'px'};
    top: ${({ switchStyle }) => switchStyle.offset + 'px'};
    width: ${({ switchStyle }) => switchStyle.handleWidth + 'px'};
    height: ${({ switchStyle }) => switchStyle.handleHeight + 'px'};
    background: ${({ checked, handleActiveColor, handleInactiveColor }) =>
      checked ? handleActiveColor : handleInactiveColor};
    border-radius: ${({ switchStyle }) => switchStyle.handleRadius + 'px'};
    transition: 0.1s;
  }
`;

type SwitchProps = {
  id?: string;
  isEnabled: boolean;
  onToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  trackActiveColor?: string;
  trackInactiveColor?: string;
  handleActiveColor?: string;
  handleInactiveColor?: string;
  switchStyle?: SwitchStyle;
};

const Switch: React.FC<SwitchProps> = ({
  id,
  isEnabled,
  onToggle,
  trackActiveColor,
  trackInactiveColor,
  handleActiveColor,
  handleInactiveColor,
  switchStyle = defaultSwitchStyle,
}) => {
  const theme = useTheme();
  return (
    <StyledLabel
      htmlFor={id}
      checked={isEnabled}
      data-testid={id}
      trackActiveColor={trackActiveColor ?? theme.palette.background.default}
      trackInactiveColor={trackInactiveColor ?? theme.palette.primary.light}
      handleActiveColor={handleActiveColor ?? theme.palette.background.paper}
      handleInactiveColor={
        handleInactiveColor ?? theme.palette.background.paper
      }
      switchStyle={switchStyle}
    >
      <input id={id} type="checkbox" checked={isEnabled} onChange={onToggle} />
    </StyledLabel>
  );
};

export default Switch;
