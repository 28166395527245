import React from 'react';
import { Box, Typography, Popover, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../theme';
import { SharedImages } from '../../../assets/images';
import { Button } from '../../button';
import { ActivityFilterData } from '@/pages/ActivityLogPage';
import { DatePicker } from '../../datePicker';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
type FilterProps = {
  isOpenFilter: boolean;
  anchorEl: HTMLButtonElement | null;
  filterData: ActivityFilterData;
  onChangeStartDate: (date: Date | null) => void;
  onChangeEndDate: (date: Date | null) => void;
  onFilterApply: () => void;
  onFilterReset: () => void;
  onFilterClose: () => void;
  onClickFilter: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Filter: React.FC<FilterProps> = ({
  isOpenFilter,
  anchorEl,
  filterData,
  onChangeStartDate,
  onChangeEndDate,
  onFilterApply,
  onFilterReset,
  onFilterClose,
  onClickFilter,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${theme.palette.echoBlue?.[100]}`,
        borderRadius: '5px',
        width: '166px',
        height: '30px',
        fontSize: '12px',
        color: theme?.palette?.grey?.[200],
      }}
    >
      <Box
        sx={{
          backgroundColor: theme?.palette?.grey?.[300],
          marginRight: '4px',
          borderRadius: '4px',
        }}
        component="img"
        padding="5px"
        src={SharedImages?.filter.default}
        alt="filter"
      />
      {t('filter.filter')}
      <IconButton
        aria-label="arrow-down"
        sx={{ marginLeft: '65px' }}
        onClick={onClickFilter}
      >
        {isOpenFilter ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
      <Popover
        open={isOpenFilter}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={onFilterClose}
      >
        <Box
          sx={{
            padding: '15px',
            width: '280px',
            backgroundColor: theme?.palette?.background?.paper,
            border: `1px solid ${theme?.palette?.echoBlue?.[500]}`,
            borderRadius: '4px',
            alignItems: 'center',
          }}
        >
          <Typography
            fontFamily={theme?.font?.primaryBold}
            color={theme?.palette.text.secondary}
          >
            {t('filter.date')}
          </Typography>
          <Box display="flex" gap="8px" marginTop="15px">
            <DatePicker
              disableFuture
              label={t('filter.fromLabel')}
              value={filterData?.fromDate}
              onChange={onChangeStartDate}
            />
            <DatePicker
              disableFuture
              minDate={filterData?.fromDate || undefined}
              label={t('filter.toLabel')}
              value={filterData?.toDate}
              onChange={onChangeEndDate}
            />
          </Box>
          <Box
            marginTop="15px"
            display="flex"
            justifyContent="center"
            gap="4px"
          >
            <Button
              variant="outlined"
              title={t('filter.resetButtonLabel')}
              onClick={onFilterReset}
              sx={{ width: '65px', height: '24px' }}
            />
            <Button
              variant="contained"
              title={t('filter.applyButtonLabel')}
              onClick={onFilterApply}
              disabled={!filterData?.fromDate}
              sx={{ width: '65px', height: '24px' }}
            />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default Filter;
