import { AxiosProgressEvent } from 'axios';
import { getHttpClient, postMultiPart } from './AxiosClient';
import {
  CreateImageResponse,
  ShareCyphlensImageResponse,
  UploadFileResponse,
} from '../types/CreateImage';

export const createImage = (
  title: string,
  message: string,
  accessType: string,
  decryptionType: string,
  canShowTitle: boolean,
  isOverlay: boolean,
  emailList: object[] | null,
  expirationDate: number | null,
  singleUseOnly: boolean,
): Promise<CreateImageResponse> => {
  return getHttpClient('document/create', 'POST', {
    title,
    text: message,
    accessType,
    decryptionType,
    cloudStorageEnabled: false,
    imageType: 'PNG',
    showTitle: canShowTitle,
    overlay: isOverlay,
    expirationDate: expirationDate,
    shareWith: emailList,
    singleUseOnly,
  });
};

export const uploadFile = (
  file: File,
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
): Promise<UploadFileResponse> => {
  return postMultiPart(
    'document/uploadFile',
    'POST',
    {
      file,
    },
    {},
    onUploadProgress,
  );
};

export const shareCyphlensImage = (
  shareType: string,
  file: File,
  email?: string,
  phoneNumber?: string,
): Promise<ShareCyphlensImageResponse> => {
  return postMultiPart('document/share/' + shareType, 'POST', {
    file,
    phoneNumber,
    email,
  });
};
