import * as React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../src/theme';
import { AuthProvider } from './state';
import { Router } from './route';
import './services/InternationalizationService';
import { SnackbarProvider } from './components/snackbar/SnackbarProvider';
import { GlobalStyles } from './theme';
import LoadingProvider from './components/loading/LoadingProvider';
import { register } from './repositories/AxiosClient';

register();
const App = (): React.JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <LoadingProvider>
        <SnackbarProvider>
          <AuthProvider>
            <BrowserRouter>
              <GlobalStyles />
              <Router />
            </BrowserRouter>
          </AuthProvider>
        </SnackbarProvider>
      </LoadingProvider>
    </ThemeProvider>
  );
};

export default App;
