import React, { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { useTheme } from '@mui/material/styles';
import { TextField } from '../textField';
import { t } from 'i18next';
import EmailPickerItem, { EmailPickerItemMode } from './EmailPickerItem';
import * as Yup from 'yup';
import { Chip } from '@mui/material';

interface EmailPickerProps {
  menuList: string[];
  setMenuList: (menuList: string[]) => void;
  isDisabled?: boolean;
  sx?: React.CSSProperties;
}

const EmailPicker: React.FC<EmailPickerProps> = ({
  menuList,
  setMenuList,
  isDisabled,
  sx,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [value, setValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (isDisabled) return;
      setAnchorEl(event.currentTarget);
    },
    [isDisabled],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setErrorMessage(null);
      setValue(event.target.value);
    },
    [],
  );

  const validateEmail = useCallback((email: string) => {
    const schema = Yup.string().email();
    return schema.isValidSync(email);
  }, []);

  const isAlreadyExists = useCallback(
    (email: string) => {
      return menuList.includes(email);
    },
    [menuList],
  );

  const onEmailAdd = useCallback(() => {
    setErrorMessage(null);
    if (value != '' && validateEmail(value) && !isAlreadyExists(value)) {
      setMenuList([...menuList, value]);
      setValue('');
    } else if (isAlreadyExists(value)) {
      setErrorMessage(t('validation.emailAlreadyExists'));
    } else if (value != '') {
      setErrorMessage(t('validation.invalidEmail'));
    } else {
      setValue('');
    }
  }, [menuList, value, setMenuList, validateEmail, isAlreadyExists]);

  const handleBlurEvent = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      event.preventDefault();
      onEmailAdd();
      setAnchorEl(null);
    },
    [onEmailAdd],
  );

  const handleEnterKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onEmailAdd();
      }
    },
    [onEmailAdd],
  );

  const onClickRemove = useCallback(
    (index: number) => {
      const updatedMenuList = [
        ...menuList.slice(0, index),
        ...menuList.slice(index + 1),
      ];
      setMenuList(updatedMenuList);
    },
    [menuList, setMenuList],
  );

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingLeft="11px"
        paddingRight="11px"
        gap="5px"
        maxHeight="40px"
        height="40px"
        data-testid="email-picker"
        sx={{
          overflowY: 'hidden',
          overflowX: 'hidden',
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
          border: `1px solid ${theme.palette.echoBlue?.[100]}`,
          minWidth: 0,
          ...sx,
        }}
        onClick={handleClick}
      >
        {menuList && menuList.length > 0 ? (
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            gap="5px"
            minWidth="0"
            sx={{
              flex: 1,
              overflowX: 'hidden',
              flexWrap: 'nowrap',
            }}
          >
            {menuList.length > 0 && (
              <EmailPickerItem
                key={0}
                index={0}
                email={menuList[0]}
                onRemoveItem={onClickRemove}
                mode={EmailPickerItemMode.VIEW}
              />
            )}
            {menuList.length > 1 && (
              <Chip
                label={'+' + (menuList?.length - 1)}
                sx={{ fontSize: 12, height: 20 }}
                color="primary"
              />
            )}
          </Box>
        ) : (
          <Typography variant="h2">
            {t('createCyphlensImage.enterEmailPlaceHolder')}
          </Typography>
        )}
        {!isDisabled && (
          <AddCircleOutlineRoundedIcon
            sx={{ color: theme.palette.echoBlue?.[100], fontSize: '16px' }}
          />
        )}
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-testid="email-picker-popover"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              width: anchorEl?.offsetWidth,
              border: `1px solid ${theme.palette?.echoBlue?.[100]}`,
              borderRadius: '5px',
              padding: '10px',
              boxShadow: 'none',
              marginTop: '12px',
            },
          },
        }}
      >
        <TextField
          sx={{
            margin: 0,
            borderColor: `1px solid ${theme.palette?.echoBlue?.[100]}`,
          }}
          value={value}
          onChange={handleInputChange}
          placeholder="Enter"
          data-testid="email-picker-input"
          onBlur={handleBlurEvent}
          onKeyDown={handleEnterKeyPress}
          isTouched={errorMessage != null}
          errorMessage={errorMessage}
        />
        <Box
          maxHeight="150px"
          sx={{ overflowY: 'auto', overflowX: 'hidden', mt: '5px' }}
        >
          {menuList &&
            menuList.map((text, index) => (
              <EmailPickerItem
                key={index}
                index={index}
                email={text}
                onRemoveItem={onClickRemove}
                sx={{ mt: '5px' }}
              />
            ))}
        </Box>
      </Popover>
    </Box>
  );
};

export default EmailPicker;
