import React, { useState, useCallback } from 'react';
import {
  Box,
  Typography,
  Popover,
  IconButton,
  Divider,
  useTheme,
  FormControlLabel,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';
import { SharedImages } from '../../assets/images';
import { Button, Checkbox, DatePicker } from '../../components';
import { FileManagerFilterData } from '../../pages/FileManagerPage';
import { AccessTypeOptions } from '../../constants';

type FileManagerFilterProps = {
  isOpenFilter: boolean;
  anchorEl: HTMLButtonElement | null;
  filterData: FileManagerFilterData;
  onChangeCreatedStartDate: (date: Date | null) => void;
  onChangeCreatedEndDate: (date: Date | null) => void;
  onChangeExpireStartDate: (date: Date | null) => void;
  onChangeExpireEndDate: (date: Date | null) => void;
  onChangeAccessType: (accessType: string[]) => void;
  onFilterApply: () => void;
  onFilterReset: () => void;
  onFilterClose: () => void;
  onClickFilter: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const FileManagerFilter: React.FC<FileManagerFilterProps> = ({
  isOpenFilter,
  anchorEl,
  filterData,
  onChangeCreatedStartDate,
  onChangeCreatedEndDate,
  onChangeExpireStartDate,
  onChangeExpireEndDate,
  onChangeAccessType,
  onFilterApply,
  onFilterReset,
  onFilterClose,
  onClickFilter,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isCreatedDateExpanded, setIsCreatedDateExpanded] =
    useState<boolean>(false);
  const [isExpireDateExpanded, setIsExpireDateExpanded] =
    useState<boolean>(false);
  const [isAccessTypeExpanded, setIsAccessTypeExpanded] =
    useState<boolean>(false);

  const handleCreatedDate = useCallback(() => {
    setIsCreatedDateExpanded((prevState) => !prevState);
  }, []);

  const handleExpireDate = useCallback(() => {
    setIsExpireDateExpanded((prevState) => !prevState);
  }, []);

  const handleAccessType = useCallback(() => {
    setIsAccessTypeExpanded((prevState) => !prevState);
  }, []);

  const handleAccessTypeChange = useCallback(
    (accessType: string) => {
      return () => {
        const updatedAccessType = filterData.accessType.includes(accessType)
          ? filterData.accessType.filter((type) => type !== accessType)
          : [...filterData.accessType, accessType];
        onChangeAccessType(updatedAccessType);
      };
    },
    [filterData.accessType, onChangeAccessType],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${theme.palette.echoBlue?.[100]}`,
        borderRadius: '5px',
        width: '166px',
        height: '30px',
        fontSize: '12px',
        color: theme?.palette?.grey?.[200],
      }}
    >
      <Box
        sx={{
          backgroundColor: theme?.palette?.grey?.[300],
          borderRadius: '4px',
          marginRight: '4px',
        }}
        component="img"
        padding="5px"
        src={SharedImages?.filter.default}
        alt="filter"
      />
      {t('fileManagerFilter.filter')}
      <IconButton
        aria-label="arrow-down"
        sx={{ marginLeft: '65px' }}
        onClick={onClickFilter}
      >
        {isOpenFilter ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
      <Popover
        open={isOpenFilter}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={onFilterClose}
      >
        <Box
          sx={{
            padding: '15px',
            width: '280px',
            backgroundColor: theme?.palette?.background?.paper,
            border: `1px solid ${theme?.palette?.echoBlue?.[500]}`,
            borderRadius: '4px',
            alignItems: 'center',
          }}
        >
          <Typography
            fontFamily={theme?.font?.primaryBold}
            fontSize="12px"
            color={theme?.palette.text.secondary}
          >
            {t('fileManagerFilter.createdDate')}
            <IconButton
              aria-label="arrow-down-created-date"
              sx={{ position: 'absolute', right: '10px', padding: 0 }}
              onClick={handleCreatedDate}
            >
              {isCreatedDateExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Typography>

          {isCreatedDateExpanded && (
            <Box display="flex" gap="8px" marginTop="15px">
              <DatePicker
                disableFuture
                label={t('fileManagerFilter.fromLabel')}
                value={filterData?.createdFromDate}
                onChange={onChangeCreatedStartDate}
              />
              <DatePicker
                disableFuture
                minDate={filterData?.createdFromDate || undefined}
                label={t('fileManagerFilter.toLabel')}
                value={filterData?.createdToDate}
                onChange={onChangeCreatedEndDate}
              />
            </Box>
          )}
          <Divider sx={{ marginTop: '10px' }} />
          <Typography
            fontFamily={theme?.font?.primaryBold}
            color={theme?.palette.text.secondary}
            marginTop="8px"
            fontSize="12px"
          >
            {t('fileManagerFilter.expireDate')}
            <IconButton
              aria-label="arrow-down-expire-date"
              sx={{ position: 'absolute', right: '10px', padding: 0 }}
              onClick={handleExpireDate}
            >
              {isExpireDateExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Typography>

          {isExpireDateExpanded && (
            <Box display="flex" gap="8px" marginTop="15px">
              <DatePicker
                label={t('fileManagerFilter.fromLabel')}
                value={filterData?.expireFromDate}
                onChange={onChangeExpireStartDate}
              />
              <DatePicker
                minDate={filterData?.expireFromDate || undefined}
                label={t('fileManagerFilter.toLabel')}
                value={filterData?.expireToDate}
                onChange={onChangeExpireEndDate}
              />
            </Box>
          )}
          <Divider sx={{ marginTop: '10px' }} />
          <Typography
            fontFamily={theme?.font?.primaryBold}
            color={theme?.palette.text.secondary}
            marginTop="8px"
            fontSize="12px"
          >
            {t('fileManagerFilter.accessType')}
            <IconButton
              aria-label="arrow-down-access-type"
              sx={{ position: 'absolute', right: '10px', padding: 0 }}
              onClick={handleAccessType}
            >
              {isAccessTypeExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Typography>

          {isAccessTypeExpanded && (
            <Box display="flex" gap="8px" marginTop="10px">
              {AccessTypeOptions.map(({ label, value }) => (
                <FormControlLabel
                  key={value}
                  control={
                    <Checkbox
                      value={value}
                      isChecked={filterData.accessType.includes(value)}
                      onChange={handleAccessTypeChange(value)}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: theme?.palette?.background?.default,
                      }}
                    >
                      {t(`${label}`)}
                    </Typography>
                  }
                />
              ))}
            </Box>
          )}
          <Divider sx={{ marginTop: '10px' }} />
          <Box
            marginTop="15px"
            display="flex"
            justifyContent="center"
            gap="4px"
          >
            <Button
              variant="outlined"
              data-testid="reset-button"
              title={t('fileManagerFilter.resetButtonLabel')}
              onClick={onFilterReset}
              sx={{ width: '65px', height: '24px' }}
            />
            <Button
              variant="contained"
              data-testid="apply-button"
              title={t('fileManagerFilter.applyButtonLabel')}
              onClick={onFilterApply}
              sx={{ width: '65px', height: '24px' }}
            />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default FileManagerFilter;
