import { Box, Typography } from '@mui/material';
import React from 'react';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { SxProps, Theme, useTheme } from '@mui/material/styles';

export enum EmailPickerItemMode {
  VIEW,
  EDIT,
}

interface EmailPickerItemsProps {
  index: number;
  email: string;
  mode?: EmailPickerItemMode | EmailPickerItemMode.EDIT;
  removeIconStyle?: SxProps;
  onRemoveItem: (index: number) => void;
  sx?: SxProps<Theme>;
}

const EmailPickerItem: React.FC<EmailPickerItemsProps> = ({
  index,
  email,
  mode,
  removeIconStyle,
  onRemoveItem,
  sx,
}) => {
  const theme = useTheme();
  const handleDeleteEvent = React.useCallback(() => {
    onRemoveItem(index);
  }, [index, onRemoveItem]);

  return (
    <Box
      key={email}
      sx={{
        border: `0.6px solid ${theme.palette.echoBlue?.[100]}`,
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxHeight: '30px',
        height: '30px',
        backgroundColor: theme.palette.white?.[500],
        wordWrap: 'break-word',
        paddingLeft: '10px',
        paddingRight: '10px',
        minWidth: 0,
        ...sx,
      }}
    >
      <Typography
        sx={{
          overflow: 'hidden',
          textWrap: 'nowrap',
          wordWrap: 'initial',
          textOverflow: 'ellipsis',
          fontSize: 12,
        }}
      >
        {email}
      </Typography>
      {mode === EmailPickerItemMode.VIEW ? null : (
        <HighlightOffRoundedIcon
          onClick={handleDeleteEvent}
          data-testid="email-picker-remove"
          sx={
            removeIconStyle || {
              color: theme.palette.echoBlue?.[100],
              width: '16px',
              height: '16px',
              cursor: 'pointer',
              marginLeft: '15px',
            }
          }
        />
      )}
    </Box>
  );
};

export default EmailPickerItem;
