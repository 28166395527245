import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import { Button } from '../../components';
import { useTranslation } from 'react-i18next';
import { EmergencyContactListResponse } from '@/types';
import { EmergencyRecoverContactListLayout } from './';
import { SharedImages } from '../../assets/images';

type SendEmergencyRecoverTokenLayoutProps = {
  selectedContact?: string | null;
  recoverContactListData?: EmergencyContactListResponse;
  onChangeRecoverContactList: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  onClickSendToken: () => void;
};

const SendEmergencyRecoverTokenLayout: React.FC<
  SendEmergencyRecoverTokenLayoutProps
> = ({
  selectedContact,
  recoverContactListData,
  onChangeRecoverContactList,
  onClickSendToken,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box>
      <Typography
        sx={{
          marginTop: '20px',
          fontSize: '14px',
          color: theme?.palette?.text?.primary,
        }}
      >
        {t('emergencyRecoverPage.subTitle')}
      </Typography>
      <Box marginTop="40px">
        {recoverContactListData?.map((contact) => (
          <EmergencyRecoverContactListLayout
            key={contact?.id}
            selectedContact={selectedContact}
            contactListData={contact}
            onChangeRecoverContactList={onChangeRecoverContactList}
          />
        ))}
      </Box>
      <Button
        disabled={!selectedContact}
        onClick={onClickSendToken}
        title={t('emergencyRecoverPage.sendButtonLabel')}
        sx={{
          width: '150px',
          height: '40px',
        }}
        variant="contained"
        startIcon={
          <Box component="img" src={SharedImages.send.default} alt="send" />
        }
      />
    </Box>
  );
};

export default SendEmergencyRecoverTokenLayout;
