import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Typography, Paper, Box } from '@mui/material';
import { theme } from '../../theme';
import EmailForm from './EmailForm';
import MFAForm from './MFAForm';
import PasswordForm from './PasswordForm';

export const ScreenType = {
  email: 'email',
  password: 'password',
  cyphlensImageVerification: 'cyphlensImageVerification',
};

export type LoginFormType = {
  email: string;
  password: string;
  mfaCode: string;
};

export type LoginFormProps = {
  isLoading: boolean;
  mfaInterval?: number;
  imageUrl?: string;
  screenType: string;
  onClickRecover: () => void;
  onClickRefresh: () => void;
  onClickNext: (values: LoginFormType) => void;
  onClickVerify: (values: LoginFormType) => void;
  onClickLogin: (values: LoginFormType) => void;
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email('validation.invalidEmailError')
    .required('validation.emailError'),
  password: Yup.string(),
  mfaCode: Yup.string(),
});

const LoginForm: React.FC<LoginFormProps> = ({
  isLoading,
  mfaInterval,
  imageUrl,
  screenType,
  onClickRecover,
  onClickRefresh,
  onClickNext,
  onClickVerify,
  onClickLogin,
}) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    (value: LoginFormType) => {
      switch (screenType) {
        case ScreenType.password:
          return onClickLogin(value);
        case ScreenType.cyphlensImageVerification:
          return onClickVerify(value);
        default:
          return onClickNext(value);
      }
    },
    [screenType, onClickLogin, onClickVerify, onClickNext],
  );
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      mfaCode: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Paper
      data-testid="login-form"
      sx={{
        background: theme?.palette?.background?.paper,
        padding: '40px',
        borderRadius: '5px',
        boxShadow: `0 0 10px ${theme?.palette?.black?.[400]}`,
        width: '440px',
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '36px',
          gap: '10px',
          marginBottom: '20px',
          fontWeight: 600,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize:
              screenType === ScreenType.cyphlensImageVerification
                ? '16px'
                : '20px',
            fontFamily: theme?.font?.primaryBold,
            color: theme?.palette?.text?.secondary,
          }}
        >
          {screenType === ScreenType.cyphlensImageVerification
            ? t('loginForm.verifyImageTitle')
            : t('loginForm.title')}
        </Typography>
      </Box>
      <form data-testid="submit-form" onSubmit={formik.handleSubmit}>
        {screenType === ScreenType.email && (
          <EmailForm
            isLoading={isLoading}
            onClickRecover={onClickRecover}
            values={formik.values}
            errors={formik.errors}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            touched={formik.touched}
            isDisabled={!formik.isValid}
          />
        )}
        {screenType === ScreenType.cyphlensImageVerification && (
          <MFAForm
            imageUrl={imageUrl}
            mfaInterval={mfaInterval}
            isLoading={isLoading}
            onClickRefresh={onClickRefresh}
            value={formik.values.mfaCode}
            errorMessage={formik.errors.mfaCode}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            isDisabled={!formik.isValid || mfaInterval === 0}
            isTouched={formik.touched.mfaCode}
          />
        )}
        {screenType === ScreenType.password && (
          <PasswordForm
            isLoading={isLoading}
            value={formik.values.password}
            errorMessage={formik.errors.password}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            isDisabled={!formik.isValid}
            isTouched={formik.touched.password}
          />
        )}
      </form>
    </Paper>
  );
};

export default LoginForm;
