import React from 'react';
import { Typography, Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { theme } from '../../theme';
import { UserAccountResponse } from '../../types';
import { Switch } from '../../components/switch';
import { AccountPageSwitchStyle } from '../../components/switch/Switch';

export type AccountLayoutProps = {
  accountData?: UserAccountResponse;
  isAccountActive: boolean;
  onChangeAccountStatus: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const AccountLayout: React.FC<AccountLayoutProps> = ({
  accountData,
  isAccountActive,
  onChangeAccountStatus,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{ background: theme?.palette?.background?.paper, padding: '16px 0' }}
    >
      <Typography
        variant="h4"
        sx={{
          fontFamily: theme?.font?.primaryBold,
          fontSize: '18px',
          fontWeight: 700,
        }}
      >
        {t('accountPage.title')}
      </Typography>
      <Box gap="10px" paddingTop="38px" display="flex">
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 700,
            color: theme?.palette?.text?.secondary,
          }}
        >
          {t('accountPage.name')} :
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 500,
          }}
        >
          {accountData?.firstName} {accountData?.lastName}
        </Typography>
      </Box>
      <Box gap="10px" paddingTop="20px" display="flex">
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 700,
            color: theme?.palette?.text?.secondary,
          }}
        >
          {t('accountPage.email')} :
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 500,
          }}
        >
          {accountData?.email}
        </Typography>
      </Box>
      <Box gap="10px" paddingTop="20px" display="flex">
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 700,
            color: theme?.palette?.text?.secondary,
          }}
        >
          {t('accountPage.lastLogin')} :
        </Typography>
        <Typography
          component="pre"
          sx={{
            fontSize: '14px',
            fontWeight: 500,
          }}
        >
          {accountData?.lastLogin}
        </Typography>
      </Box>
      <Divider sx={{ marginTop: '30px', color: theme?.palette?.divider }} />
      <Typography
        variant="h4"
        sx={{
          marginTop: '30px',
          fontFamily: theme?.font?.primaryBold,
          fontSize: '18px',
        }}
      >
        {t('accountPage.manageAccount')}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
        <Switch
          id="account-switch"
          isEnabled={isAccountActive}
          onToggle={onChangeAccountStatus}
          trackActiveColor={theme?.palette?.success?.main}
          switchStyle={AccountPageSwitchStyle}
        />
        <Typography sx={{ marginLeft: '15px', fontSize: '16px' }}>
          {t('accountPage.account')}
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            marginLeft: '4px',
            color: theme?.palette?.success?.main,
            fontFamily: theme?.font?.primaryBold,
          }}
        >
          {accountData?.deviceStatus}
        </Typography>
      </Box>
    </Box>
  );
};

export default AccountLayout;
