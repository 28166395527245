import React, { useCallback, useEffect, useState } from 'react';
import { EmergencyContactRepository } from '../repositories';
import { useLoading } from '../components/loading/LoadingProvider';
import { EmergencyContactLayout } from '../layout/emergencyContact';
import { useSearchParams } from 'react-router-dom';

const EmergencyContactPage = () => {
  const { setLoading } = useLoading();
  const [searchParams] = useSearchParams();
  const hash = searchParams.get('hash');
  const [recoverStatus, setRecoverStatus] = useState<boolean>(false);

  const getEmergencyContact = useCallback(async () => {
    try {
      setLoading(true);
      const response = await EmergencyContactRepository.getRecoverStatus(hash);
      setRecoverStatus(response?.approved);
    } catch {
      setRecoverStatus(false);
    } finally {
      setLoading(false);
    }
  }, [hash, setLoading]);

  useEffect(() => {
    getEmergencyContact();
  }, [getEmergencyContact]);

  return <EmergencyContactLayout isSuccess={recoverStatus} />;
};

export default EmergencyContactPage;
