import * as React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { Button, DatePicker } from '../../components';
import { ActivityFilterData } from '../../pages/ActivityLogPage';
import { useTranslation } from 'react-i18next';

type ActivityLogDownloadProps = {
  isOpenDownload: boolean;
  downloadDate: ActivityFilterData;
  onChangeDownloadStartDate: (date: Date | null) => void;
  onChangeDownloadEndDate: (date: Date | null) => void;
  onDownloadClose: () => void;
  onDownloadApply: () => void;
};

const ActivityLogDownload: React.FC<ActivityLogDownloadProps> = ({
  isOpenDownload,
  downloadDate,
  onDownloadApply,
  onDownloadClose,
  onChangeDownloadEndDate,
  onChangeDownloadStartDate,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Dialog
      open={isOpenDownload}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      onClose={onDownloadClose}
    >
      <IconButton
        aria-label="close"
        sx={{ position: 'absolute', top: 0, right: 0 }}
        onClick={onDownloadClose}
      >
        <HighlightOffRoundedIcon
          style={{ color: theme?.palette?.black?.[600] }}
        />
      </IconButton>
      <DialogTitle align="center" marginTop="20px">
        {t('activityLogDownload.title')}
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography>{t('activityLogDownload.description')}</Typography>
        <Box display="flex" gap="8px" marginTop="30px">
          <DatePicker
            disableFuture
            label={t('activityLogDownload.fromLabel')}
            value={downloadDate?.fromDate}
            onChange={onChangeDownloadStartDate}
            sx={{
              '& .MuiInputBase-input.MuiOutlinedInput-input': {
                width: '200px',
                height: '40px',
                padding: '4px',
                fontSize: '14px',
              },
              '& .MuiInputLabel-root': {
                textAlign: 'center',
                fontSize: '14px',
                color: theme?.palette?.grey?.[600],
              },
            }}
          />
          <DatePicker
            disableFuture
            minDate={downloadDate?.fromDate || undefined}
            label={t('activityLogDownload.toLabel')}
            value={downloadDate?.toDate}
            onChange={onChangeDownloadEndDate}
            sx={{
              '& .MuiInputBase-input.MuiOutlinedInput-input': {
                width: '200px',
                height: '40px',
                padding: '4px',
                fontSize: '14px',
              },
              '& .MuiInputLabel-root': {
                textAlign: 'center',
                fontSize: '14px',
                color: theme?.palette?.grey?.[600],
              },
            }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          gap="10px"
          margin="30px 0 20px"
        >
          <Button
            title={t('activityLogDownload.cancelButtonLabel')}
            variant="outlined"
            sx={{ width: '100px' }}
            onClick={onDownloadClose}
          />
          <Button
            title={t('activityLogDownload.applyButtonLabel')}
            variant="contained"
            sx={{ width: '100px' }}
            onClick={onDownloadApply}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ActivityLogDownload;
