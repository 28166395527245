export const SharedImages = {
  loading: require('./loading.svg'),
  download: require('./download.svg'),
  filter: require('./filter.svg'),
  create: require('./create.svg'),
  close: require('./close.svg'),
  noRecord: require('./noRecord.svg'),
  imageDownload: require('./imageDownload.svg'),
  delete: require('./delete.svg'),
  share: require('./share.svg'),
  send: require('./send.svg'),
  upload: require('./upload.svg'),
};
