import { getHttpClient } from './AxiosClient';
import {
  ActivityLogDownloadResponse,
  ActivityLogFilterParam,
  ActivityLogResponse,
} from '../types/ActivityLog';
import qs from 'qs';

export const activityLog = (
  search?: string | null,
  filter?: ActivityLogFilterParam | null,
  page?: number,
  pageSize?: number,
): Promise<ActivityLogResponse> => {
  return getHttpClient('activity/list', 'POST', null, {
    page,
    pageSize,
    search,
    filter: qs.stringify(filter, {
      arrayFormat: 'comma',
      delimiter: ';',
    }),
  });
};

export const activityLogDownload = (
  locale: string | null,
  timeZone: string | null,
  filter: ActivityLogFilterParam | null,
): Promise<ActivityLogDownloadResponse> => {
  return getHttpClient('activity/download', 'POST', null, {
    locale,
    timeZone,
    filter: qs.stringify(filter, {
      arrayFormat: 'comma',
      delimiter: ';',
    }),
  });
};

export const activityLogDownloadByUrl = (url: string): Promise<string> => {
  return getHttpClient(url, 'GET');
};
