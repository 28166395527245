import { Box, IconButton, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Button from '../../components/button/Button';
import { TextField, AutoCompleteCountry } from '../../components';
import Stack from '@mui/material/Stack';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { CountryCode, countryCodes } from '../../utils/CountrycodeUtil';

type ShareTypeMessageProps = {
  isLoading?: boolean;
  onSharePopupClose?: () => void;
  onClickMessageSendButton: (phoneCode: string, phoneNumber: string) => void;
};

const ShareTypeMessage: React.FC<ShareTypeMessageProps> = ({
  isLoading,
  onSharePopupClose,
  onClickMessageSendButton,
}: ShareTypeMessageProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    countryCode: Yup.object().shape({
      code: Yup.string().required('validation.required'),
    }),
    phoneNumber: Yup.string()
      .required('validation.required')
      .matches(phoneRegExp, 'Phone number is not valid'),
  });

  const formik = useFormik({
    initialValues: {
      countryCode: {
        callingCode: '+1',
        flag: '',
        code: 'US',
      },
      phoneNumber: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onClickMessageSendButton(
        values.countryCode.callingCode,
        values.phoneNumber,
      );
    },
  });

  const handleCountryCodeChangeEvent = useCallback(
    (value: CountryCode | null) => {
      formik.setFieldValue('countryCode', value);
    },
    [formik],
  );

  return (
    <Box sx={{ width: '100%' }}>
      <form data-test-id="share-by-sms-form" onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <IconButton
            data-testid="close-share-popup"
            style={{ color: theme.palette.primary.main }}
            onClick={onSharePopupClose}
          >
            <HighlightOffRoundedIcon />
          </IconButton>
        </Box>
        <Typography
          sx={{ marginBottom: '30px', mt: '-10px' }}
          variant="h6"
          color={theme.palette.background.default}
        >
          {t('createCyphlensImage.share.shareByMessage')}
        </Typography>
        <Box sx={{ paddingLeft: '10%', paddingRight: '10%' }}>
          <Stack direction="row">
            <AutoCompleteCountry
              id="share-country-code-dropdown"
              onChange={handleCountryCodeChangeEvent}
              value={formik.values.countryCode}
              options={countryCodes}
              placeholder={t('createCyphlensImage.share.countryCode')}
              sx={{
                width: '100%',
                height: '50px',
                marginTop: '0px',
                '& .MuiOutlinedInput-root': {
                  padding: '0px 8px',
                  marginTop: '0px',
                  paddingRight: '10px !important',
                  borderTopRightRadius: '0px !important',
                  borderBottomRightRadius: '0px !important',
                  paddingTop: '1.5px',
                },
              }}
              inputSx={{
                input: {
                  height: '25px',
                  width: '40px',
                },
              }}
            />
            <Box>
              <TextField
                sx={{
                  width: '240px',
                  borderColor: theme?.palette?.echoBlue?.[100],
                  margin: 0,
                  '& .MuiInputBase-root': {
                    borderTopLeftRadius: '0px',
                    borderBottomLeftRadius: '0px',
                  },
                }}
                placeholder={t(
                  'createCyphlensImage.enterPhoneNumberPlaceHolder',
                )}
                name="phoneNumber"
                id="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                errorMessage={formik.errors?.phoneNumber}
                isTouched={formik.touched?.phoneNumber}
              />
            </Box>
          </Stack>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            marginTop="30px"
            sx={{
              width: '150px',
            }}
          >
            <Button
              sx={{ width: '100px', marginBottom: '20px' }}
              data-testid="share-with-sms-submit-button"
              type="submit"
              variant="contained"
              title={t('createCyphlensImage.send')}
              isLoading={isLoading}
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default ShareTypeMessage;
