import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountLayout } from '../layout/account';
import { AccountRepository } from '../repositories';
import { UserAccountResponse } from '../types';
import { useSnackbar } from '../state';
import { useLoading } from '../components/loading/LoadingProvider';
import { AccountStatus } from '../constants';
import { SeverityType } from '../components/snackbar/Snackbar';

const AccountPage = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { setLoading } = useLoading();
  const [accountData, setAccountData] = useState<UserAccountResponse>();
  const [checked, setChecked] = useState(false);

  const handleAccountDetails = useCallback(async () => {
    try {
      setLoading(true);
      const response = await AccountRepository.getUser();
      const dateFormat = new Date(parseInt(response?.lastLogin));
      const formatLastLogin = dateFormat?.toLocaleTimeString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
      const lastLogin = formatLastLogin?.toUpperCase();
      const deviceStatus =
        response?.deviceStatus === AccountStatus?.activeCaps
          ? AccountStatus?.active
          : AccountStatus?.inActive;
      setAccountData({
        ...response,
        lastLogin,
        deviceStatus,
      });
      setChecked(response.deviceStatus === AccountStatus?.activeCaps);
      setLoading(false);
    } catch (error: unknown) {
      setLoading(false);
      if (error instanceof Error && error?.message) {
        snackbar.show(error.message);
      }
    }
  }, [snackbar, setLoading]);

  const handleChangeAccountStatus = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event?.target?.checked);
      try {
        setLoading(true);
        await AccountRepository.updateStatus(
          event?.target?.checked ? 'ACTIVE' : 'INACTIVE',
        );
        handleAccountDetails();
        snackbar.show(
          t('accountPage.updateStatusSuccessMessage'),
          SeverityType.success,
        );
      } catch (error: unknown) {
        if (error instanceof Error && error?.message) {
          snackbar.show(error.message);
        }
      } finally {
        setLoading(false);
      }
    },
    [snackbar, t, setLoading, handleAccountDetails],
  );

  useEffect(() => {
    handleAccountDetails();
  }, [handleAccountDetails]);

  return (
    <AccountLayout
      isAccountActive={checked}
      accountData={accountData}
      onChangeAccountStatus={handleChangeAccountStatus}
    />
  );
};

export default AccountPage;
