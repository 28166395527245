import React, { useMemo } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { theme } from '../../theme';
import { useTranslation } from 'react-i18next';
import { NoDataContainer } from '../../components/shared';

export type ActivityLog = {
  id: string;
  date: string;
  time: string;
  activity: string;
  details: string;
};

type ActivityLogTableProps = {
  data: ActivityLog[];
};

const ActivityLogTable: React.FC<ActivityLogTableProps> = ({ data }) => {
  const { t } = useTranslation();

  const tableHeadStyle = useMemo(() => {
    return {
      fontFamily: theme?.font?.primaryBold,
      padding: '10px',
      color: theme?.palette?.background?.paper,
      width: '20%',
    };
  }, []);

  const tableBodyStyle = useMemo(() => {
    return {
      padding: '10px',
      borderBottom: `1px solid ${theme?.palette?.echoBlue?.[100]}`,
      maxWidth: '200px',
      wordBreak: 'break-word',
    };
  }, []);

  return (
    <Box sx={{ marginTop: '25px' }}>
      <TableContainer component={Paper}>
        <Table
          sx={{
            borderRadius: '5px 5px 0px 0px',
            border: `1px solid ${theme?.palette?.echoBlue?.[100]}`,
          }}
          aria-label="activity log table"
        >
          <TableHead sx={{ backgroundColor: theme?.palette?.black?.[600] }}>
            <TableRow>
              <TableCell sx={tableHeadStyle}>
                {t('activityLogTable.date')}
              </TableCell>
              <TableCell sx={tableHeadStyle}>
                {t('activityLogTable.time')}
              </TableCell>
              <TableCell sx={tableHeadStyle}>
                {t('activityLogTable.activity')}
              </TableCell>
              <TableCell sx={{ ...tableHeadStyle, width: '40%' }}>
                {t('activityLogTable.details')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length > 0 ? (
              data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell sx={tableBodyStyle}>{row.date}</TableCell>
                  <TableCell sx={tableBodyStyle}>{row.time}</TableCell>
                  <TableCell sx={tableBodyStyle}>{row.activity}</TableCell>
                  <TableCell sx={tableBodyStyle}>{row.details}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <NoDataContainer />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ActivityLogTable;
