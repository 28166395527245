import * as React from 'react';
import { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import IconButton from '@mui/material/IconButton';

export type ProgressBarProps = {
  value: number;
  file: File;
  onFileRemove: () => void;
} & LinearProgressProps;

const units = ['bytes', 'KB', 'MB', 'GB'];

export const formatFileSize = (size: string) => {
  let unitIndex = 0;
  let sizeValue = parseInt(size, 10) || 0;

  while (sizeValue >= 1024 && unitIndex < units.length - 1) {
    sizeValue /= 1024;
    unitIndex++;
  }
  const decimalPlaces = sizeValue < 10 && unitIndex > 0 ? 1 : 0;
  return `${sizeValue.toFixed(decimalPlaces)} ${units[unitIndex]}`;
};

const ProgressBar = ({ file, onFileRemove }: ProgressBarProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderRadius: '8px',
        backgroundColor: theme?.palette?.white?.[400],
        border: `1px dashed ${theme?.palette?.echoBlue?.[100]}`,
        padding: '10px',
      }}
    >
      <Stack
        marginBottom="8px"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row">
          <Typography fontSize="12px" fontWeight={500} display="inline-flex">
            {file.name}
          </Typography>
          <Typography fontSize="12px" fontWeight={500} marginLeft="16px">
            {formatFileSize(file.size.toString())}
          </Typography>
        </Stack>
        <IconButton onClick={onFileRemove} data-testid={'remove-file-button'}>
          <CancelOutlinedIcon
            sx={{ color: theme?.palette?.black?.[600], fontSize: '12px' }}
          />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default ProgressBar;
