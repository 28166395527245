import { createTheme } from '@mui/material';
import { MixThemeOptions, MixTheme } from '.';

export const theme = createTheme({
  typography: {
    fontFamily: 'lato',
  },
  font: {
    primary: 'lato',
    primaryBold: 'latoBold',
    primarySemiBold: 'latoSemiBold',
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              display: 'none',
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#9DBAC6',
          },
          '& .MuiInputBase-input': {
            color: '#212121',
            fontSize: '12px',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#000000',
          '&.Mui-checked': {
            color: '#000000',
          },
          '& .MuiSvgIcon-root': {
            width: '16px',
            height: '16px',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
              @font-face {
                font-family: "lato";
                font-style: normal;
                font-display: swap;
                font-weight: 400;
                text-transform: none;
                font-size: 16px;
              }
            `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          fontFamily: 'lato',
          textTransform: 'none',
          borderRadius: '4px',
          fontWeight: 700,
          fontSize: '14px',
          '&:disabled': {
            background: '#22222280',
            Opacity: 0.5,
            color: 'rgba(255, 255, 255, 0.5)',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: '1px solid #D7D8DB',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.08)',
          borderRadius: '4px',
        },
      },
    },
    MuiDivider: {
      variants: [
        {
          props: { orientation: 'horizontal' },
          style: {
            ':before': {
              borderTop: 'thin solid #D7D8DB',
            },
            ':after': {
              borderTop: 'thin solid #D7D8DB',
            },
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h6' },
          style: {
            fontSize: '18px',
            fontWeight: 700,
            color: '#2D3233',
          },
        },
        {
          props: { variant: 'h4' },
          style: {
            fontSize: '14px',
            fontWeight: 700,
            color: '#212121',
          },
        },
        {
          props: { variant: 'h2' },
          style: {
            fontSize: '12px',
            fontWeight: 500,
            color: '#868686',
          },
        },
      ],
    },
  },
  palette: {
    text: {
      primary: '#616161',
      secondary: '#212121',
    },
    primary: {
      main: '#616161',
    },
    secondary: {
      main: '#596EE0',
    },
    background: {
      paper: '#ffffff',
      default: '#000000',
    },
    grey: {
      100: '#868b93',
      200: '#868686',
      300: '#E8EBEC',
      400: '#4f5664',
      500: '#A1A5AB',
      600: '#22222280',
      700: '#FFFFFF80',
      800: '#00000080',
      900: '#BFBFBF',
    },
    toastError: {
      500: '#CE2818',
    },
    iconColor: '#000000',
    divider: '#DDDDDD',
    success: {
      main: '#51BD7F',
    },
    error: {
      main: '#E34A3C',
    },
    info: {
      main: '#F4994D',
    },
    black: {
      100: '#191919',
      200: 'rgba(15, 15, 15, 0.00)',
      300: 'rgba(0, 0, 0, 0.15)',
      400: 'rgba(0, 0, 0, 0.1)',
      500: 'rgba(0,0,0,0.2)',
      600: '#0F0F0F',
      700: '#1C1C1C',
      800: '#424242',
      900: '#1f1f1f',
    },
    white: {
      100: '#fff',
      200: '#F7F7F7',
      300: '#F4F8FA',
      400: '#E7E7E7',
      500: '#F6F5F5',
      600: '#CEDEE5',
      700: '#00000040',
    },
    echoBlue: {
      100: '#9DBAC6',
    },
    brown: {
      100: '#004C8133',
    },
  },
} as MixThemeOptions) as MixTheme;
