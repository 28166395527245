import { Box, IconButton } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import { CreateImageResponse } from '@/types/CreateImage';
import { theme } from '../../theme';
import { SharedImages } from '../../assets/images';

type CreateCyphlensImageViewerItemProps = {
  imageItem: CreateImageResponse;
  onDeleteImageClick: (id: string) => void;
  onShareButtonClick: (id: string) => void;
  onDownloadButtonClick: (id: string) => void;
};

const CreateCyphlensImageViewerItem: React.FC<
  CreateCyphlensImageViewerItemProps
> = ({
  imageItem,
  onDeleteImageClick,
  onShareButtonClick,
  onDownloadButtonClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const imageRef = useRef(null);

  const [isHover, setHovered] = React.useState(false);
  const handleMouseEnter = useCallback(() => {
    setAnchorEl(imageRef.current);
    setHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const onDeleteButtonClickEvent = useCallback(() => {
    onDeleteImageClick(imageItem.id);
  }, [onDeleteImageClick, imageItem.id]);
  const onShareButtonClickEvent = useCallback(() => {
    onShareButtonClick(imageItem.id);
  }, [onShareButtonClick, imageItem.id]);
  const onDownloadButtonClickEvent = useCallback(() => {
    onDownloadButtonClick(imageItem.id);
  }, [onDownloadButtonClick, imageItem.id]);

  return (
    <Box
      data-testid={imageItem.id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        cursor: 'pointer',
      }}
    >
      {isHover && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            width: anchorEl?.offsetWidth,
            textAlign: 'right',
            height: anchorEl?.offsetHeight,
            backgroundColor: theme?.palette?.background.default,
            opacity: 0.7,
          }}
        >
          <IconButton
            aria-label="Delete"
            onClick={onDeleteButtonClickEvent}
            data-testid="image-item-delete"
          >
            <Box
              component="img"
              padding="5px"
              src={SharedImages?.delete.default}
              alt="filter"
            />
          </IconButton>
          <IconButton
            aria-label="Share"
            onClick={onShareButtonClickEvent}
            data-testid="image-item-share"
          >
            <Box
              component="img"
              padding="5px"
              src={SharedImages?.share.default}
              alt="filter"
            />
          </IconButton>
          <IconButton
            data-testid="image-item-download"
            aria-label="Download"
            onClick={onDownloadButtonClickEvent}
          >
            <Box
              component="img"
              padding="5px"
              src={SharedImages?.imageDownload.default}
              alt="filter"
            />
          </IconButton>
        </Box>
      )}
      <Box
        ref={imageRef}
        key={imageItem.id}
        component="img"
        src={`data:image/png;base64,${imageItem.image}`}
        alt="Loading"
        sx={{
          position: 'relative',
          maxWidth: '50%',
        }}
      />
    </Box>
  );
};

export default CreateCyphlensImageViewerItem;
