import React, { useCallback } from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, TextField, AutoComplete } from '../../components';
import { ContactUsSubjectOptions } from '../../constants';
import { SharedImages } from '../../assets/images';

export type ContactUsData = {
  subject: string;
  message: string;
};
export type ContactUsLayoutProps = {
  onClickSendEmail: (values: ContactUsData) => void;
};

const validationSchema = Yup.object({
  subject: Yup.string().required('contactUsPage.subjectFieldError'),
  message: Yup.string().required('contactUsPage.messageFieldError'),
});

const ContactUsLayout: React.FC<ContactUsLayoutProps> = ({
  onClickSendEmail,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      subject: '',
      message: '',
    },
    validationSchema,
    onSubmit: onClickSendEmail,
  });

  const handleTopicChange = useCallback(
    (value: string | null) => {
      formik.setFieldValue('subject', value);
    },
    [formik],
  );

  return (
    <Box
      sx={{
        background: theme?.palette?.background?.paper,
      }}
    >
      <Typography
        variant="h4"
        sx={{ fontFamily: theme?.font?.primaryBold, fontSize: '18px' }}
      >
        {t('contactUsPage.title')}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            marginTop: '30px',
            height: '40px',
            fontFamily: theme?.font?.primary,
          }}
        >
          <AutoComplete
            id="auto-complete-selector"
            options={ContactUsSubjectOptions}
            value={formik.values.subject || null}
            onChange={handleTopicChange}
            placeholder={t('contactUsPage.topicLabel')}
            isTouched={formik?.touched?.subject}
            errorMessage={formik?.errors?.subject}
          />
        </Box>
        <TextField
          placeholder={t('contactUsPage.messageLabel')}
          name="message"
          sx={{
            marginTop: '30px',
            '& .MuiOutlinedInput-input': {
              padding: 0,
            },
          }}
          rows={5}
          value={formik.values.message}
          onChange={formik.handleChange}
          isTouched={formik?.touched?.message}
          errorMessage={formik?.errors?.message}
          fullWidth
          multiline
        />
        <Button
          type="submit"
          title={t('contactUsPage.sendButtonLabel')}
          sx={{
            marginTop: '30px',
            width: '150px',
            height: '40px',
          }}
          startIcon={
            <Box component="img" src={SharedImages.send.default} alt="send" />
          }
          variant="contained"
        />
      </form>
    </Box>
  );
};

export default ContactUsLayout;
