import React from 'react';
import DrawerContainer, { drawerWidth } from '../../components/drawer';
import { Outlet } from 'react-router-dom';
import { theme } from '../../theme';
import Box from '@mui/material/Box';

const AppLayout = () => {
  return (
    <>
      <DrawerContainer />
      <Box
        component="main"
        sx={{
          padding: '20px 30px',
          sm: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          background: theme.palette.black?.[200],
          height: `100vh`,
        }}
      >
        <Box
          sx={{
            boxShadow: `0px 0px 10px 0px ${theme.palette.brown?.[100]}`,
            padding: '30px',
            background: theme.palette.white?.[100],
            borderRadius: '5px',
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default AppLayout;
