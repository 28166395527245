import { Box } from '@mui/material';
import React, { useCallback } from 'react';
import Grid from '@mui/material/Grid';
import CreateCyphlensImageForm, {
  CreateCyphlensImageFormType,
} from './CreateCyphlensImageForm';
import { CreateImageResponse } from '../../types/CreateImage';
import CreateCyphlensImageViewer from '../../layout/createCyphlensImage/CreateCyphlensImageViewer';
import { ShareLayoutType } from '../../pages/CreateCyphlensImagePage';

export type CreateCyphlensImageLayoutProps = {
  isLoading?: boolean;
  isSharePopupOpen?: boolean;
  singleUseTextCount: number;
  onCreateImage: (values: CreateCyphlensImageFormType) => void;
  cyphlensImages: CreateImageResponse[];
  onFileChange: (file: File) => void;
  selectedFile: File | null;
  uploadProgress: number;
  onFileRemove: () => void;
  onShareButtonClick: () => void;
  onDownloadButtonClick: () => void;
  onSharePopupClose?: () => void;
  onMessageOptionSelected?: () => void;
  onEmailOptionSelected?: () => void;
  shareLayoutType: ShareLayoutType;
  onClickMessageSendButton: (phoneCode: string, phoneNumber: string) => void;
  onSendEmailEvent: (emailList: string[]) => void;
  onShareImageItemEvent: (id: string) => void;
  onDownloadImageItemEvent: (id: string) => void;
  onDeleteImageItemEvent: (id: string) => void;
};

const CreateCyphlensImageLayout: React.FC<CreateCyphlensImageLayoutProps> = ({
  isLoading,
  isSharePopupOpen,
  singleUseTextCount,
  onCreateImage,
  cyphlensImages,
  onFileChange,
  onFileRemove,
  selectedFile,
  uploadProgress,
  onShareButtonClick,
  onDownloadButtonClick,
  onSharePopupClose,
  onEmailOptionSelected,
  onMessageOptionSelected,
  shareLayoutType,
  onSendEmailEvent,
  onClickMessageSendButton,
  onShareImageItemEvent,
  onDownloadImageItemEvent,
  onDeleteImageItemEvent,
}) => {
  const [formHeight, setFormHeight] = React.useState<number | null>(null);

  const onFormHeightChange = useCallback((height: number) => {
    setFormHeight(height);
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Grid
        container
        spacing={2}
        columnSpacing={6}
        sx={{
          gridTemplateColumns: 'repeat(5, 1fr)',
          alignItems: 'stretch',
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CreateCyphlensImageForm
            onFormHeightChange={onFormHeightChange}
            key={'create-cyphlens-image-form'}
            onCreateImage={onCreateImage}
            onFileChange={onFileChange}
            isLoading={isLoading}
            singleUseTextCount={singleUseTextCount}
            selectedFile={selectedFile}
            uploadProgress={uploadProgress}
            onFileRemove={onFileRemove}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CreateCyphlensImageViewer
            key={cyphlensImages.length}
            isLoading={isLoading}
            cyphlensImages={cyphlensImages}
            onShareButtonClick={onShareButtonClick}
            onDownloadButtonClick={onDownloadButtonClick}
            isSharePopupOpen={isSharePopupOpen}
            onSharePopupClose={onSharePopupClose}
            onEmailOptionSelected={onEmailOptionSelected}
            onMessageOptionSelected={onMessageOptionSelected}
            shareLayoutType={shareLayoutType}
            onSendEmailEvent={onSendEmailEvent}
            onClickMessageSendButton={onClickMessageSendButton}
            onDeleteImageItemEvent={onDeleteImageItemEvent}
            onDownloadImageItemEvent={onDownloadImageItemEvent}
            onShareImageItemEvent={onShareImageItemEvent}
            formHeight={formHeight}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateCyphlensImageLayout;
