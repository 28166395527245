import {
  Switch,
  DatePicker,
  TextField,
  TextareaWithCounter,
} from '../../components';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { t } from 'i18next';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '../../components/button';
import Dropdown from '../../components/dropdown/Dropdown';
import { FileUploader } from '../../components/fileUploader';
import { useTheme } from '@mui/material/styles';
import { SharedImages } from '../../assets/images';
import { EmailPicker } from '../../components/emailPicker';
import { SelectChangeEvent } from '@mui/material';
import { AccessType, DESCRIPTION_MAX_LENGTH } from '../../constants';
import { addDays } from 'date-fns';
import { timezoneOptions } from '../../utils/TimezoneUtil';

export type CreateCyphlensImageFormType = {
  title: string;
  message: string;
  accessType: string | 'PRIVATE';
  decryptionType: string | 'POPUP';
  expirationDate: Date | null;
  expirationTimeZone: string;
  canShowTitle: boolean | false;
  isOverlay: boolean | false;
  emailList: string[];
  singleUseOnly: boolean | false;
};

export type CreateCyphlensImageFormProps = {
  isLoading?: boolean;
  singleUseTextCount: number;
  onCreateImage: (values: CreateCyphlensImageFormType) => void;
  onFileChange: (file: File) => void;
  selectedFile: File | null;
  uploadProgress: number;
  onFileRemove: () => void;
  onFormHeightChange: (height: number) => void;
};

const CreateCyphlensImageForm: React.FC<CreateCyphlensImageFormProps> = ({
  isLoading,
  singleUseTextCount,
  onCreateImage,
  onFileChange,
  selectedFile,
  uploadProgress,
  onFileRemove,
  onFormHeightChange,
}) => {
  const nextDate = addDays(new Date(), 1);
  const [neverExpire, setNeverExpire] = useState<boolean>(true);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    onFormHeightChange(formRef.current?.clientHeight ?? 0);
  }, [formRef, onFormHeightChange]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('createCyphlensImage.validation.title')),
    accessType: Yup.string(),
    message: Yup.string()
      .test(
        'file-check',
        t('createCyphlensImage.validation.message'),
        function (value) {
          return selectedFile !== null || !!value;
        },
      )
      .max(200, t('createCyphlensImage.validation.message')),
  });

  const accessTypeOptions = [
    {
      value: 'PRIVATE',
      label: t('createCyphlensImage.accessTypeOptions.private'),
    },
    {
      value: 'PUBLIC',
      label: t('createCyphlensImage.accessTypeOptions.public'),
    },
    {
      value: 'SHARED',
      label: t('createCyphlensImage.accessTypeOptions.shared'),
    },
  ];

  const handleSubmit = useCallback(
    (
      value: CreateCyphlensImageFormType,
      { resetForm }: FormikHelpers<CreateCyphlensImageFormType>,
    ) => {
      onCreateImage(value);
      resetForm();
    },
    [onCreateImage],
  );

  const currentTimeZone = useMemo(() => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }, []);

  const formik = useFormik({
    initialValues: {
      title: '',
      message: '',
      accessType: AccessType.private,
      expirationDate: null,
      expirationTimeZone: currentTimeZone,
      decryptionType: 'POPUP',
      canShowTitle: false,
      isOverlay: false,
      emailList: [] as string[],
      singleUseOnly: false,
    } as CreateCyphlensImageFormType,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const theme = useTheme();

  const handleShowTitleChange = useCallback(() => {
    formik.setFieldValue('canShowTitle', !formik.values.canShowTitle);
  }, [formik]);

  const handleIsOverlayChange = useCallback(() => {
    formik.setFieldValue('isOverlay', !formik.values.isOverlay);
  }, [formik]);

  const handleSingleUseOnlyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue('singleUseOnly', event.target.checked);
    },
    [formik],
  );

  const handleExpirationDateChange = useCallback(
    (date: Date | null) => {
      formik.setFieldValue('expirationDate', date);
    },
    [formik],
  );

  const handleFileChange = useCallback(
    (file: File) => {
      onFileChange(file);
      formik.setFieldValue('isOverlay', false);
    },
    [onFileChange, formik],
  );

  const handleNeverExpireChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNeverExpire(e.target.checked);
      if (e.target.checked) {
        formik.setFieldValue('expirationDate', null);
      }
    },
    [formik],
  );

  const handleOnAccessTypeChange = useCallback(
    (event: SelectChangeEvent) => {
      formik.setFieldValue('accessType', event.target.value);
    },
    [formik],
  );

  const handleTimeZoneChange = useCallback(
    (event: SelectChangeEvent) => {
      formik.setFieldValue('expirationTimeZone', event.target.value);
    },
    [formik],
  );

  const handleSetEmailList = useCallback(
    (emailList: string[]) => {
      formik.setFieldValue('emailList', [...emailList]);
    },
    [formik],
  );

  return (
    <form
      data-test-id="create-cyphlens-form"
      onSubmit={formik.handleSubmit}
      ref={formRef}
    >
      <Typography marginBottom="32px" variant="h6">
        {t('createCyphlensImage.title')}
      </Typography>
      <Stack direction="column" gap="1.25rem" width="100%">
        <Box>
          <Stack
            marginBottom="6px"
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="h4">
              {t('createCyphlensImage.titleLabel')}
            </Typography>
            <Stack direction="row" alignItems="center">
              <Typography
                sx={{
                  fontSize: '12px',
                  color: theme?.palette?.text?.primary,
                  mr: '7px',
                }}
              >
                {t('createCyphlensImage.show')}
              </Typography>
              <Switch
                id={'show-title-switch'}
                data-testid="show-title-switch"
                isEnabled={formik.values.canShowTitle}
                onToggle={handleShowTitleChange}
              />
            </Stack>
          </Stack>
          <TextField
            sx={{
              width: '100%',
              borderColor: theme?.palette?.echoBlue?.[100],
              margin: 0,
            }}
            placeholder={t('createCyphlensImage.titlePlaceHolder')}
            name="title"
            id="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            errorMessage={formik.errors?.title}
            isTouched={formik.touched?.title}
          />
        </Box>
        <Box>
          <Stack
            marginBottom="6px"
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="h4">
              {t('createCyphlensImage.messageLabel')}
            </Typography>
            <Stack direction="row" alignItems="center">
              <Typography
                sx={{
                  fontSize: '12px',
                  color: theme?.palette?.text?.primary,
                  mr: '7px',
                }}
              >
                {t('createCyphlensImage.overlay')}
              </Typography>
              <Switch
                id={'overlay-switch'}
                data-testid="overlay-switch"
                isEnabled={formik.values.isOverlay}
                onToggle={handleIsOverlayChange}
              />
            </Stack>
          </Stack>
          <TextareaWithCounter
            sx={{
              margin: 0,
              '& .MuiOutlinedInput-input': {
                padding: 0,
              },
            }}
            id="message"
            placeholder={t('createCyphlensImage.messagePlaceHolder')}
            value={formik.values.message}
            onChange={formik.handleChange}
            errorMessage={formik.errors?.message}
            isTouched={formik.touched?.message}
            inputProps={{
              maxLength: DESCRIPTION_MAX_LENGTH,
            }}
          />
        </Box>
        <Box sx={{ marginBottom: '10px' }} data-testid="file-upload-section">
          <FileUploader
            onFileChange={handleFileChange}
            selectedFile={selectedFile}
            uploadProgress={uploadProgress}
            onFileRemove={onFileRemove}
          />
        </Box>
        <Box
          sx={{
            marginBottom: '10px',
          }}
        >
          <Typography variant="h4" marginBottom="6px">
            {t('createCyphlensImage.accessTypeLabel')}
          </Typography>
          <Stack direction="row">
            <Dropdown
              id={'accessType'}
              onSelectValue={handleOnAccessTypeChange}
              value={formik.values.accessType}
              options={accessTypeOptions}
            />
            <Box sx={{ width: '100%', minWidth: 0 }}>
              <EmailPicker
                menuList={formik.values.emailList}
                setMenuList={handleSetEmailList}
                isDisabled={formik.values.accessType != 'SHARED'}
              />
            </Box>
          </Stack>
        </Box>
        <Box sx={{ marginBottom: '10px' }} data-testid="expiry-section">
          <Stack
            marginBottom="6px"
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="h4">
              {t('createCyphlensImage.expirationDateLabel')}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: theme?.palette?.text?.primary,
                  mr: '7px',
                }}
              >
                {t('createCyphlensImage.neverExpire')}
              </Typography>
              <Switch
                id={'never-expire'}
                data-testid={'never-expire'}
                isEnabled={neverExpire}
                onToggle={handleNeverExpireChange}
              />
            </Box>
          </Stack>
          <Stack direction="row">
            <DatePicker
              onChange={handleExpirationDateChange}
              value={formik.values.expirationDate}
              disabled={neverExpire}
              minDate={nextDate}
              disableHighlightToday={true}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '5px 0 0 5px',
                },
                '& .MuiInputLabel-root': {
                  textAlign: 'center',
                  fontSize: '10px',
                  width: '100%',
                },
                '& .MuiInputBase-input.MuiOutlinedInput-input': {
                  width: '100%',
                  padding: '4px',
                  fontSize: '10px',
                  height: '32px',
                },
              }}
            />
            <Dropdown
              id={'timezone'}
              onSelectValue={handleTimeZoneChange}
              isDisabled={neverExpire}
              value={formik.values.expirationTimeZone}
              options={timezoneOptions}
              sx={{
                borderRadius: '0 5px 5px 0',
              }}
            />
          </Stack>
        </Box>
        <Stack marginBottom="20px" direction="row" alignItems="center">
          <Typography
            variant="h4"
            sx={{
              mr: '7px',
              fontSize: '12px',
            }}
          >
            {t('createCyphlensImage.singleUseText', { singleUseTextCount })}
          </Typography>
          <Switch
            id={'single-use-only'}
            data-testid={'single-use-only'}
            onToggle={handleSingleUseOnlyChange}
            isEnabled={formik.values.singleUseOnly}
          />
        </Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{ width: '150px' }}
            data-testid="create-cyphlens-button"
            type="submit"
            variant="contained"
            isLoading={isLoading}
            startIcon={
              <Box
                component="img"
                height={'16px'}
                width={'16px'}
                src={SharedImages?.create?.default}
                alt="filter"
              />
            }
            title={t('createCyphlensImage.createButton')}
          />
        </Box>
      </Stack>
    </form>
  );
};
export default CreateCyphlensImageForm;
