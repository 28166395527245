import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import {
  LoginPage,
  RecoverPage,
  AccountPage,
  ContactUsPage,
  FaqPage,
  EmergencyRecoverAccountPage,
  CreateCyphlensImagePage,
  ActivityLogPage,
  FileManagerPage,
  EmergencyContactPage,
} from '../pages';
import { AuthenticationType, RouterName } from '../constants';
import AppLayout from '../layout/appLayout';
import { useAuth } from '../state';
import { StorageService } from '../services';
import { useMemo } from 'react';

const Router = (): React.JSX.Element => {
  const { isLoggedIn } = useAuth();
  const authenticationType = StorageService.authenticationType.getValue();

  const isMfaLoggedIn = useMemo(() => {
    return authenticationType == AuthenticationType.MFA;
  }, [authenticationType]);

  return (
    <Routes>
      <Route path="*" element={<LoginPage />} />
      <Route path={RouterName.recover} element={<RecoverPage />} />
      <Route
        path={RouterName.emergencyContact}
        element={<EmergencyContactPage />}
      />
      {isLoggedIn && (
        <Route path="/" element={<AppLayout />}>
          <Route index element={<Navigate to={RouterName.account} />} />
          <Route path={RouterName.account} element={<AccountPage />} />
          <Route path={RouterName.contactUs} element={<ContactUsPage />} />
          <Route path={RouterName.faq} element={<FaqPage />} />
          <Route
            path={RouterName.emergencyRecoverAccount}
            element={<EmergencyRecoverAccountPage />}
          />
          {isMfaLoggedIn && (
            <>
              <Route
                path={RouterName.createCyphlensImage}
                element={<CreateCyphlensImagePage />}
              />
              <Route
                path={RouterName.activityLog}
                element={<ActivityLogPage />}
              />
              <Route
                path={RouterName.fileManager}
                element={<FileManagerPage />}
              />
            </>
          )}
        </Route>
      )}
    </Routes>
  );
};

export default Router;
