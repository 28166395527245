import React, { useCallback, useState } from 'react';
import { RecoverLayout } from '../layout/recover';
import { useAuth, useSnackbar } from '../state';
import { RecoverRepository } from '../repositories';
import { useNavigate } from 'react-router-dom';
import { RouterName, AuthenticationType } from '../constants';
import { RecoverFormType } from '../layout/recover/RecoverForm';

const RecoverPage = () => {
  const { setIsLoggedIn, setUser, updateAuthenticationType } = useAuth();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleClickLogin = useCallback(
    async (values: RecoverFormType) => {
      try {
        setLoading(true);
        const response = await RecoverRepository.recover(
          values.email,
          values.password,
        );
        updateAuthenticationType(AuthenticationType.SFA);
        setIsLoggedIn(true);
        setUser(response);
        navigate(RouterName.account);
        setLoading(false);
      } catch (error: unknown) {
        setLoading(false);
        if (error instanceof Error && error?.message) {
          snackbar.show(error.message);
        }
      }
    },
    [snackbar, navigate, setIsLoggedIn, setUser, updateAuthenticationType],
  );

  return (
    <RecoverLayout
      data-testid="RecoverLayout"
      isLoading={loading}
      onClickLogin={handleClickLogin}
    />
  );
};

export default RecoverPage;
