import React from 'react';
import { Box, Grid } from '@mui/material';
import { LogoImages } from '../../assets/images';
import { theme } from '../../theme';
import LoginForm, { LoginFormType } from './LoginForm';

export type LoginLayoutProps = {
  isLoading: boolean;
  mfaInterval?: number;
  imageUrl?: string;
  screenType: string;
  onClickRecover: () => void;
  onClickRefresh: () => void;
  onClickNext: (values: LoginFormType) => void;
  onClickVerify: (values: LoginFormType) => void;
  onClickLogin: (values: LoginFormType) => void;
};

const LoginLayout: React.FC<LoginLayoutProps> = ({
  isLoading,
  mfaInterval,
  imageUrl,
  screenType,
  onClickRecover,
  onClickRefresh,
  onClickNext,
  onClickVerify,
  onClickLogin,
}) => {
  return (
    <Grid container item xs={12} sx={{ display: 'flex', minHeight: '100vh' }}>
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        sx={{
          flex: '1 1',
          minWidth: '500px',
          height: '100vh',
          width: '100%',
          background: `linear-gradient(270deg, ${theme?.palette?.black?.[200]} 1.81%, ${theme?.palette?.black?.[100]}  48.71%, ${theme?.palette?.black?.[200]} 100%)`,
          backgroundColor: theme?.palette?.background?.default,
          boxShadow: `0px 6px 60px 0px ${theme?.palette?.black?.[300]}`,
          opacity: 0.9,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src={LogoImages.cyphlensLogo}
          width="145px"
          alt="recover-background"
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={8}
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src={LogoImages.transparent.default}
          alt="transparent-logo"
          sx={{
            position: 'absolute',
            width: '300px',
            height: '375px',
            flexShrink: 0,
            top: '-30px',
            right: 0,
          }}
        />
        <LoginForm
          isLoading={isLoading}
          mfaInterval={mfaInterval}
          imageUrl={imageUrl}
          screenType={screenType}
          onClickRecover={onClickRecover}
          onClickRefresh={onClickRefresh}
          onClickNext={onClickNext}
          onClickVerify={onClickVerify}
          onClickLogin={onClickLogin}
        />
      </Grid>
    </Grid>
  );
};

export default LoginLayout;
