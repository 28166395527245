import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const NoEmergencyContactLayout = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box>
      <Typography
        sx={{
          marginTop: '20px',
          fontSize: '14px',
          color: theme?.palette?.text?.primary,
          fontFamily: theme?.font?.primaryBold,
        }}
      >
        {t('emergencyRecoverPage.noEmergencyContactMessage')}
      </Typography>
      <Box display="flex" gap="2px">
        <Typography
          sx={{
            marginTop: '20px',
            fontSize: '14px',
            color: theme?.palette?.text?.primary,
          }}
        >
          {t('emergencyRecoverPage.emergencyContactFirstInstructionPrefix')}
        </Typography>
        <Typography
          sx={{
            marginTop: '20px',
            fontSize: '14px',
            color: theme?.palette?.text?.primary,
            fontWeight: 700,
          }}
        >
          {t('emergencyRecoverPage.emergencyContactFirstInstructionPath')}
        </Typography>
        <Typography
          sx={{
            marginTop: '20px',
            fontSize: '14px',
            color: theme?.palette?.text?.primary,
          }}
        >
          {t('emergencyRecoverPage.emergencyContactFirstInstructionSuffix')}
        </Typography>
      </Box>
      <Typography
        sx={{
          marginTop: '10px',
          fontSize: '14px',
          color: theme?.palette?.text?.primary,
        }}
      >
        {t('emergencyRecoverPage.emergencyContactSecondInstruction')}
      </Typography>
      <Typography
        sx={{
          marginTop: '20px',
          fontSize: '14px',
          color: theme?.palette?.text?.primary,
        }}
      >
        {t('emergencyRecoverPage.subTitle')}
      </Typography>
    </Box>
  );
};

export default NoEmergencyContactLayout;
