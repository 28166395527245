import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmergencyRecoverAccountLayout } from '../layout/emergencyRecoverAccount';
import { EmergencyRecoverAccountRepository } from '../repositories';
import { EmergencyContactListResponse } from '../types';
import { useSnackbar } from '../state';
import { useLoading } from '../components/loading/LoadingProvider';
import { SeverityType } from '../components/snackbar/Snackbar';

const EmergencyRecoverAccountPage = () => {
  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const [selectedContact, setSelectedContact] = useState<string | null>(null);
  const [recoverContactListData, setRecoverContactListData] =
    useState<EmergencyContactListResponse>([]);

  const handleChangeContactList = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedContact(event.target.value);
    },
    [],
  );

  const fetchRecoverContactList = useCallback(async () => {
    try {
      setLoading(true);
      const response =
        await EmergencyRecoverAccountRepository.emergencyContactList();
      setRecoverContactListData(response);
    } catch (error: unknown) {
      if (error instanceof Error && error?.message) {
        snackbar.show(error.message);
      }
    } finally {
      setLoading(false);
    }
  }, [snackbar, setLoading]);

  const handleSendToken = useCallback(async () => {
    try {
      setLoading(true);
      await EmergencyRecoverAccountRepository.recover(selectedContact ?? '');
      snackbar.show(
        t('emergencyRecoverPage.sendTokenSuccessMessage'),
        SeverityType.success,
      );
    } catch (error: unknown) {
      if (error instanceof Error && error?.message) {
        snackbar.show(error.message);
      }
    } finally {
      setLoading(false);
    }
  }, [selectedContact, snackbar, t, setLoading]);

  useEffect(() => {
    fetchRecoverContactList();
  }, [fetchRecoverContactList]);

  return (
    <EmergencyRecoverAccountLayout
      selectedContact={selectedContact}
      recoverContactListData={recoverContactListData}
      onChangeRecoverContact={handleChangeContactList}
      onClickSendToken={handleSendToken}
    />
  );
};

export default EmergencyRecoverAccountPage;
