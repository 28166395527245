import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { TextField, Button, EmailPickerItem } from '../../components';
import { t } from 'i18next';

type EditEmailListProps = {
  value: string;
  menuList: string[];
  isTouched: boolean;
  errorMessage: string | null;
  onBlurEvent: (event: React.FocusEvent<HTMLInputElement>) => void;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEnterKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onClickRemove: (index: number) => void;
  onClickClose: () => void;
  onClickEditApply: () => void;
};

const EditEmailList: React.FC<EditEmailListProps> = ({
  value,
  menuList,
  isTouched,
  errorMessage,
  onBlurEvent,
  onInputChange,
  onEnterKeyPress,
  onClickRemove,
  onClickClose,
  onClickEditApply,
}) => {
  const theme = useTheme();

  return (
    <Box>
      <TextField
        sx={{
          margin: 0,
          borderColor: `1px solid ${theme.palette?.echoBlue?.[100]}`,
          height: '30px',
          '& .MuiInputBase-input': {
            padding: '4px',
          },
        }}
        value={value}
        onBlur={onBlurEvent}
        onChange={onInputChange}
        placeholder={t('editSharedEmail.emailPlaceholder')}
        data-testid="email-input"
        onKeyDown={onEnterKeyPress}
        isTouched={isTouched}
        errorMessage={errorMessage ?? ''}
      />
      <Box
        maxHeight="150px"
        sx={{ overflowY: 'auto', overflowX: 'hidden', mt: '5px' }}
      >
        {menuList &&
          menuList.map((text, index) => (
            <EmailPickerItem
              key={text}
              index={index}
              email={text}
              sx={{ mt: '24px' }}
              removeIconStyle={{
                width: '16px',
                height: '16px',
                cursor: 'pointer',
                marginLeft: '4px',
              }}
              onRemoveItem={onClickRemove}
            />
          ))}
      </Box>
      <Box marginTop="30px" display="flex" justifyContent="center" gap="4px">
        <Button
          variant="outlined"
          title={t('editSharedEmail.cancelButtonLabel')}
          onClick={onClickClose}
          sx={{ width: '65px', height: '24px' }}
        />
        <Button
          title={t('editSharedEmail.applyButtonLabel')}
          onClick={onClickEditApply}
          sx={{ width: '65px', height: '24px' }}
          variant="contained"
        />
      </Box>
    </Box>
  );
};

export default EditEmailList;
