import React from 'react';
import { Box, Typography } from '@mui/material';
import { theme } from '../../theme';
import { useTranslation } from 'react-i18next';
import HighlightOffRounded from '@mui/icons-material/HighlightOffRounded';
import { ActivityFilterData } from '../../pages/ActivityLogPage';

type ActivityLogViewFilterProps = {
  filterData?: ActivityFilterData;
  onFilterReset: () => void;
};

const ActivityLogViewFilter: React.FC<ActivityLogViewFilterProps> = ({
  filterData,
  onFilterReset,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            color: theme?.palette?.black?.[100],
            fontWeight: 400,
          }}
        >
          {t('activityLog.filters')}
        </Typography>
        <Typography
          onClick={onFilterReset}
          sx={{
            fontSize: '10px',
            fontWeight: 300,
            color: theme?.palette?.grey?.[500],
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          {t('activityLog.clearAll')}
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: '10px',
          border: `0.6px solid ${theme.palette.echoBlue?.[100]}`,
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxHeight: '25px',
          height: '25px',
          backgroundColor: theme.palette.white?.[500],
          wordWrap: 'break-word',
          paddingLeft: '10px',
          paddingRight: '10px',
          maxWidth: '250px',
        }}
      >
        <Typography
          sx={{
            overflow: 'hidden',
            textWrap: 'nowrap',
            wordWrap: 'initial',
            textOverflow: 'ellipsis',
            fontSize: 12,
          }}
        >
          {`${t('filter.date')}: ${filterData?.fromDate?.toLocaleDateString()} ${t('filter.toLabel')} ${filterData?.toDate ? filterData?.toDate?.toLocaleDateString() : new Date()?.toLocaleDateString()}`}
        </Typography>
        {
          <HighlightOffRounded
            onClick={onFilterReset}
            data-testid="filter-remove"
            sx={{
              width: '16px',
              height: '16px',
              cursor: 'pointer',
              marginLeft: '15px',
            }}
          />
        }
      </Box>
    </>
  );
};

export default ActivityLogViewFilter;
