import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactUsLayout } from '../layout/contactUs';
import { ContactUsData } from '../layout/contactUs/ContactUsLayout';
import { useSnackbar } from '../state';
import { ContactUsRepository } from '../repositories';
import { useLoading } from '../components/loading/LoadingProvider';
import { SeverityType } from '../components/snackbar/Snackbar';

const ContactUsPage = () => {
  const snackbar = useSnackbar();
  const { setLoading } = useLoading();
  const { t } = useTranslation();

  const handleClickSendEmail = useCallback(
    async (values: ContactUsData) => {
      try {
        setLoading(true);
        await ContactUsRepository.sendEmail(values.subject, values.message);
        snackbar.show(
          t('contactUsPage.sendEmailSuccessMessage'),
          SeverityType.success,
        );
        setLoading(false);
      } catch (error: unknown) {
        setLoading(false);
        if (error instanceof Error && error?.message) {
          snackbar.show(error.message);
        }
      } finally {
        setLoading(false);
      }
    },
    [t, snackbar, setLoading],
  );

  return <ContactUsLayout onClickSendEmail={handleClickSendEmail} />;
};

export default ContactUsPage;
