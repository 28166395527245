import { useContext, createContext } from 'react';
import { SeverityType } from '@/components/snackbar/Snackbar';

type SnackBarCallback = (message: string, severity?: SeverityType) => void;

type SnackbarContextType = {
  show: SnackBarCallback;
};
export const SnackbarContext = createContext({} as SnackbarContextType);

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};
