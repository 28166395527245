import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Paper, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ProgressBar from '../progressBar/ProgressBar';
import { useTranslation } from 'react-i18next';
import { SharedImages } from '../../assets/images';

export type FileUploaderProps = {
  selectedFile: File | null;
  onFileChange: (file: File) => void;
  uploadProgress: number;
  onFileRemove: () => void;
};

const HiddenInput = styled('input')`
  position: absolute;
`;

const FileUploader: React.FC<FileUploaderProps> = ({
  onFileChange,
  selectedFile,
  onFileRemove,
  uploadProgress,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onFileChange(acceptedFiles[0] as File);
    },
    [onFileChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png', '.jpg', '.jpeg'],
      'video/mp4': ['.mp4'],
    },
  });

  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box>
      {selectedFile ? (
        <ProgressBar
          value={uploadProgress}
          file={selectedFile}
          onFileRemove={onFileRemove}
        />
      ) : (
        <Paper
          {...getRootProps()}
          data-testid="drop-input"
          sx={{
            width: '100%',
            borderRadius: '5px',
            backgroundColor: theme?.palette?.white?.[400],
            textAlign: 'center',
            cursor: 'pointer',
            border: `1px dashed ${theme?.palette?.echoBlue?.[100]}`,
            boxShadow: 'none',
            height: 60,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <HiddenInput type="file" {...getInputProps()} />
          <Box
            component="img"
            padding="5px"
            src={SharedImages?.upload.default}
            alt="filter"
          />
          <Typography sx={{ mr: '4px', fontSize: 14 }}>
            {t('createCyphlensImage.dragImageText')}
          </Typography>
          <Typography
            sx={{
              mr: '4px',
              textDecoration: 'underline',
              color: theme?.palette?.black?.[100],
              fontSize: 14,
              fontWeight: 700,
            }}
          >
            {t('createCyphlensImage.clickHere')}
          </Typography>
          <Typography sx={{ fontSize: 14 }}>to upload</Typography>
        </Paper>
      )}
    </Box>
  );
};

export default FileUploader;
