import React from 'react';
import {
  Snackbar as MUISnackbar,
  SnackbarProps as MUISnackbarProps,
  Alert,
} from '@mui/material';
import { VoidCallback } from '@/types';

export enum SeverityType {
  error = 'error',
  success = 'success',
  info = 'info',
  warning = 'warning',
}

export type SnackbarProps = MUISnackbarProps & {
  severity: SeverityType;
  onClose: VoidCallback;
};

const Snackbar: React.FC<SnackbarProps> = ({
  open,
  severity,
  message,
  onClose,
  ...props
}) => {
  return (
    <MUISnackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      {...props}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: '500px' }}>
        {message}
      </Alert>
    </MUISnackbar>
  );
};

export default Snackbar;
