import { Box } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import ShareIcon from '@mui/icons-material/Share';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { theme } from '../../theme';
import { Button } from '../../components/button';
import { CreateImageResponse } from '../../types/CreateImage';
import { ShareLayoutType } from '../../pages/CreateCyphlensImagePage';
import ShareLayout from '../share/ShareLayout';
import CreateCyphlensImageViewerItem from './CreateCyphlensImageViewerItem';
import { isNull } from 'lodash';

type CreateCyphlensImageViewerProps = {
  isLoading?: boolean;
  formHeight?: number | null;
  cyphlensImages: CreateImageResponse[];
  isSharePopupOpen?: boolean;
  onShareButtonClick: () => void;
  onDownloadButtonClick: () => void;
  onSharePopupClose?: () => void;
  onEmailOptionSelected?: () => void;
  onMessageOptionSelected?: () => void;
  shareLayoutType: ShareLayoutType;
  onSendEmailEvent: (emailList: string[]) => void;
  onClickMessageSendButton: (phoneCode: string, phoneNumber: string) => void;
  onShareImageItemEvent: (id: string) => void;
  onDownloadImageItemEvent: (id: string) => void;
  onDeleteImageItemEvent: (id: string) => void;
};

const CreateCyphlensImageViewer: React.FC<CreateCyphlensImageViewerProps> = ({
  isLoading,
  formHeight,
  cyphlensImages,
  onShareButtonClick,
  onDownloadButtonClick,
  isSharePopupOpen,
  onSharePopupClose,
  onEmailOptionSelected,
  onMessageOptionSelected,
  shareLayoutType,
  onSendEmailEvent,
  onClickMessageSendButton,
  onShareImageItemEvent,
  onDownloadImageItemEvent,
  onDeleteImageItemEvent,
}) => {
  return (
    <Box
      marginTop={{
        xs: 0,
        sm: 0,
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        maxHeight: isNull(formHeight) ? null : `${formHeight}px`,
      }}
    >
      <Box
        sx={{
          borderRadius: '5px',
          border: `1px solid ${theme?.palette?.echoBlue?.[100]}`,
          overflowY: 'auto',
          position: 'relative',
          mt: '84px',
          height: '100%',
          mb: '9px',
        }}
      >
        <Box
          data-testid="cyphlens-image-viewer"
          id="cyphlens-image-viewer"
          sx={{
            height: '100%',
          }}
        >
          {cyphlensImages.map((imageResponse) => (
            <CreateCyphlensImageViewerItem
              key={imageResponse.id}
              imageItem={imageResponse}
              onShareButtonClick={onShareImageItemEvent}
              onDownloadButtonClick={onDownloadImageItemEvent}
              onDeleteImageClick={onDeleteImageItemEvent}
            />
          ))}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          marginTop="30px"
          sx={{
            width: '150px',
            marginRight: '30px',
          }}
        >
          <Button
            data-testid="share-button"
            type="reset"
            variant="contained"
            startIcon={<ShareIcon sx={{ height: '16px', width: '15px' }} />}
            title={t('createCyphlensImage.shareButton')}
            style={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: '25px',
              border: `1px solid ${theme?.palette?.grey?.[800]}`,
              color:
                cyphlensImages.length === 0
                  ? theme?.palette?.grey?.[800]
                  : theme?.palette?.black?.[100],
            }}
            isDisabled={cyphlensImages.length === 0}
            onClick={onShareButtonClick}
          />
          <ShareLayout
            isLoading={isLoading}
            shareLayoutType={shareLayoutType}
            isSharePopupOpen={isSharePopupOpen}
            onSharePopupClose={onSharePopupClose}
            onEmailOptionSelected={onEmailOptionSelected}
            onMessageOptionSelected={onMessageOptionSelected}
            screenType={shareLayoutType}
            onSendEmailEvent={onSendEmailEvent}
            onClickMessageSendButton={onClickMessageSendButton}
          />
        </Box>
        <Box
          marginTop="30px"
          sx={{
            width: '150px',
          }}
        >
          <Button
            sx={{ width: '150px' }}
            data-testid="download-button"
            type="submit"
            variant="contained"
            startIcon={
              <DownloadRoundedIcon sx={{ height: '16px', width: '15px' }} />
            }
            isDisabled={cyphlensImages.length === 0}
            onClick={onDownloadButtonClick}
            title={t('createCyphlensImage.downloadButton')}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default CreateCyphlensImageViewer;
