import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import { theme } from '../../theme';
import { TextField, Button, TextFieldProps } from '../../components';

type MFAFormProps = {
  mfaInterval?: number;
  imageUrl?: string;
  isLoading: boolean;
  isDisabled?: boolean;
  onClickRefresh: () => void;
} & TextFieldProps;

const MFAForm: React.FC<MFAFormProps> = ({
  mfaInterval,
  imageUrl,
  isLoading,
  value,
  errorMessage,
  isTouched,
  isDisabled,
  onChange,
  onBlur,
  onClickRefresh,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box
        component="img"
        src={`data:image/svg+xml;base64,${imageUrl}`}
        alt="cyphlens-verify"
        style={{
          width: '100%',
        }}
      />
      <Box sx={{ marginBottom: '10px' }}>
        <TextField
          id="mfaCode"
          placeholder={t('loginForm.mfaCode')}
          name="mfaCode"
          type="text"
          value={value}
          errorMessage={errorMessage}
          isTouched={isTouched}
          onBlur={onBlur}
          onChange={onChange}
        />
      </Box>
      <Button
        disabled={isDisabled}
        title={`${t('loginForm.verifyButtonLabel', { mfaInterval })}`}
        isLoading={isLoading}
        type="submit"
        variant="contained"
        data-testid="verify-button"
      />
      <Typography
        variant="subtitle1"
        style={{
          fontSize: '14px',
          fontWeight: 500,
          cursor: 'pointer',
          marginTop: '30px',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          textDecoration: 'underline',
          color: theme?.palette?.text?.secondary,
        }}
        onClick={onClickRefresh}
      >
        {t('loginForm.refreshButtonLabel')}
      </Typography>
    </Box>
  );
};

export default MFAForm;
