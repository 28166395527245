export const MenuImages = {
  recover: require('./recover.png'),
  create: require('./create.svg'),
  account: require('./account.svg'),
  faq: require('./faq.svg'),
  fileManager: require('./fileManager.svg'),
  recoverSideNav: require('./recover.svg'),
  activityLog: require('./activityLog.svg'),
  contactUs: require('./contactUs.svg'),
  profile: require('./profile.svg'),
};
