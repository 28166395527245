export const RouterName = {
  login: '/login',
  recover: '/recover',
  account: '/account',
  contactUs: '/contact-us',
  faq: '/faq',
  emergencyRecoverAccount: '/emergency-recover',
  createCyphlensImage: '/create',
  activityLog: '/activity-log',
  fileManager: '/file-manager',
  emergencyContact: '/emergency-contact',
};
