import React, { useCallback } from 'react';
import {
  AutocompleteRenderInputParams,
  Box,
  FilterOptionsState,
  Autocomplete as MUIAutoComplete,
  SxProps,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { TextField } from '../textField';
import { CountryCode } from '../../utils/CountrycodeUtil';

export type AutoCompleteCountryProps = {
  id: string | undefined;
  value: CountryCode | null;
  onChange: (value: CountryCode | null) => void;
  options: CountryCode[];
  placeholder: string;
  isTouched?: boolean;
  errorMessage?: string;
  sx?: SxProps;
  inputSx?: SxProps;
};

const AutoCompleteCountry: React.FC<AutoCompleteCountryProps> = ({
  id,
  value,
  onChange,
  options,
  placeholder,
  isTouched,
  errorMessage,
  sx,
  inputSx,
}) => {
  const renderTextField = useCallback(
    (params: AutocompleteRenderInputParams): React.ReactNode => (
      <TextField
        {...params}
        sx={{ height: 40, ...inputSx }}
        placeholder={placeholder}
        errorMessage={errorMessage}
        isTouched={isTouched}
      />
    ),
    [placeholder, inputSx, errorMessage, isTouched],
  );

  const renderOptions = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: CountryCode) => {
      return (
        <Box
          component="li"
          key={option.code + option.callingCode}
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option.flag} ({option.code}) {option.callingCode}
        </Box>
      );
    },
    [],
  );

  const handleChange = useCallback(
    (
      _event: React.SyntheticEvent<Element, Event>,
      value: CountryCode | null,
    ) => {
      onChange(value);
    },
    [onChange],
  );

  const filterOptions = useCallback(
    (options: CountryCode[], state: FilterOptionsState<CountryCode>) => {
      return options.filter(
        (option) =>
          option.callingCode
            .replace('+', '')
            .toLowerCase()
            .startsWith(state.inputValue.replace('+', '').toLowerCase()) ||
          option.code.toLowerCase().startsWith(state.inputValue.toLowerCase()),
      );
    },
    [],
  );

  const renderOptionLabel = useCallback((option: CountryCode) => {
    return option.callingCode;
  }, []);

  const getOptionKey = useCallback((option: CountryCode) => {
    return option.code + option.callingCode;
  }, []);

  const isOptionEqualToValue = useCallback(
    (option: CountryCode, value: CountryCode) => {
      return (
        option.code === value.code && option.callingCode === value.callingCode
      );
    },
    [],
  );
  return (
    <MUIAutoComplete
      popupIcon={<ExpandMore />}
      disablePortal
      id={id}
      key={id}
      options={options}
      onChange={handleChange}
      title="select"
      renderInput={renderTextField}
      renderOption={renderOptions}
      getOptionLabel={renderOptionLabel}
      filterOptions={filterOptions}
      getOptionKey={getOptionKey}
      value={value}
      isOptionEqualToValue={isOptionEqualToValue}
      sx={{
        width: 385,
        height: 40,
        '& .MuiTextField-root': {
          marginTop: '0px',
        },
        ...sx,
      }}
    />
  );
};

export default AutoCompleteCountry;
