import * as React from 'react';
import { useCallback, useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { LogoImages, MenuImages } from '../../assets/images';
import DrawerMenuItem from './DrawerMenuItem';
import { AuthenticationType, RouterName } from '../../constants';
import DrawerBottomContainer from './DrawerBottomContainer';
import { useAuth, useSnackbar } from '../../state';
import { AccountRepository } from '../../repositories';
import { useLoading } from '../../components/loading/LoadingProvider';
import { StorageService } from '../../services';
export const drawerWidth = 240;

const drawerMenu = [
  {
    name: 'menu.account',
    icon: MenuImages.account.default,
    routeName: RouterName.account,
  },
  {
    name: 'menu.create',
    icon: MenuImages.create.default,
    routeName: RouterName.createCyphlensImage,
  },
  {
    name: 'menu.fileManager',
    icon: MenuImages.fileManager.default,
    routeName: RouterName.fileManager,
  },
  {
    name: 'menu.activityLog',
    icon: MenuImages.activityLog.default,
    routeName: RouterName.activityLog,
  },
  {
    name: 'menu.recover',
    icon: MenuImages.recoverSideNav.default,
    routeName: RouterName.emergencyRecoverAccount,
  },
  {
    name: 'menu.faq',
    icon: MenuImages.faq.default,
    routeName: RouterName.faq,
  },
  {
    name: 'menu.contactUs',
    icon: MenuImages.contactUs.default,
    routeName: RouterName.contactUs,
  },
];

const DrawerContainer: React.FC = () => {
  const snackbar = useSnackbar();
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { user, setIsLoggedIn } = useAuth();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState<number>(0);
  const authenticationType = StorageService.authenticationType.getValue();

  const onClickMenu = useCallback(
    async (index: number, routeName: string) => {
      setActiveTab(index);
      navigate(routeName);
      if (
        routeName === RouterName.faq ||
        routeName === RouterName.contactUs ||
        routeName === RouterName.createCyphlensImage
      ) {
        try {
          setLoading(true);
          await AccountRepository.getUser();
        } catch (error: unknown) {
          if (error instanceof Error && error?.message) {
            snackbar.show(error.message);
          }
        } finally {
          setLoading(false);
        }
      }
    },
    [navigate, snackbar, setLoading],
  );

  const handleLogout = useCallback(() => {
    setIsLoggedIn(false);
  }, [setIsLoggedIn]);

  const menuItems = useMemo(() => {
    if (authenticationType === AuthenticationType.MFA) {
      return drawerMenu;
    } else {
      return drawerMenu.filter(
        (menu) =>
          menu.routeName !== RouterName.createCyphlensImage &&
          menu.routeName !== RouterName.activityLog &&
          menu.routeName !== RouterName.fileManager,
      );
    }
  }, [authenticationType]);

  useEffect(() => {
    const selectedTab = menuItems.findIndex(
      (menuItem) => menuItem.routeName === location.pathname,
    );
    setActiveTab(selectedTab);
  }, [menuItems]);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        background: theme.palette.background.paper,
      }}
    >
      <>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            boxShadow: 'none',
          }}
        />
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            background: theme.palette.black?.[600],
            border: 'none',
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              background: theme.palette.black?.[600],
              color: theme.palette.background.paper,
              border: 'none',
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar>
            <Box
              component="img"
              alt="Cyphlens Logo"
              src={LogoImages.cyphlensLogo}
              sx={{
                width: '120px',
                height: '25px',
                marginRight: '12px',
              }}
            />
          </Toolbar>
          <List sx={{ paddingLeft: '16px' }}>
            {menuItems.map((menu, index) => (
              <DrawerMenuItem
                key={menu.name}
                index={index}
                activeTab={activeTab}
                menu={menu}
                isShowDivider={index === menuItems.length - 1}
                onClickMenu={onClickMenu}
              />
            ))}
          </List>
          <DrawerBottomContainer
            onClickLogout={handleLogout}
            profileImage={MenuImages.profile.default}
            name={user?.firstName ?? ''}
          />
        </Drawer>
      </>
    </Box>
  );
};

export default DrawerContainer;
