import React, { useMemo } from 'react';
import {
  Box,
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
} from '@mui/material';
import { theme } from '../../theme';
import { SharedImages } from '../../assets/images';

type ButtonProps = {
  id?: string;
  title: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  variant?: string;
  type?: string;
} & MUIButtonProps;

const Button: React.FC<ButtonProps> = ({
  id,
  title,
  isDisabled,
  type,
  isLoading,
  variant,
  sx,
  ...props
}) => {
  const backgroundColor = useMemo(() => {
    return variant === 'outlined'
      ? 'transparent'
      : theme?.palette?.text?.secondary;
  }, [variant]);

  const color = useMemo(() => {
    return variant === 'outlined'
      ? theme?.palette?.text?.secondary
      : theme?.palette?.background?.paper;
  }, [variant]);

  return (
    <MUIButton
      disabled={isDisabled}
      type={type}
      fullWidth
      variant={variant}
      sx={{
        borderRadius: '25px',
        backgroundColor: isDisabled
          ? theme?.palette?.text?.primary
          : backgroundColor,
        color: color,
        ...sx,
      }}
      {...props}
    >
      {isLoading ? (
        <Box
          sx={{ animation: 'spin 2s linear infinite' }}
          component="img"
          src={SharedImages.loading.default}
          alt="Loading"
          style={{
            width: '20px',
            height: '20px',
          }}
        />
      ) : (
        title
      )}
    </MUIButton>
  );
};

export default Button;
