import * as React from 'react';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import { SharedImages } from '../../assets/images';

export type LoadingProps = {
  isLoading: boolean;
};

const Loading: React.FC<LoadingProps> = ({ isLoading }) => {
  return (
    <Dialog
      open={isLoading}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <DialogContent
        sx={{
          width: '500px',
          height: '179px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          component="img"
          alt="Loading"
          src={SharedImages.loading.default}
          height={40}
          width={40}
          className="loading-icon"
          sx={{ animation: 'spin 2s linear infinite' }}
        />
        <Typography sx={{ fontSize: '18px' }}>Loading...</Typography>
      </DialogContent>
    </Dialog>
  );
};

export default Loading;
