import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileManagerLayout } from '../layout/fileManager';
import { FileManagerRepository } from '../repositories';
import debounce from 'lodash/debounce';
import { PAGE_COUNT, singleUseFileExpiration } from '../constants';
import { useSnackbar } from '../state';
import { useLoading } from '../components/loading/LoadingProvider';
import {
  DocumentData,
  FileManagerFilterParam,
  FileManagerResponse,
} from '../types/FileManager';
import { SeverityType } from '../components/snackbar/Snackbar';

export type FileManagerFilterData = {
  createdFromDate: Date | null;
  createdToDate: Date | null;
  expireFromDate: Date | null;
  expireToDate: Date | null;
  accessType: string[];
};

const FileManagerPage = () => {
  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const [searchText, setSearchText] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [filterData, setFilterData] = useState<FileManagerFilterData>({
    createdFromDate: null,
    createdToDate: null,
    expireFromDate: null,
    expireToDate: null,
    accessType: [],
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [page, setPage] = useState<number>(0);
  const [fileManagerData, setFileManagerData] = useState<FileManagerResponse>();
  const [filterValue, setFilterValue] = useState<FileManagerFilterParam | null>(
    null,
  );
  const [anchorElMenu, setAnchorElMenu] = useState<HTMLElement | null>(null);
  const [selectedDocument, setSelectedDocument] = useState<DocumentData | null>(
    null,
  );
  const [isEdit, setIsEdit] = useState(false);
  const [expireOn, setExpireOn] = useState<Date | null>(null);
  const [menuList, setMenuList] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [viewFilter, setViewFilter] = useState(false);
  const [appliedFilterData, setAppliedFilterData] =
    useState<FileManagerFilterData>({
      createdFromDate: null,
      createdToDate: null,
      expireFromDate: null,
      expireToDate: null,
      accessType: [],
    });

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      if (event?.currentTarget) setPage(newPage);
    },
    [],
  );

  const handleClickFilter = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setOpen((prevOpen) => !prevOpen);
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleChangeSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
    },
    [],
  );

  const handleApplySearch = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        setSearchText(searchValue);
        setPage(0);
      }
    },
    [searchValue],
  );

  const handleCreatedStartDateChange = useCallback((date: Date | null) => {
    setFilterData((prev) => ({
      ...prev,
      createdFromDate: date,
    }));
  }, []);

  const handleCreatedEndDateChange = useCallback((date: Date | null) => {
    setFilterData((prev) => ({
      ...prev,
      createdToDate: date,
    }));
  }, []);

  const handleExpireStartDateChange = useCallback((date: Date | null) => {
    setFilterData((prev) => ({
      ...prev,
      expireFromDate: date,
    }));
  }, []);

  const handleExpireEndDateChange = useCallback((date: Date | null) => {
    setFilterData((prev) => ({
      ...prev,
      expireToDate: date,
    }));
  }, []);

  const handleAccessTypeChange = useCallback((event: string[]) => {
    setFilterData((prev) => ({
      ...prev,
      accessType: event,
    }));
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleApply = useCallback(() => {
    const createOnFromDate = filterData?.createdFromDate
      ? filterData?.createdFromDate?.getTime()?.toString()
      : null;
    const createOnToDate = filterData?.createdToDate
      ? filterData?.createdToDate?.setHours(23, 59, 59)?.toString()
      : null;
    const expireOnFromDate = filterData?.expireFromDate
      ? filterData?.expireFromDate?.getTime()?.toString()
      : null;
    const expireOnToDate = filterData?.expireToDate
      ? filterData?.expireToDate?.setHours(23, 59, 59)?.toString()
      : null;
    const accessType =
      filterData?.accessType && Array.isArray(filterData.accessType)
        ? filterData?.accessType?.join(',')
        : null;
    setAppliedFilterData(filterData);
    setViewFilter(true);
    setFilterValue({
      ...(accessType && { accessType }),
      ...(createOnFromDate && { createOnFromDate }),
      ...(createOnToDate && { createOnToDate }),
      ...(expireOnFromDate && { expireOnFromDate }),
      ...(expireOnToDate && { expireOnToDate }),
    });
  }, [filterData]);

  const handleReset = useCallback(() => {
    setFilterData((prev) => ({
      ...prev,
      createdFromDate: null,
      createdToDate: null,
      expireFromDate: null,
      expireToDate: null,
      accessType: [],
    }));
    setFilterValue(null);
    setAppliedFilterData((prev) => ({
      ...prev,
      createdFromDate: null,
      createdToDate: null,
      expireFromDate: null,
      expireToDate: null,
      accessType: [],
    }));
    setViewFilter(false);
  }, []);

  const handleFilterDelete = useCallback(
    (filterDataOverride: FileManagerFilterData) => {
      const createOnFromDate = filterDataOverride?.createdFromDate
        ? filterDataOverride?.createdFromDate?.getTime()?.toString()
        : null;
      const createOnToDate = filterDataOverride?.createdToDate
        ? filterDataOverride?.createdToDate?.setHours(23, 59, 59)?.toString()
        : null;
      const expireOnFromDate = filterDataOverride?.expireFromDate
        ? filterDataOverride?.expireFromDate?.getTime()?.toString()
        : null;
      const expireOnToDate = filterDataOverride?.expireToDate
        ? filterDataOverride?.expireToDate?.setHours(23, 59, 59)?.toString()
        : null;
      const accessType =
        filterDataOverride?.accessType &&
        Array.isArray(filterDataOverride.accessType)
          ? filterDataOverride?.accessType?.join(',')
          : null;
      setAppliedFilterData(filterDataOverride);
      setFilterValue({
        ...(accessType && { accessType }),
        ...(createOnFromDate && { createOnFromDate }),
        ...(createOnToDate && { createOnToDate }),
        ...(expireOnFromDate && { expireOnFromDate }),
        ...(expireOnToDate && { expireOnToDate }),
      });
    },
    [],
  );

  const handleCreateDateDelete = useCallback(() => {
    setFilterData((prev) => {
      const updatedFilterData = {
        ...prev,
        createdFromDate: null,
        createdToDate: null,
      };
      handleFilterDelete(updatedFilterData);
      return updatedFilterData;
    });
  }, [handleFilterDelete]);

  const handleExpireDateDelete = useCallback(() => {
    setFilterData((prev) => {
      const updatedFilterData = {
        ...prev,
        expireFromDate: null,
        expireToDate: null,
      };
      handleFilterDelete(updatedFilterData);
      return updatedFilterData;
    });
  }, [handleFilterDelete]);

  const handleAccessTypeDelete = useCallback(() => {
    setFilterData((prev) => {
      const updatedFilterData = {
        ...prev,
        accessType: [],
      };
      handleFilterDelete(updatedFilterData);
      return updatedFilterData;
    });
  }, [handleFilterDelete]);

  const handleMenuOpen = useCallback(
    (data: DocumentData) => (event: React.MouseEvent<HTMLElement>) => {
      setIsEdit(false);
      setAnchorElMenu(event.currentTarget);
      setSelectedDocument(data);
      setMenuList(data?.sharedWith ?? []);
    },
    [],
  );

  const handleMenuClose = useCallback(() => {
    setAnchorElMenu(null);
  }, []);

  const handleCancelEdit = useCallback(() => {
    setIsEdit(false);
  }, []);

  const handleClickEdit = useCallback(() => {
    setIsEdit(true);
    setAnchorElMenu(null);
  }, []);

  const handleClickSearch = useCallback(
    (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      if (event.type === 'click') {
        setSearchText(searchValue);
        setPage(0);
      }
    },
    [searchValue],
  );

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await FileManagerRepository.fileManagerList(
        searchText,
        filterValue,
        page + 1,
        PAGE_COUNT,
      );
      const data = response?.data?.map((item) => {
        const expiresAfter = item?.singleUseOnly
          ? `${t('fileManagerTable.expiresAfterValue', { singleUseFileExpiration })}`
          : '-';
        const expireDateFormat = item?.expireOn
          ? new Date(parseInt(item?.expireOn))
          : null;
        const createdDateFormat = new Date(parseInt(item?.createOn));
        const expiresOnDate = expireDateFormat;
        const expireOn = item?.expireOn
          ? expireDateFormat
              ?.toLocaleTimeString([], {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })
              .replace('am', 'AM')
              .replace('pm', 'PM')
          : '-';
        const createOn = createdDateFormat
          ?.toLocaleTimeString([], {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })
          .replace('am', 'AM')
          .replace('pm', 'PM');
        return {
          ...item,
          expiresAfter,
          createOn,
          expireOn,
          expiresOnDate,
        } as DocumentData;
      });
      setFileManagerData({ ...response, data });
      setLoading(false);
    } catch (error: unknown) {
      setLoading(false);
      if (error instanceof Error && error?.message) {
        snackbar.show(error.message);
      }
    }
  }, [t, searchText, snackbar, page, filterValue, setLoading]);

  const requestToEdit = useCallback(
    async (date: Date | null) => {
      const convertedMenuList = menuList.map((email) => {
        return {
          emails: [email],
        };
      });
      const expireOnDate = date?.setHours(23, 59, 59)?.toString();
      try {
        setLoading(true);
        await FileManagerRepository.fileManagerEdit(
          selectedDocument?.id ?? '',
          expireOnDate,
          convertedMenuList,
        );
        await fetchData();
        setLoading(false);
        setIsEdit(false);
      } catch (error: unknown) {
        setLoading(false);
        if (error instanceof Error && error?.message) {
          snackbar.show(error.message);
        }
      }
    },
    [selectedDocument, menuList, snackbar, setLoading, fetchData],
  );

  const handleClickEmailEditApply = useCallback(() => {
    requestToEdit(expireOn);
  }, [expireOn, requestToEdit]);

  const handleChangeExpiresOn = useCallback((date: Date | null) => {
    setExpireOn(date);
  }, []);

  const handleApplyExpireOn = useCallback(
    (date: Date | null) => {
      requestToEdit(date);
    },
    [requestToEdit],
  );

  const handleDeleteDocument = useCallback(async () => {
    try {
      setLoading(true);
      await FileManagerRepository.fileManagerDelete(selectedDocument?.id ?? '');
      handleMenuClose();
      await fetchData();
      setLoading(false);
      setAnchorElMenu(null);
      snackbar.show(
        t('fileManagerTable.deleteSuccessMessage'),
        SeverityType.success,
      );
    } catch (error: unknown) {
      setLoading(false);
      if (error instanceof Error && error?.message) {
        snackbar.show(error.message);
      }
    }
  }, [t, snackbar, selectedDocument, fetchData, setLoading, handleMenuClose]);

  useEffect(() => {
    debounce(fetchData, 1000, { maxWait: 400 });
    fetchData();
  }, [fetchData]);

  return (
    <FileManagerLayout
      menuList={menuList}
      fileManagerData={fileManagerData?.data ?? []}
      isOpenFilter={open}
      page={page}
      totalRecords={fileManagerData?.page?.totalRecords ?? 0}
      onChangePage={handleChangePage}
      anchorEl={anchorEl}
      anchorElMenu={anchorElMenu}
      filterData={filterData}
      isEdit={isEdit}
      selectedDocument={selectedDocument}
      appliedFilterData={appliedFilterData}
      isShowAppliedFilter={viewFilter}
      onChangeExpiresOn={handleChangeExpiresOn}
      setMenuList={setMenuList}
      onClickEdit={handleClickEdit}
      onChangeSearch={handleChangeSearch}
      onApplySearch={handleApplySearch}
      onFilterApply={handleApply}
      onFilterReset={handleReset}
      onClickFilter={handleClickFilter}
      onChangeCreatedStartDate={handleCreatedStartDateChange}
      onChangeCreatedEndDate={handleCreatedEndDateChange}
      onChangeExpireStartDate={handleExpireStartDateChange}
      onChangeExpireEndDate={handleExpireEndDateChange}
      onChangeAccessType={handleAccessTypeChange}
      onFilterClose={handleClose}
      onClickDeleteDocument={handleDeleteDocument}
      onClickMenu={handleMenuOpen}
      onClickMenuClose={handleMenuClose}
      onClickEmailEdit={handleClickEmailEditApply}
      onClickSearch={handleClickSearch}
      onClickCancelEdit={handleCancelEdit}
      onCreateDateDelete={handleCreateDateDelete}
      onExpireDateDelete={handleExpireDateDelete}
      onAccessTypeDelete={handleAccessTypeDelete}
      onApplyExpireOn={handleApplyExpireOn}
    />
  );
};

export default FileManagerPage;
