import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { differenceInSeconds } from 'date-fns';
import { MFA_REFRESH_IN_SECONDS } from '../constants';

export type UseTimer = {
  value: number;
  start: () => void;
  end: () => void;
};
const useTimer = (): UseTimer => {
  const [startDate, setStartDate] = useState<number | null>(null);
  const [value, setValue] = useState(MFA_REFRESH_IN_SECONDS);
  const [running, setRunning] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | undefined>();

  const start = useCallback(() => {
    setStartDate(Date.now());
    setRunning(true);
  }, []);

  const end = useCallback(() => {
    setRunning(false);
  }, []);

  useEffect(() => {
    if (running) {
      timerRef.current = setInterval(() => {
        const currentTime = Date.now();
        const elapsedTime = differenceInSeconds(
          currentTime,
          startDate as number,
        );
        const remainingSeconds = Math.max(
          MFA_REFRESH_IN_SECONDS - elapsedTime,
          0,
        );
        setValue(remainingSeconds);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }
    return () => clearInterval(timerRef.current);
  }, [running, startDate]);

  return useMemo(
    () => ({ start, end, value }) as UseTimer,
    [start, end, value],
  );
};

export default useTimer;
