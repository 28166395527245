import { getHttpClient } from './AxiosClient';
import {
  FileManagerDeleteResponse,
  FileManagerResponse,
  FileManagerFilterParam,
} from '../types/FileManager';
import qs from 'qs';

export const fileManagerList = (
  search?: string | null,
  filter?: FileManagerFilterParam | null,
  page?: number,
  pageSize?: number,
): Promise<FileManagerResponse> => {
  return getHttpClient('document/list', 'POST', null, {
    page,
    pageSize,
    search,
    filter: qs
      .stringify(filter, {
        arrayFormat: 'comma',
        delimiter: ';',
      })
      ?.replace(/%2C/g, ','),
  });
};

export const fileManagerDelete = (
  id: string,
): Promise<FileManagerDeleteResponse> => {
  return getHttpClient('document/delete', 'POST', {
    id,
  });
};

export const fileManagerEdit = (
  id: string,
  expirationDate?: string,
  shareWith?: {
    emails: string[];
  }[],
): Promise<FileManagerDeleteResponse> => {
  return getHttpClient('document/edit', 'POST', {
    id,
    expirationDate,
    shareWith,
  });
};
