import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SharedImages } from '../../../assets/images';

const NoDataContainer = () => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        component="img"
        src={SharedImages.noRecord.default}
        alt="no-record"
        style={{
          width: '53px',
          height: '68px',
        }}
      />
      <Typography fontSize="18px" marginTop="20px">
        {t('noDataContainer.message')}
      </Typography>
    </Box>
  );
};

export default NoDataContainer;
