import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EmergencyContactListResponse } from '../../types';
import { NoEmergencyContactLayout, SendEmergencyRecoverTokenLayout } from './';

export type EmergencyRecoverAccountLayoutProps = {
  selectedContact?: string | null;
  recoverContactListData?: EmergencyContactListResponse;
  onChangeRecoverContact: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickSendToken: () => void;
};

const EmergencyRecoverAccountLayout: React.FC<
  EmergencyRecoverAccountLayoutProps
> = ({
  selectedContact,
  recoverContactListData,
  onChangeRecoverContact,
  onClickSendToken,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box sx={{ background: theme?.palette?.background?.paper }}>
      <Typography
        variant="h4"
        sx={{ fontFamily: theme?.font?.primaryBold, fontSize: '18px' }}
      >
        {t('emergencyRecoverPage.title')}
      </Typography>
      {recoverContactListData && recoverContactListData?.length > 0 ? (
        <SendEmergencyRecoverTokenLayout
          selectedContact={selectedContact}
          recoverContactListData={recoverContactListData}
          onChangeRecoverContactList={onChangeRecoverContact}
          onClickSendToken={onClickSendToken}
        />
      ) : (
        <NoEmergencyContactLayout />
      )}
    </Box>
  );
};

export default EmergencyRecoverAccountLayout;
