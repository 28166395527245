import React from 'react';
import { Typography, Radio, RadioProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { theme } from '../../theme';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    backgroundColor: theme?.palette?.white?.[200],
    boxShadow: `inset 0 0 0 1px ${theme?.palette?.black?.[500]}`,
    borderRadius: '50%',
    width: 16,
    height: 16,
  },
  checkedIcon: {
    backgroundColor: theme?.palette?.success?.main,
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: `radial-gradient(${theme?.palette?.background?.paper},${theme?.palette?.background?.paper} 28%,transparent 32%)`,
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme?.palette?.success?.main,
    },
  },
});

const StyledRadio = (props: RadioProps) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <Typography className={classes.icon + ' ' + classes.checkedIcon} />
      }
      icon={<Typography className={classes.icon} />}
      {...props}
    />
  );
};

export default StyledRadio;
