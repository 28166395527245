import { Box, IconButton, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Button from '../../components/button/Button';
import { EmailPicker } from '../../components/emailPicker';

type ShareTypeEmailProps = {
  isLoading?: boolean;
  onSharePopupClose?: () => void;
  onSendEmailEvent: (emailList: string[]) => void;
};
const ShareTypeEmail: React.FC<ShareTypeEmailProps> = ({
  isLoading,
  onSharePopupClose,
  onSendEmailEvent,
}: ShareTypeEmailProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [menuList, setMenuList] = useState<string[]>([]);

  const onButtonClick = useCallback(() => {
    onSendEmailEvent(menuList);
  }, [onSendEmailEvent, menuList]);

  return (
    <>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <IconButton
          style={{ color: theme.palette.primary.main }}
          onClick={onSharePopupClose}
        >
          <HighlightOffRoundedIcon />
        </IconButton>
      </Box>
      <Typography
        sx={{ marginBottom: '30px', mt: '-10px' }}
        variant="h6"
        color={theme.palette.background.default}
      >
        {t('createCyphlensImage.share.shareByEmail')}
      </Typography>
      <Box sx={{ width: '80%' }}>
        <EmailPicker
          menuList={menuList}
          setMenuList={setMenuList}
          sx={{
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
            width: '100%',
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          marginTop="30px"
          sx={{
            width: '150px',
          }}
        >
          <Button
            sx={{ width: '100px', marginBottom: '20px' }}
            data-testid="share-with-email-submit-button"
            type="submit"
            variant="contained"
            title={t('createCyphlensImage.send')}
            isDisabled={menuList.length === 0}
            onClick={onButtonClick}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </>
  );
};

export default ShareTypeEmail;
