import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { theme } from '../../theme';
import { useTranslation } from 'react-i18next';
import HighlightOffRounded from '@mui/icons-material/HighlightOffRounded';
import { FileManagerFilterData } from '../../pages/FileManagerPage';

type FileManagerViewFilterProps = {
  appliedFilterData?: FileManagerFilterData;
  onCreateDateDelete?: () => void;
  onExpireDateDelete?: () => void;
  onAccessTypeDelete?: () => void;
  onFilterReset: () => void;
};

const FileManagerViewFilter: React.FC<FileManagerViewFilterProps> = ({
  appliedFilterData,
  onAccessTypeDelete,
  onExpireDateDelete,
  onCreateDateDelete,
  onFilterReset,
}) => {
  const { t } = useTranslation();

  const filterDataStyle = useMemo(() => {
    return {
      marginTop: '10px',
      border: `0.6px solid ${theme?.palette?.echoBlue?.[100]}`,
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      maxHeight: '25px',
      height: '25px',
      backgroundColor: theme?.palette?.white?.[500],
      paddingLeft: '10px',
      paddingRight: '10px',
      maxWidth: '275px',
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            color: theme?.palette?.black?.[100],
            fontWeight: 400,
          }}
        >
          {t('activityLog.filters')}
        </Typography>
        <Typography
          onClick={onFilterReset}
          sx={{
            fontSize: '10px',
            fontWeight: 300,
            color: theme?.palette?.grey?.[500],
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          {t('activityLog.clearAll')}
        </Typography>
      </Box>
      <Box display={'flex'} gap="10px">
        {appliedFilterData?.createdFromDate && (
          <Box sx={filterDataStyle}>
            <Typography
              sx={{
                overflow: 'hidden',
                textWrap: 'nowrap',
                wordWrap: 'initial',
                textOverflow: 'ellipsis',
                fontSize: 12,
              }}
            >
              {`${t('fileManagerFilter.createdDate')}: ${appliedFilterData?.createdFromDate?.toLocaleDateString()} ${t('filter.toLabel')} ${appliedFilterData?.createdToDate ? appliedFilterData?.createdToDate?.toLocaleDateString() : new Date()?.toLocaleDateString()}`}
            </Typography>
            {
              <HighlightOffRounded
                onClick={onCreateDateDelete}
                data-testid="filter-remove"
                sx={{
                  width: '16px',
                  height: '16px',
                  cursor: 'pointer',
                  marginLeft: '15px',
                }}
              />
            }
          </Box>
        )}
        {appliedFilterData?.expireFromDate && (
          <Box sx={filterDataStyle}>
            <Typography
              sx={{
                overflow: 'hidden',
                textWrap: 'nowrap',
                wordWrap: 'initial',
                textOverflow: 'ellipsis',
                fontSize: 12,
              }}
            >
              {`${t('fileManagerFilter.expireDate')}: ${appliedFilterData?.expireFromDate?.toLocaleDateString()} ${t('filter.toLabel')} ${appliedFilterData?.expireToDate ? appliedFilterData?.expireToDate?.toLocaleDateString() : new Date()?.toLocaleDateString()}`}
            </Typography>
            {
              <HighlightOffRounded
                onClick={onExpireDateDelete}
                data-testid="filter-remove"
                sx={{
                  width: '16px',
                  height: '16px',
                  cursor: 'pointer',
                  marginLeft: '15px',
                }}
              />
            }
          </Box>
        )}
        {appliedFilterData?.accessType &&
          appliedFilterData?.accessType?.length > 0 && (
            <Box sx={filterDataStyle}>
              <Typography
                sx={{
                  overflow: 'hidden',
                  textWrap: 'nowrap',
                  wordWrap: 'initial',
                  textOverflow: 'ellipsis',
                  fontSize: 12,
                }}
              >
                {`${t('fileManagerFilter.accessType')}: ${appliedFilterData?.accessType}`}
              </Typography>
              {
                <HighlightOffRounded
                  onClick={onAccessTypeDelete}
                  data-testid="filter-remove"
                  sx={{
                    width: '16px',
                    height: '16px',
                    cursor: 'pointer',
                    marginLeft: '15px',
                  }}
                />
              }
            </Box>
          )}
      </Box>
    </>
  );
};

export default FileManagerViewFilter;
