import countrycode from 'country-codes-list';

export interface CountryCode {
  flag: string;
  code: string;
  callingCode: string;
}

export class CountryCodeUtil {
  static getAllCountryMobileCode() {
    const countryCodes: CountryCode[] = [];
    countrycode.all().forEach((country) => {
      if (
        countryCodes.filter((c) => c.code === country.countryCode).length > 0
      ) {
        return;
      }
      const countryCode: CountryCode = {
        code: country.countryCode,
        callingCode: '+' + country.countryCallingCode,
        flag: country.flag,
      };
      countryCodes.push(countryCode);
    });
    return countryCodes;
  }
}

export const countryCodes = CountryCodeUtil.getAllCountryMobileCode();
