import React from 'react';
import { Typography, Box, TablePagination } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { theme } from '../../theme';
import { SharedImages } from '../../assets/images';
import { ActivityLogTable } from './';
import { ActivityLog } from './ActivityLogTable.layout';
import { Filter } from '../../components/shared/filter';
import { ActivityFilterData } from '../../pages/ActivityLogPage';
import { TextField } from '../../components';
import { PAGE_COUNT } from '../../constants';
import ActivityLogDownload from './ActivityLogDownload';
import ActivityLogViewFilter from './ActivityLogViewFilter';

export type ActivityLogLayoutProps = {
  filterData: ActivityFilterData;
  isOpenFilter: boolean;
  isOpenDownload: boolean;
  downloadDate: ActivityFilterData;
  page: number;
  totalRecords: number;
  anchorEl: HTMLButtonElement | null;
  activityLogData: ActivityLog[];
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void;
  isViewFilter?: boolean;
  appliedFilterData?: ActivityFilterData;
  onChangeDownloadStartDate: (date: Date | null) => void;
  onChangeDownloadEndDate: (date: Date | null) => void;
  onDownloadClose: () => void;
  onDownloadApply: () => void;
  onClickDownload: () => void;
  onFilterApply: () => void;
  onClickFilter: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onFilterReset: () => void;
  onApplySearch: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onChangeStartDate: (date: Date | null) => void;
  onChangeEndDate: (date: Date | null) => void;
  onChangeSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterClose: () => void;
  onClickSearch: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const ActivityLogLayout: React.FC<ActivityLogLayoutProps> = ({
  isOpenFilter,
  isOpenDownload,
  downloadDate,
  page,
  totalRecords,
  anchorEl,
  filterData,
  activityLogData,
  isViewFilter,
  appliedFilterData,
  onChangePage,
  onChangeStartDate,
  onChangeEndDate,
  onFilterApply,
  onFilterReset,
  onFilterClose,
  onClickFilter,
  onApplySearch,
  onChangeSearch,
  onDownloadApply,
  onDownloadClose,
  onChangeDownloadEndDate,
  onChangeDownloadStartDate,
  onClickDownload,
  onClickSearch,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: '100%', height: '100%', minHeight: '786px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">{'Activity Log'}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Box
            component="img"
            src={SharedImages?.download.default}
            alt="download"
            sx={{ cursor: 'pointer' }}
            onClick={onClickDownload}
          />
          <TextField
            placeholder={t('activityLog.search')}
            InputProps={{
              endAdornment: (
                <Search onClick={onClickSearch} sx={{ cursor: 'pointer' }} />
              ),
            }}
            sx={{
              width: '166px',
              height: '30px',
              marginTop: 0,
              marginBottom: 0,
              '& .MuiInputBase-input': {
                padding: '4px',
              },
              '& .MuiOutlinedInput-input': {
                padding: '6px',
              },
            }}
            onChange={onChangeSearch}
            onKeyDown={onApplySearch}
          />
          <Filter
            isOpenFilter={isOpenFilter}
            anchorEl={anchorEl}
            filterData={filterData}
            onChangeStartDate={onChangeStartDate}
            onChangeEndDate={onChangeEndDate}
            onFilterApply={onFilterApply}
            onFilterReset={onFilterReset}
            onFilterClose={onFilterClose}
            onClickFilter={onClickFilter}
          />
          <TablePagination
            sx={{
              '& .MuiButtonBase-root.MuiIconButton-root': {
                borderRadius: '5px',
                margin: '0 5px',
                width: '18px',
                height: '18px',
                backgroundColor: theme.palette.black?.[600],
                color: theme.palette.background?.paper,
                '&:hover': {
                  backgroundColor: theme.palette.black?.[600],
                },
              },
              '& .MuiButtonBase-root.MuiIconButton-root.Mui-disabled': {
                backgroundColor: theme.palette.grey?.[900],
                cursor: 'none',
              },
              '& .MuiToolbar-root.MuiTablePagination-toolbar': {
                paddingLeft: '16px',
              },
            }}
            component="div"
            count={totalRecords}
            page={page}
            onPageChange={onChangePage}
            labelRowsPerPage=""
            rowsPerPageOptions={[]}
            rowsPerPage={PAGE_COUNT}
          />
        </Box>
      </Box>
      {isViewFilter && (
        <ActivityLogViewFilter
          filterData={appliedFilterData}
          onFilterReset={onFilterReset}
        />
      )}
      <ActivityLogTable data={activityLogData} />
      <ActivityLogDownload
        isOpenDownload={isOpenDownload}
        downloadDate={downloadDate}
        onChangeDownloadStartDate={onChangeDownloadStartDate}
        onChangeDownloadEndDate={onChangeDownloadEndDate}
        onDownloadApply={onDownloadApply}
        onDownloadClose={onDownloadClose}
      />
    </Box>
  );
};

export default ActivityLogLayout;
