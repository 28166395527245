import React, { useMemo } from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

interface DrawerMenuItemProps {
  activeTab: number;
  index: number;
  menu: {
    routeName: string;
    icon: string;
    name: string;
  };
  isShowDivider: boolean;
  onClickMenu: (index: number, routeName: string) => void;
}

const DrawerMenuItem: React.FC<DrawerMenuItemProps> = ({
  activeTab,
  index,
  menu,
  isShowDivider,
  onClickMenu,
}) => {
  const theme = useTheme();
  const isActiveTab = activeTab === index;
  const containerStyle = useMemo(() => {
    return {
      maxHeight: 40,
      paddingLeft: '0px',
      background: isActiveTab
        ? theme.palette.white?.[300]
        : theme.palette.black?.[600],
      color: isActiveTab
        ? theme.palette.text.secondary
        : theme.palette.background.paper,
      borderTopLeftRadius: isActiveTab ? '25px' : 0,
      borderBottomLeftRadius: isActiveTab ? '25px' : 0,
    };
  }, [theme, isActiveTab]);
  const { t } = useTranslation();

  const onClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      onClickMenu(index, menu.routeName);
    },
    [index, onClickMenu, menu.routeName],
  );

  return (
    <>
      <Box
        sx={{
          maxHeight: 61,
          background: isActiveTab
            ? `linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,255,255,0) 0%, #e0e0e0 100%)`
            : 'transparent',
        }}
      >
        {isActiveTab && (
          <Box
            sx={{
              height: '8px',
              borderBottomRightRadius: '10px',
              background: theme.palette.black?.[600],
            }}
          />
        )}

        <ListItem
          sx={containerStyle}
          data-testid={menu.name}
          key={index}
          disablePadding
        >
          <ListItemButton
            sx={{ margin: 0, paddingLeft: '5px' }}
            onClick={onClick}
            component="button"
          >
            <ListItemIcon
              sx={{
                minWidth: 40,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: theme.palette.black?.[600],
                  width: '30px',
                  height: '30px',
                  margin: 0,
                  borderRadius: '30px',
                }}
              >
                <Box
                  component="img"
                  src={menu.icon}
                  alt="logo"
                  sx={{
                    width: '16px',
                    height: '16px',
                  }}
                />
              </Box>
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: isActiveTab ? 700 : 500,
              }}
              primary={t(menu.name)}
            />
          </ListItemButton>
        </ListItem>

        {isActiveTab && (
          <Box
            sx={{
              height: '8px',
              borderTopRightRadius: '10px',
              background: theme.palette.black?.[600],
            }}
          />
        )}
      </Box>
      {isShowDivider || (
        <Divider
          variant="middle"
          component="li"
          sx={{ borderColor: theme.palette.black?.[700] }}
        />
      )}
    </>
  );
};

export default DrawerMenuItem;
