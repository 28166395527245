import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { TextField, Button, TextFieldProps } from '../../components';

type PasswordFormProps = {
  isLoading: boolean;
  isDisabled?: boolean;
} & TextFieldProps;

const PasswordForm: React.FC<PasswordFormProps> = ({
  isLoading,
  value,
  errorMessage,
  isTouched,
  isDisabled,
  onChange,
  onBlur,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={{ marginBottom: '10px' }}>
        <TextField
          name="password"
          placeholder={t('loginForm.password')}
          type={'password'}
          id="password"
          value={value}
          errorMessage={errorMessage}
          isTouched={isTouched}
          onBlur={onBlur}
          onChange={onChange}
        />
      </Box>
      <Button
        disabled={isDisabled}
        title={t('loginForm.loginButtonLabel')}
        data-testid="login-button"
        type="submit"
        variant="contained"
        isLoading={isLoading}
      />
    </Box>
  );
};

export default PasswordForm;
