import { getHttpClient } from './AxiosClient';
import { UserAccountResponse, AccountStatusResponse } from '../types/Account';

export const getUser = (): Promise<UserAccountResponse> => {
  return getHttpClient('account/getDetails', 'POST');
};

export const updateStatus = (
  deviceStatus: string,
): Promise<AccountStatusResponse> => {
  return getHttpClient('device/updateStatus', 'POST', { deviceStatus });
};
