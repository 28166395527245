import React, { useMemo } from 'react';
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  SxProps,
  Typography,
} from '@mui/material';
import { theme } from '../../theme';

export type InputProgressBarProps = {
  count: number;
  maxTextLength: number;
  sx: SxProps;
} & CircularProgressProps;

const InputProgressBar = ({
  count,
  maxTextLength,
  sx,
}: InputProgressBarProps) => {
  const progress = useMemo(() => {
    return Math.ceil((count / maxTextLength) * 100);
  }, [count, maxTextLength]);

  const progressColor = useMemo(() => {
    if (progress > 80) {
      return theme.palette.error.main;
    }
    if (progress > 45) {
      return theme.palette.info.main;
    }
    return theme.palette.success.main;
  }, [progress]);

  return (
    <Box sx={sx}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: `${theme.palette.white[600]}`,
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        size={20}
        thickness={2}
        value={100}
      />
      <CircularProgress
        data-testid="progress-bar"
        variant="determinate"
        sx={{ color: progressColor, position: 'absolute', top: 0, left: 0 }}
        size={20}
        thickness={2}
        value={progress}
      />
      <Typography
        sx={{
          fontSize: 10,
          marginLeft: '20px',
          paddingLeft: '5px',
          marginTop: '2px',
          fontWeight: 400,
          minWidth: '50px',
          textAlign: 'right',
          color: theme.palette.background.default,
        }}
      >
        {count}/{maxTextLength}
      </Typography>
    </Box>
  );
};

export default InputProgressBar;
