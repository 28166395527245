import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EmergencyContactImages } from '../../assets/images';

const EmergencyContactError = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        component="img"
        alt="Success Logo"
        width="10%"
        src={EmergencyContactImages?.errorContact.default}
      />
      <Typography fontSize="32px">
        {t('emergencyContactPage.errorMessage')}
      </Typography>
    </>
  );
};

export default EmergencyContactError;
